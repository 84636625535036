import Config from "../Config";
import { authHeader, handleResponse } from "../helpers/requestHelpers";

const config = new Config();

export const SET_LOADING = "SET_LOADING";
export function setLoading(isLoading) {
    return {
        type: SET_LOADING,
        payload: isLoading,
    };
}

//NEU

export function createTransaction(orderTypesId) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(orderTypesId),
    };
    return function (dispatch) {
        dispatch(setLoading(true));
        return fetch(config.backendHost + "/transactions/createTransaction", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                json.OrderDetails = [];
                dispatch(setOrder(json));
                dispatch(setLoading(false));
                return json;
            });
    };
}

export function updateTransactionOrderType(transactionId, orderType) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify({ transactionId: transactionId, orderTypesId: orderType.id }),
        };
        return fetch(config.backendHost + "/transactions/updateTransactionOrderType", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                dispatch(setOrderType(orderType));
                // dispatch(checkOrderDependensiesFullfilled(ordersId));
                // dispatch(getOrderSums(ordersId));
                return json;
            });
    };
}

export function addArticleToTransaction(transactionId, articleId) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ transactionId: transactionId, articleId: articleId }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/transactions/addArticleToTransaction", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function getAllTransactions(loadOptions, daysBefore) {
    return function () {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(daysBefore),
        };

        let query = new URLSearchParams(loadOptions).toString();

        return fetch(config.backendHost + "/transactions/getAllTransactions?" + query, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

//ALT

export function createOrder(orderTypesId) {
    return function (dispatch) {
        dispatch(setLoading(true));
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(orderTypesId),
        };
        return fetch(config.backendHost + "/Orders/createOrder", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                dispatch(setOrder(json));
                dispatch(setLoading(false));
                return json;
            });
    };
}

export const SET_ORDER = "SET_ORDER";
export function setOrder(json) {
    console.log(json);
    return {
        type: SET_ORDER,
        payload: json,
    };
}

export function createDailyOrdersReport(date) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(date),
        };
        return fetch(config.backendHost + "/Reports/printDailyOrders", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function getArticleGroupsForOrderManagement() {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
        };
        return fetch(config.backendHost + "/ArticleGroups/getArticleGroupsForOrderManagement", requestOptions).then(
            (response) => handleResponse(response)
        );
    };
}

export function getArticleGroupsForOrderManagementByParentId(articleGroupsId) {
    return function (dispatch) {
        const requestOptions = {
            method: "GET",
            headers: authHeader(),
        };
        if (articleGroupsId === null) {
            return fetch(
                config.backendHost + "/ArticleGroups/getArticleGroupsForOrderManagementByParentId/0",
                requestOptions
            )
                .then((response) => handleResponse(response))
                .then((json) => {
                    return json;
                });
        } else {
            return fetch(
                config.backendHost + "/ArticleGroups/getArticleGroupsForOrderManagementByParentId/" + articleGroupsId,
                requestOptions
            )
                .then((response) => handleResponse(response))
                .then((json) => {
                    return json;
                });
        }
    };
}

export function getRootArticleGroupsForOrderManagement() {
    return function (dispatch) {
        const requestOptions = {
            method: "GET",
            headers: authHeader(),
        };
        return fetch(config.backendHost + "/ArticleGroups/getRootArticleGroupsForOrderManagement", requestOptions).then(
            (response) => handleResponse(response)
        );
    };
}

export function changeOrderDetailAmount(ordersId, orderDetailsId, amount) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify({
                orderDetailsId: orderDetailsId,
                amount: amount,
            }),
        };
        return fetch(config.backendHost + "/Orders/changeOrderDetailAmount", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                dispatch(updateOrderDetailAmount(json));
                return json;
            })
            .then((json) => {
                dispatch(getOrderSums(ordersId));
                dispatch(checkOrderDependensiesFullfilled(ordersId));
                return json;
            });
    };
}

export const UPDATE_ORDER_DETAIL_AMOUNT = "UPDATE_ORDER_DETAIL_AMOUNT";
export function updateOrderDetailAmount(json) {
    return {
        type: UPDATE_ORDER_DETAIL_AMOUNT,
        payload: json,
    };
}

export function cancelOrder(ordersId, reason) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify({ ordersId: ordersId, reason: reason }),
        };
        return fetch(config.backendHost + "/Orders/cancelOrder", requestOptions).then((response) =>
            handleResponse(response).then(() => {
                dispatch(unsetOrder());
            })
        );
    };
}

export const UNSET_ORDER = "UNSET_ORDER";
export function unsetOrder() {
    return {
        type: UNSET_ORDER,
    };
}

export function getOrderSums(ordersId) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(ordersId),
        };
        return fetch(config.backendHost + "/Orders/getOrderSums", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                console.log(json);
                dispatch(updateOrderSums(json));
                return json;
            });
    };
}

export const UPDATE_ORDER_SUMS = "UPDATE_ORDER_SUMS";
export function updateOrderSums(json) {
    return {
        type: UPDATE_ORDER_SUMS,
        payload: json,
    };
}

export function getAllOrders(loadOptions, daysBefore) {
    return function () {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(daysBefore),
        };

        let query = new URLSearchParams(loadOptions).toString();

        return fetch(config.backendHost + "/Orders/getAllOrders?" + query, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function getAllOrderDetailsSortedForPrint() {
    return function () {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
        };
        return fetch(config.backendHost + "/Orders/getAllOrderDetailsSortedForPrint", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function setDoneOrder(ordersId) {
    return function () {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(ordersId),
        };
        return fetch(config.backendHost + "/Orders/setDoneOrder", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function bookAndSetDoneOrder(ordersId) {
    return function () {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(ordersId),
        };
        return fetch(config.backendHost + "/Orders/bookAndSetDoneOrder", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function getAllOrderDetails() {
    return function () {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
        };
        return fetch(config.backendHost + "/Orders/getAllOrderDetails", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function getOrderById(ordersId) {
    return function () {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(ordersId),
        };
        return fetch(config.backendHost + "/Orders/getOrderById", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function setPackageTerminalPrintedFlagTrue(ordersId) {
    return function () {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(ordersId),
        };
        return fetch(config.backendHost + "/Orders/setPackageTerminalPrintedFlagTrue", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function getAllDailyOrderDetails(date) {
    return function () {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(date ? date : new Date()),
        };
        return fetch(config.backendHost + "/Orders/getDailyOrderDetails", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function checkOrderDependensiesFullfilled(ordersId) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(ordersId),
        };
        return fetch(config.backendHost + "/Orders/checkOrderDependensiesFullfilled", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                dispatch(updateOrderValidation(json));
                return json;
            });
    };
}

export const UPDATE_ORDER_VALIDATION = "UPDATE_ORDER_VALIDATION";
export function updateOrderValidation(orderValidation) {
    return {
        type: UPDATE_ORDER_VALIDATION,
        payload: orderValidation,
    };
}

export function doOrder(ordersId, addressId) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify({ ordersId: ordersId, addressId: addressId }),
        };
        return fetch(config.backendHost + "/Orders/doOrder", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                dispatch(unsetOrder());
                return json;
            });
    };
}

export function setCustomerForOrder(ordersId, customersId) {
    return function (dispatch) {
        dispatch(setLoading(true));
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify({ ordersId: ordersId, customersId: customersId }),
        };
        return fetch(config.backendHost + "/Orders/setCustomerForOrder", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                dispatch(updateCustomerForOrder(json));
                dispatch(checkOrderDependensiesFullfilled(ordersId));
                dispatch(setLoading(false));
                return json;
            });
    };
}

export const UPDATE_CUSTOMER_FOR_ORDER = "UPDATE_CUSTOMER_FOR_ORDER";
export function updateCustomerForOrder(json) {
    return {
        type: UPDATE_CUSTOMER_FOR_ORDER,
        payload: json,
    };
}

export const UPDATE_CUSTOMER_ADDRESS_FOR_ORDER = "UPDATE_CUSTOMER_ADDRESS_FOR_ORDER";
export function updateCustomerAddressForOrder(json) {
    return {
        type: UPDATE_CUSTOMER_ADDRESS_FOR_ORDER,
        payload: json,
    };
}

export function createCustomer(customerFormData) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(customerFormData),
        };
        return fetch(config.backendHost + "/Customers/createCustomer", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function updateCustomer(customerFormData) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(customerFormData),
        };
        return fetch(config.backendHost + "/Customers/updateCustomer", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function addArticleToOrder(ordersId, articlesId, cartItemId) {
    return function (dispatch) {
        dispatch(setCartItemIsPending());
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify({ ordersId: ordersId, articlesId: articlesId }),
        };
        return fetch(config.backendHost + "/Orders/addArticleToOrder", requestOptions)
            .then((response) => {
                if (!response.ok) {
                    dispatch(deleteTempOrderDetail(cartItemId));
                }
                return handleResponse(response);
            })
            .then((json) => {
                dispatch(checkOrderDependensiesFullfilled(ordersId));
                dispatch(getOrderSums(ordersId));
                return json;
            });
    };
}

export const SET_ORDER_DETAIL = "SET_ORDER_DETAIL";
export function setOrderDetail(orderDetail) {
    return {
        type: SET_ORDER_DETAIL,
        payload: orderDetail,
    };
}

export const SET_CART_ITEM_IS_PENDING = "SET_CART_ITEM_IS_PENDING";
export function setCartItemIsPending() {
    return {
        type: SET_CART_ITEM_IS_PENDING,
        payload: true,
    };
}

export const UPDATE_ORDER_DETAILS = "UPDATE_ORDER_DETAILS";
export function updateOrderDetails(orderDetails) {
    return {
        type: UPDATE_ORDER_DETAILS,
        payload: orderDetails,
    };
}

export const ADD_ARTICLE_TO_CART = "ADD_ARTICLE_TO_CART";
export function addArticleToCart(article) {
    return {
        type: ADD_ARTICLE_TO_CART,
        payload: article,
    };
}

export const RECEIVE_ORDERTYPES_FOR_ORDER = "RECEIVE_ORDERTYPES_FOR_ORDER";
export function receiveOrderTypesForOrder(orderTypes) {
    return {
        type: RECEIVE_ORDERTYPES_FOR_ORDER,
        payload: orderTypes,
    };
}

export function updateOrderType(ordersId, orderType) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify({ ordersId: ordersId, orderTypesId: orderType.id }),
        };
        return fetch(config.backendHost + "/Orders/updateOrderType", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                dispatch(setOrderType(orderType));
                dispatch(checkOrderDependensiesFullfilled(ordersId));
                dispatch(getOrderSums(ordersId));
                return json;
            });
    };
}

export const SET_ORDER_TYPE = "SET_ORDER_TYPE";
export function setOrderType(orderType) {
    return {
        type: SET_ORDER_TYPE,
        payload: orderType,
    };
}

export function getOrderDetail(orderDetailsId) {
    return function (dispatch) {
        const requestOptions = {
            method: "GET",
            headers: authHeader(),
        };
        return fetch(config.backendHost + "/Orders/getOrderDetail/" + orderDetailsId, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function updateOrderDetail(orderDetail) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify({
                ordersId: orderDetail.ordersId,
                orderDetailsId: orderDetail.orderDetailsId,
                sizesId: orderDetail.sizesId,
                articleCount: orderDetail.articleCount,
            }),
        };
        return fetch(config.backendHost + "/Orders/updateOrderDetail", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function orderDetailSetArticleCount(orderDetail) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify({
                ordersId: orderDetail.ordersId,
                orderDetailsId: orderDetail.orderDetailsId,
                amount: orderDetail.articleCount,
            }),
        };
        return fetch(config.backendHost + "/Orders/orderDetailSetArticleCount", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function updateRequestedDeliveryTime(ordersId, requestedDeliveryTime) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify({ ordersId, requestedDeliveryTime }),
        };
        return fetch(config.backendHost + "/Orders/updateRequestedDeliveryTime", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function removeOrderDetail(ordersId, orderDetailsId, reason) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify({ ordersId: ordersId, orderDetailsId: orderDetailsId, reason: reason }),
        };
        return fetch(config.backendHost + "/Orders/removeOrderDetail", requestOptions)
            .then((response) => handleResponse(response))
            .then(() => {
                dispatch(deleteOrderDetail(orderDetailsId));
            })
            .then(() => {
                dispatch(getOrderSums(ordersId));
                dispatch(checkOrderDependensiesFullfilled(ordersId));
            });
    };
}

export const DELETE_ORDER_DETAIL = "DELETE_ORDER_DETAIL";
export function deleteOrderDetail(orderDetailsId) {
    return {
        type: DELETE_ORDER_DETAIL,
        payload: orderDetailsId,
    };
}

export const DELETE_TEMP_ORDER_DETAIL = "DELETE_TEMP_ORDER_DETAIL";
export function deleteTempOrderDetail(cartItemId) {
    return {
        type: DELETE_TEMP_ORDER_DETAIL,
        payload: cartItemId,
    };
}

export function getCurrentDeliveryDay() {
    return function (dispatch) {
        const requestOptions = {
            method: "GET",
            headers: authHeader(),
        };
        return fetch(config.backendHost + "/Orders/getCurrentDeliveryDay", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export const SET_ARTICLES_LOADING = "SET_ARTICLES_LOADING";
export function setArticlesLoading(loading) {
    return {
        type: SET_ARTICLES_LOADING,
        payload: loading,
    };
}

export function getRootArticleGroups() {
    return function (dispatch) {
        const requestOptions = {
            method: "GET",
            headers: authHeader(),
        };
        return fetch(config.backendHost + "/ArticleGroups/getRootArticleGroups", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                dispatch(setRootArticleGroups(json));
            });
    };
}

export const SET_ROOT_ARTICLE_GROUPS = "SET_ROOT_ARTICLE_GROUPS";
export function setRootArticleGroups(rootArticleGroups) {
    return {
        type: SET_ROOT_ARTICLE_GROUPS,
        payload: rootArticleGroups,
    };
}

export function setSelectedArticleGroup(articleGroup) {
    return function (dispatch) {
        dispatch(setArticlesLoading(true));
        dispatch(updateSelectedArticleGroup(articleGroup));
        const requestOptions = {
            method: "GET",
            headers: authHeader(),
        };
        return Promise.all([
            fetch(
                config.backendHost + "/ArticleGroups/getArticleGroupsByParentId/" + articleGroup.articleGroupsId,
                requestOptions
            )
                .then((response) => handleResponse(response))
                .then((json) => {
                    dispatch(updateCurrentArticleGroups(json));
                }),
            fetch(
                config.backendHost + "/Articles/getArticlesByArticleGroupsId/" + articleGroup.articleGroupsId,
                requestOptions
            )
                .then((response) => handleResponse(response))
                .then((json) => {
                    dispatch(updateCurrentArticles(json));
                }),
        ]).then(() => {
            dispatch(setArticlesLoading(false));
        });
    };
}

export const UPDATE_SELECTED_ARTICLE_GROUP = "UPDATE_SELECTED_ARTICLE_GROUP";
export function updateSelectedArticleGroup(articleGroup) {
    return {
        type: UPDATE_SELECTED_ARTICLE_GROUP,
        payload: articleGroup,
    };
}

export const UPDATE_CURRENT_ARTICLE_GROUPS = "UPDATE_CURRENT_ARTICLE_GROUPS";
export function updateCurrentArticleGroups(articleGroups) {
    return {
        type: UPDATE_CURRENT_ARTICLE_GROUPS,
        payload: articleGroups,
    };
}

export const UPDATE_CURRENT_ARTICLES = "UPDATE_CURRENT_ARTICLES";
export function updateCurrentArticles(articles) {
    return {
        type: UPDATE_CURRENT_ARTICLES,
        payload: articles,
    };
}

export function getParentArticleGroup(articleGroup) {
    return function (dispatch) {
        dispatch(setArticlesLoading(true));

        if (articleGroup.parentId) {
            const requestOptions = {
                method: "GET",
                headers: authHeader(),
            };
            return fetch(
                config.backendHost + "/ArticleGroups/getArticleGroupById/" + articleGroup.parentId,
                requestOptions
            )
                .then((response) => handleResponse(response))
                .then((json) => {
                    dispatch(setArticlesLoading(false));
                    dispatch(updateCurrentArticleGroups([]));
                    dispatch(updateCurrentArticles([]));
                    dispatch(setSelectedArticleGroup(json));
                });
        } else {
            dispatch(setArticlesLoading(false));
            dispatch(updateCurrentArticleGroups([]));
            dispatch(updateCurrentArticles([]));
            dispatch(updateSelectedArticleGroup(null));
            dispatch(getRootArticleGroups());
        }
    };
}

export const TOGGLE_SEARCH_POPUP = "TOGGLE_SEARCH_POPUP";
export function toggleSearchPopup(isSearchPopupOpen) {
    return {
        type: "TOGGLE_SEARCH_POPUP",
        payload: isSearchPopupOpen,
    };
}

export function getSearchResults(searchString) {
    return function (dispatch) {
        dispatch(setArticlesLoading(true));
        dispatch(updateSelectedArticleGroup(null));
        const requestOptions = {
            method: "GET",
            headers: authHeader(),
        };
        return Promise.all([
            fetch(config.backendHost + "/ArticleGroups/getArticleGroupsBySearchString/" + searchString, requestOptions)
                .then((response) => handleResponse(response))
                .then((json) => {
                    dispatch(updateCurrentArticleGroups(json));
                }),
            fetch(config.backendHost + "/Articles/getArticlesBySearchString/" + searchString, requestOptions)
                .then((response) => handleResponse(response))
                .then((json) => {
                    dispatch(updateCurrentArticles(json));
                }),
        ]).then(() => {
            dispatch(setArticlesLoading(false));
        });
    };
}
