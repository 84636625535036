import React from "react";
import { useTranslation } from "react-i18next";

export default function Tool(props) {
    const { t } = useTranslation(["dynamicTranslation"]);
    return (
        <React.Fragment>
            <div className="inner-box tool">
                <header>{t("Arbeitsgeräte")}</header>
                <div className="inner-box-content">
                    <table>
                        <tbody>
                            {props.stepTools &&
                                props.stepTools.map((stepTool) => {
                                    return (
                                        <tr key={stepTool.stepToolId}>
                                            <td className="name">{t(stepTool.Tool.toolName)}</td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    );
}
