import Config from "../../Config";
import { authHeader, handleResponse } from "../../helpers/requestHelpers";

const config = new Config();

export function getArticleDetailsRows(articlesId) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    return function () {
        return fetch(config.backendHost + "/ArticleDetailsRow/" + articlesId, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => json);
    };
}

export function createArticleDetailsRow(row) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(row),
    };
    return function () {
        return fetch(config.backendHost + "/ArticleDetailsRow", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => json);
    };
}

export function deleteArticleDetailsRow(articleDetailsRowsId) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    return function () {
        return fetch(config.backendHost + "/ArticleDetailsRow/delete/" + articleDetailsRowsId, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => json);
    };
}

export function updateArticleDetailsRow(row) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(row),
    };
    return function () {
        return fetch(config.backendHost + "/ArticleDetailsRow/updateArticleDetailsRow", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => json);
    };
}

export function getArticleDetailsContents(articleDetailsRowsId, column) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    return function () {
        return fetch(
            config.backendHost + "/ArticleDetailsContent/" + articleDetailsRowsId + "/" + column,
            requestOptions
        )
            .then((response) => handleResponse(response))
            .then((json) => json);
    };
}

export function createArticleDetailsContent(content) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(content),
    };
    return function () {
        return fetch(config.backendHost + "/ArticleDetailsContent", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => json);
    };
}

export function updateArticleDetailsContent(content) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(content),
    };
    return function () {
        return fetch(config.backendHost + "/ArticleDetailsContent/update", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => json);
    };
}

export function deleteArticleDetailsContent(content) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    return function () {
        return fetch(
            config.backendHost + "/ArticleDetailsContent/delete/" + content.articleDetailsContentsId,
            requestOptions
        ).then((response) => handleResponse(response));
    };
}

export function getArticlesWithRows() {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    return function () {
        return fetch(config.backendHost + "/ArticleDetailsRow/getArticlesWithRows", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => json);
    };
}

export function importArticleDetailsFromTo(fromArticlesId, toArticlesId) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    return function () {
        return fetch(
            config.backendHost + "/ArticleDetailsRow/importRowsFromArticle/" + fromArticlesId + "/" + toArticlesId,
            requestOptions
        ).then((response) => handleResponse(response));
    };
}

export function getArticleDetailsContentsByArticlesId(articlesId) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    return function () {
        return fetch(config.backendHost + "/ArticleDetailsContent/getByArticlesId/" + articlesId, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => json);
    };
}

export const ADD_TO_CLIPBOARD = "ADD_TO_CLIPBOARD";
export function addToClipboard(articleDetailsContent) {
    return {
        type: "ADD_TO_CLIPBOARD",
        payload: articleDetailsContent,
    };
}

export const REMOVE_FROM_CLIPBOARD = "REMOVE_FROM_CLIPBOARD";
export function removeFromClipboard(articleDetailsContent) {
    return {
        type: "REMOVE_FROM_CLIPBOARD",
        payload: articleDetailsContent,
    };
}

export const SET_CONTENT_TO_COPY = "SET_CONTENT_TO_COPY";
export function setContentToCopy(articleDetailsContent) {
    return {
        type: "SET_CONTENT_TO_COPY",
        payload: articleDetailsContent,
    };
}

export const RESET_CONTENT_TO_COPY = "RESET_CONTENT_TO_COPY";
export function resetContentToCopy(articleDetailsContent) {
    return {
        type: "RESET_CONTENT_TO_COPY",
    };
}
