import React, { Component } from "react";
import { DataGrid } from "devextreme-react";
import { Sorting, Editing, Column } from "devextreme-react/data-grid";
import { withTranslation } from "react-i18next";

class AllCookingProcessesDatagrid extends Component {
    render() {
        const { t } = this.props;
        return (
            <div>
                <DataGrid
                    id={"gridContainer"}
                    dataSource={this.props.childCookingProcesses}
                    columnAutoWidth={true}
                    ref={this.props.dataGridChildCookingProcesses}
                    rowAlternationEnabled={true}
                    allowColumnReordering={false}
                    onRowUpdated={this.props.childCookingProcessExistingWeightUpdated}
                    wordWrapEnabled={true}
                >
                    <Sorting mode="none" />
                    <Editing mode={"cell"} allowUpdating={true} selectTextOnEditStart={true} />
                    <Column
                        caption={t("Vorprodukte")}
                        dataField={"articleName"}
                        calculateDisplayValue={(e) => {
                            let array = e.articleName.replace(")", "").split(" (");
                            return t(array[0]) + " (" + t("für") + " " + t(array[1].replace("für ", "")) + ")";
                        }}
                        allowEditing={false}
                    />
                    <Column
                        caption={t("Dishes.AllreadyAvailableInG")}
                        width={200}
                        dataField={"existingWeight"}
                        allowEditing={true}
                    />
                    <Column
                        caption={t("benötigt (g)")}
                        width={200}
                        dataField={"weight"}
                        allowEditing={false}
                        calculateDisplayValue={(e) => {
                            var weight = 0;
                            if (this.props.switchedOn) {
                                // eslint-disable-next-line
                                this.props.articleSizesWithoutDefaultSize.map((articleSize) => {
                                    weight += articleSize.cookingProcessAmountHelper
                                        ? (e.weight / 100) *
                                          articleSize.cookingProcessAmountHelper *
                                          articleSize.portionSize
                                        : 0;
                                });
                            } else {
                                weight += (e.weight / 100) * this.props.parentCookingProcess.existingWeight;
                            }
                            return Math.max(0, Math.round(weight - e.existingWeight));
                        }}
                    />
                </DataGrid>
            </div>
        );
    }
}

export default withTranslation(["dynamicTranslation"])(AllCookingProcessesDatagrid);
