class Config {
    constructor() {
        this.backendHost = "/b";
        this.currencyFormat = "0[.]00 $";
        this.grammagesFormat = "0[.]00";
        this.selectedTheme = "Bios"; // möglich ["Bios","Rewe","Sandbox"] default="Bios"
        this.selectedImpressum = "FoodGenius"; // möglich ["FoodGenius","Bios"] default="FoodGenius"
        this.imageStorageBlobURL = "https://ggbiosmedia.blob.core.windows.net/ggbiosmedia/";
    }
}

export default Config;
