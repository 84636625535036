export const DRAGGED_ITEM = "DRAGGED_ITEM";
export function draggedItem(item) {
    return {
        type: DRAGGED_ITEM,
        draggedItem: item
    };
}

export const DROPPED_ON_ITEM = "DROPPED_ON_ITEM";
export function droppedOnItem(item) {
    return {
        type: DROPPED_ON_ITEM,
        droppedOnItem: item
    };
}
