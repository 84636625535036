import { HttpTransportType, HubConnectionBuilder, LogLevel, signalMiddleware, withCallbacks } from "redux-signalr";
import { setNewOrdersAvailable } from "../actions/orderConnectionActions";
import Config from "../Config";

const config = new Config();

export const connection = new HubConnectionBuilder()
    .configureLogging(LogLevel.None)
    .withUrl(config.backendHost + "/OrderHub", {
        skipNegotiation: false,
        transport: HttpTransportType.WebSockets,
    })
    .build();

const callbacks = withCallbacks().add("newOrdersAvailable", () => (dispatch) => {
    dispatch(setNewOrdersAvailable());
});

export const orderConnection = signalMiddleware({
    callbacks,
    connection,
    shouldConnectionStartImmediately: false,
});
