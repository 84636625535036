import Config from "../../Config";
import { authHeader, handleResponse } from "../../helpers/requestHelpers";

const config = new Config();

export const RECEIVE_CALCULATENUTRITIONALVALUES =
    "RECEIVE_CALCULATENUTRITIONALVALUES";
export function receiveCalculateNutritionalValues(string) {
    return {
        type: RECEIVE_CALCULATENUTRITIONALVALUES,
        nutritionalValuesByGrammages: string
    };
}

/* Diese Funtion lässt die Nährwerte eines Artikels anhand seiner Grammaturen berechnen */
export function calculateNutritionalValues(articlesId, sizesId) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ articlesId, sizesId })
    };
    return function (dispatch) {
        return fetch(
            config.backendHost + "/Articles/CalculateNutritionalValues",
            requestOptions
        )
            .then(response => handleResponse(response)
                    .then(response => response.text())
                    .then(s => dispatch(receiveCalculateNutritionalValues(s)))
                    .then(() => dispatch(fetchArticleNutritionalValues(articlesId)))
            );
    };
}

export function autoRecalculateNutritionValues(articlesId) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ articlesId })
    };
    return function () {
        return fetch(
                config.backendHost + "/Articles/AutoRecalculateNutritionValues",
                requestOptions)
            .then(response => handleResponse(response))            
    };
}


export function updateArticleNutritionalValue(
    articlesId,
    nutritionalValuesId,
    value
) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ articlesId, nutritionalValuesId, value })
    };
    return function () {
        return fetch(
            config.backendHost + "/Articles/UpdateArticleNutritionalValue",
            requestOptions
        ).then(response => handleResponse(response))
    };
}

/*Diese Funktion lädt alle Nährwerte eines Artikels*/
export function fetchArticleNutritionalValues(articlesId) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ articlesId })
    };
    return function (dispatch) {
        return fetch(
            config.backendHost + "/Articles/GetArticleNutritionalValues",
            requestOptions
        )
            .then(response => handleResponse(response))
    };
}

export function getAllNutritionalValues(){
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
    };   
    return function (dispatch) {
        return fetch(
            config.backendHost + "/Articles/GetAllNutritionalValues", requestOptions
        )
        .then(response =>             
            handleResponse(response)
        );
    };
}

export function setNutritionalValues(nutritionalValues)
{
    console.log(nutritionalValues);
    const requestOptions = { 
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({nutritionalValues}),
    };

    return function (dispatch) {
        return fetch(
            config.backendHost + "/Articles/SetNutritionalValuesAssignment", requestOptions)
            .then(response => handleResponse(response)
        );
    };
}


