import Config from "../Config";
import { authHeader, handleResponse } from "../helpers/requestHelpers";

const config = new Config();

export const REQUEST_ARTICLEARTICLEGROUP = "REQUEST_ARTICLEARTICLEGROUP";
export function requestArticleArticleGroup() {
    return {
        type: REQUEST_ARTICLEARTICLEGROUP,
    };
}

export const RECEIVE_ARTICLEARTICLEGROUP = "RECEIVE_ARTICLEARTICLEGROUP";
export function receiveArticleArticleGroup(json, isArticle, isLink) {
    return {
        type: RECEIVE_ARTICLEARTICLEGROUP,
        articleArticleGroup: json,
        isArticle: isArticle,
        isLink: isLink,
    };
}

export const RECEIVE_SERVERMENUCARDTIMESTAMP = "RECEIVE_SERVERMENUCARDTIMESTAMP";
export function receive_serverMenuCardTimestamp(json) {
    return {
        type: RECEIVE_SERVERMENUCARDTIMESTAMP,
        serverMenucardTimestamp: json,
    };
}

/*Hier wird die Dummyliste aller Artikel, Artikellinks und Artikelgruppen geladen*/
export function fetchArticleArticleGroup(isArticle, isLink, key) {
    let url = isArticle
        ? isLink
            ? config.backendHost + "/ArticleLinks/getArticleLinks"
            : config.backendHost + "/Articles/getArticle"
        : config.backendHost + "/ArticleGroups/getArticleGroupWithKey";

    return function (dispatch) {
        dispatch(requestArticleArticleGroup());

        return fetch(url, {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify({ key }),
        })
            .then((response) => handleResponse(response))
            .then((json) => {
                if (json && json.Sizes) json.Sizes.forEach((size) => delete size.deleted);
                dispatch(receiveArticleArticleGroup(json, isArticle, isLink));
            });
    };
}

export * from "./menucardActions/absolutGrammagesBySizeAction";
export * from "./menucardActions/additivesAction";
export * from "./menucardActions/articleAction";
export * from "./menucardActions/articleDependencyAction";
export * from "./menucardActions/articleGroupAction";
export * from "./menucardActions/articleLinkAction";
export * from "./menucardActions/articleSizesAction";
export * from "./menucardActions/categoriesAction";
export * from "./menucardActions/ingredientAction";
export * from "./menucardActions/nutritionalValuesAction";
export * from "./menucardActions/orderTypeAction";
export * from "./menucardActions/priceGroupAction";
export * from "./menucardActions/pricesAction";
export * from "./menucardActions/sizesAction";
