import CheckIcon from "@material-ui/icons/Check";
import { Button } from "devextreme-react";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.compact.css";
import React, { Component } from "react";
import { history } from "../../../helpers/history";
import "./cookingProcessStep.scss";
import notify from "devextreme/ui/notify";
import { getToast } from "../../../helpers/requestHelpers";
import { withTranslation } from "react-i18next";
import CountDownTimer from "../../core/countDownTimer/countDownTimer";

class CookingProcessStep extends Component {
    constructor(props) {
        super(props);

        this.state = {
            stepBlink1: false,
            stepBlink2: false,
            stepBlink3: false,
        };
        this.handleStepClick = this.handleStepClick.bind(this);
        this.stepTitle = this.stepTitle.bind(this);
        this.startTimer = this.startTimer.bind(this);
    }

    componentDidMount() {
        if (this.props.isCurrentStep && this.props.currentTimerStart) {
            this.startTimer();
        }
    }

    handleStepClick() {
        if (this.props.disabledMessage !== null) {
            notify(getToast(this.props.disabledMessage, "error"));
            return;
        }
        this.props.toggleLoadPanel();
        if (this.props.isCurrentStep) {
            if (this.props.isCookingProcessCustomer) {
                if (this.props.stepNumber === 1) {
                    history.push(
                        "/guest/cookingProcessIngredientList/" +
                            this.props.cookingProcessId +
                            "/" +
                            this.props.phase.phasesId +
                            "/" +
                            this.props.step.stepsId +
                            "/" +
                            this.props.customersUid
                    );
                } else {
                    history.push(
                        "/guest/cookingProcess/" +
                            this.props.cookingProcessId +
                            "/" +
                            this.props.phase.phasesId +
                            "/" +
                            this.props.step.stepsId +
                            "/" +
                            this.props.customersUid
                    );
                }
            } else {
                if (this.props.stepNumber === 1) {
                    history.push(
                        "/zutatenliste/" +
                            this.props.cookingProcessId +
                            "/" +
                            this.props.phase.phasesId +
                            "/" +
                            this.props.step.stepsId
                    );
                } else {
                    history.push(
                        "/kochprozess/" +
                            this.props.cookingProcessId +
                            "/" +
                            this.props.phase.phasesId +
                            "/" +
                            this.props.step.stepsId
                    );
                }
            }
        }
    }

    stepTitle() {
        const { t } = this.props;
        let title = t("Schritt") + " " + this.props.stepNumber + ":";
        if (this.props.singleView) {
            title =
                t("Schritt") +
                " " +
                this.props.stepNumber +
                " " +
                t("CookingProcess.of") +
                " " +
                this.props.phase.Steps.length;
        }
        return title;
    }

    startTimer() {
        var startDate = new Date(this.props.currentTimerStart);

        var countDownDate = startDate.getTime() + this.props.step.stepTimeSec * 1000;
        countDownDate = new Date(countDownDate);

        this.timerInterval = setInterval(() => {
            this.setState({
                stepBlink1: false,
                stepBlink2: false,
                stepBlink3: false,
            });

            var now = new Date().getTime();

            let timeUntilZero = countDownDate - now;

            // var days, hours, minutes, seconds;

            // if (timeUntilZero >= 0) {
            //     days = Math.floor(timeUntilZero / (1000 * 60 * 60 * 24));
            //     hours = Math.floor((timeUntilZero % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            //     minutes = Math.floor((timeUntilZero % (1000 * 60 * 60)) / (1000 * 60));
            //     seconds = Math.floor((timeUntilZero % (1000 * 60)) / 1000);
            // } else {
            //     days = Math.abs(Math.ceil(timeUntilZero / (1000 * 60 * 60 * 24)));
            //     hours = Math.abs(Math.ceil((timeUntilZero % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
            //     minutes = Math.abs(Math.ceil((timeUntilZero % (1000 * 60 * 60)) / (1000 * 60)));
            //     seconds = Math.abs(Math.ceil((timeUntilZero % (1000 * 60)) / 1000));
            // }

            var status = Math.floor(timeUntilZero / 1000);

            if (status >= 0 && status <= 10) {
                this.setState({
                    stepBlink1: true,
                });
            }
            if (status < 0 && status >= -10) {
                this.setState({
                    stepBlink2: true,
                });
            }
            if (status < -10) {
                this.setState({
                    stepBlink3: true,
                });
            }
        }, 1000);
    }

    render() {
        const { t } = this.props;
        var className = "";
        if (this.props.isDone || this.props.disabledMessage !== null) {
            className = "step stepDone";
        } else {
            if (this.state.stepBlink1) {
                className = "step stepOpen blinker1";
            } else if (this.state.stepBlink2) {
                className = "step stepOpen blinker2";
            } else if (this.state.stepBlink3) {
                className = "step stepOpen blinker3";
            } else {
                className = "step stepOpen";
            }
        }
        return (
            <div className={className}>
                <div className="stepNumber">{this.stepTitle()}</div>
                <div className="stepTextContainer">
                    <div className="stepText">{t(this.props.step.text)}</div>
                    <div className="stepButton">
                        {this.props.isDone ? <CheckIcon className="statusIcon" /> : <div />}
                    </div>
                </div>
                {this.props.isCurrentStep && this.props.showButtons && this.props.disabledMessage === null && (
                    <div style={{ display: "flex", justifyContent: "center", paddingBottom: 5 }}>
                        <Button
                            className="buttonConfirm"
                            text={this.props.currentTimerStart ? t("CookingProcess.Show") : t("CookingProcess.Start")}
                            type="default"
                            onClick={this.handleStepClick}
                        />
                    </div>
                )}
                {this.props.isCurrentStep && this.props.currentTimerStart && (
                    <div className="countDownTimer">
                        <CountDownTimer
                            duration={this.props.step.stepTimeSec}
                            startTime={this.props.currentTimerStart}
                            type={"progressbar"}
                        />
                    </div>
                )}
            </div>
        );
    }
}

export default withTranslation(["dynamicTranslation"])(CookingProcessStep);
