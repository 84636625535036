import { RECEIVE_LOGIN_TRANSLATIONS } from "../actions/menuCardAction";
import { RECEIVE_VERSION } from "../actions/settingActions";

const initialState = {
    version: "",
    loginTranslations: {
        email: "E-Mail",
        password: "Passwort",
        fieldIsRequired: "Dieses Feld wird benötigt",
        enterLicenseKey: "Bitte gültigen Lizenzschlüssel eingeben!",
        login: "Login",
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case RECEIVE_VERSION:
            return Object.assign({}, state, {
                version: action.version.version,
            });
        case RECEIVE_LOGIN_TRANSLATIONS:
            return Object.assign({}, state, {
                loginTranslations: action.loginTranslations,
            });
        default:
            return state;
    }
};
