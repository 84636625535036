import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Content from "./content/content";
import GuestHeader from "./header/guestHeader";
class GuestLayout extends Component {
    async componentDidMount() {
        const { i18n } = this.props;
        i18n.init();
    }
    render() {
        return (
            <React.Fragment>
                <GuestHeader />
                <Content noNav={true} />
            </React.Fragment>
        );
    }
}
export default withTranslation(["dynamicTranslation"])(GuestLayout);
