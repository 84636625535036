import { IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { getNavigationFavorites } from "../../../../actions/userActions";
import store from "../../../../store";
import EditFavoritesPopup from "./editFavoritesPopup";
import "./navigationPage.scss";

class NavigationPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showEditFavoritesPopup: false,
            allNavigationTiles: undefined,
            favoriteTiles: undefined,
        };

        this.toggleFavoritesPopup = this.toggleFavoritesPopup.bind(this);
        this.update = this.update.bind(this);
    }

    toggleFavoritesPopup() {
        this.setState({ showEditFavoritesPopup: !this.state.showEditFavoritesPopup });
    }

    componentDidMount() {
        if (!this.props.identity.user.Favorites)
            store.dispatch(getNavigationFavorites(this.props.identity.user.Favorites)).then(() => {});
        else {
            let favorites = this.props.identity.user.Favorites;
            let favoriteTiles = undefined;

            let allNavigationTiles = [];

            this.props.navigation.pages.forEach((page) => {
                if (page.navigationTiles) allNavigationTiles = allNavigationTiles.concat(page.navigationTiles);
            });

            if (favorites) {
                favoriteTiles = allNavigationTiles.filter((tile) =>
                    favorites.some((element) => element.route === tile.route)
                );
            }

            this.setState({ allNavigationTiles: allNavigationTiles, favoriteTiles: favoriteTiles });
        }
    }

    update() {
        let favorites = this.props.identity.user.Favorites;
        let favoriteTiles = undefined;

        let allNavigationTiles = [];

        this.props.navigation.pages.forEach((page) => {
            if (page.navigationTiles) allNavigationTiles = allNavigationTiles.concat(page.navigationTiles);
        });

        if (favorites) {
            favoriteTiles = allNavigationTiles.filter((tile) =>
                favorites.some((element) => element.route === tile.route)
            );
        }

        this.setState({ allNavigationTiles: allNavigationTiles, favoriteTiles: favoriteTiles });
    }
    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                <div className="content" style={{ height: "100%" }}>
                    <div
                        className="tilesContainerWrapper"
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            alignItems: "center",
                        }}
                    >
                        <div>
                            <div id="tilesContainer">
                                <div className="tiles">
                                    <header>{t(this.props.page.title)}</header>
                                    <div className="revealed">
                                        {this.props.page &&
                                            this.props.page.navigationTiles &&
                                            this.props.page.navigationTiles.map((tile, index) => {
                                                if (tile.hasRight && !tile.hideTile)
                                                    return (
                                                        <Link
                                                            key={index}
                                                            className={tile.hasRight ? "" : "disabledTile"}
                                                            to={tile.route ? tile.route : ""}
                                                        >
                                                            <tile.icon icon={tile.iconText ? tile.iconText : ""} />{" "}
                                                            <span>{t(tile.title)}</span>
                                                        </Link>
                                                    );
                                                else return <React.Fragment key={index} />;
                                            })}
                                    </div>
                                </div>
                            </div>

                            <div id="tilesContainer">
                                <div className="tiles">
                                    <div className="revealed">
                                        {this.state.favoriteTiles && (
                                            <React.Fragment>
                                                <header>
                                                    {t("Favoriten")}
                                                    <IconButton
                                                        title={t("Favoriten bearbeiten")}
                                                        children={<EditIcon />}
                                                        onClick={this.toggleFavoritesPopup}
                                                    />
                                                </header>
                                                {this.props.page &&
                                                    this.state.favoriteTiles &&
                                                    this.state.favoriteTiles
                                                        .filter((tile) => tile.hasRight)
                                                        .map((tile, index) => {
                                                            return (
                                                                <Link
                                                                    key={index}
                                                                    className={tile.hasRight ? "" : "disabledTile"}
                                                                    to={tile.route ? tile.route : ""}
                                                                >
                                                                    <tile.icon
                                                                        icon={tile.iconText ? tile.iconText : ""}
                                                                    />
                                                                    <span>{t(tile.title)}</span>
                                                                </Link>
                                                            );
                                                        })}
                                            </React.Fragment>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.showEditFavoritesPopup && (
                    <EditFavoritesPopup
                        visible={this.state.showEditFavoritesPopup}
                        allNavigationTiles={this.state.allNavigationTiles}
                        favoriteTiles={this.state.favoriteTiles}
                        togglePopup={this.toggleFavoritesPopup}
                        update={this.update}
                    />
                )}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { identity, navigation } = state;
    return {
        identity,
        navigation,
    };
}

export default compose(connect(mapStateToProps), withTranslation(["dynamicTranslation"]))(NavigationPage);
