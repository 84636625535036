import React, { Component } from "react";
import RegisterForm from "./registerForm/registerForm";
import "./register.scss";
import { withTranslation } from "react-i18next";

class Register extends Component {
    render() {
        const { t } = this.props;
        return (
            <div className="register">
                <div className="registerBox">
                    <h2>{t("Register.CreateNewUser")}</h2>
                    <RegisterForm />
                </div>
            </div>
        );
    }
}

export default withTranslation(["dynamicTranslation"])(Register);
