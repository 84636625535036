import Config from "../../Config";
import { authHeader, handleResponse } from "../../helpers/requestHelpers";

const config = new Config();

export const REQUEST_ALLARTICLESIZES = "REQUEST_ALLARTICLESIZES";
export function requestAllArticlesSizes() {
    return {
        type: REQUEST_ALLARTICLESIZES
    };
}

export const RECEIVE_ALLARTICLESIZES = "RECEIVE_ALLARTICLESIZES";
export function receiveAllArticleSizes(json) {
    return {
        type: RECEIVE_ALLARTICLESIZES,
        allArticleSizes: json
    };
}

export function fetchAllArticleSizes() {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };
    return function (dispatch) {
        dispatch(requestArticlesSizes());
        return fetch(config.backendHost + "/Sizes/Get", requestOptions)
            .then(response => handleResponse(response))
            .then(json => dispatch(receiveAllArticleSizes(json)));
    };
}

export const REQUEST_ARTICLESIZES = "REQUEST_ARTICLESIZES";
export function requestArticlesSizes() {
    return {
        type: REQUEST_ARTICLESIZES
    };
}

export const RECEIVE_ARTICLESIZES = "RECEIVE_ARTICLESIZES";
export function receiveArticleSizes(json) {
    return {
        type: RECEIVE_ARTICLESIZES,
        articleSizes: json
    };
}

export function fetchArticleSizesByArticlesId(articlesId) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(articlesId)
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Articles/GetArticleSizesByArticlesId", requestOptions)
            .then(response => handleResponse(response))
            .then(json => dispatch(receiveArticleSizes(json)))
    }
}

export function addSizeToArticle(articlesId, sizesId) {
    const requestOptions = {
        method: "Post",
        headers: authHeader(),
        body: JSON.stringify({ articlesId: articlesId, sizesId: sizesId })
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Articles/addSizeToArticle", requestOptions)
            .then(response => handleResponse(response))
            .then(json => dispatch(receiveArticleSizes(json)))
    }
}

export function updateArticleSizes(articlesId, values, forceDelete) {
    const requestOptions = {
        method: "Post",
        headers: authHeader(),
        body: JSON.stringify({ articlesId: articlesId, sizes: values, forceDelete: forceDelete })
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Articles/updateArticleSizes", requestOptions)
            .then(response => handleResponse(response))
    }
}

export function deleteSizeToArticle(articlesId, sizesId) {
    const requestOptions = {
        method: "Post",
        headers: authHeader(),
        body: JSON.stringify({ articlesId: articlesId, sizesId: sizesId })
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Articles/deleteSizeToArticle", requestOptions)
            .then(response => handleResponse(response))
    }
}

export function forceDeleteSizeToArticle(articlesId, sizesId) {
    const requestOptions = {
        method: "Post",
        headers: authHeader(),
        body: JSON.stringify({ articlesId: articlesId, sizesId: sizesId })
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Articles/forceDeleteSizeToArticle", requestOptions)
            .then(response => handleResponse(response))
            .then(json => dispatch(receiveArticleSizes(json)))
    }
}