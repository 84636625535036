import { SET_CURRENT_PAGE } from "../actions/pagesActions";

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_CURRENT_PAGE:
            return Object.assign({}, state, {
                currentPage: action.page,
            });

        default:
            return state;
    }
};

const initialState = { currentPage: {} };
