import Config from "../../Config";
import { fetchArticlesByArticleGroup } from "./articleAction";
import { authHeader, handleResponse } from "../../helpers/requestHelpers";

const config = new Config();

export function fetchArticleLinks(articleGroupsId, successCallback) {

    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(articleGroupsId)
    };

    return function(dispatch) {
        return fetch(config.backendHost + "/ArticleLinks/Get", requestOptions)
            .then(response => handleResponse(response))
            .then(json => {
                if (successCallback) {
                    dispatch(successCallback(json));
                }
            });
    };
}

export const RECEIVE_ARTICLELINKSBYARTICLEGROUP =
    "RECEIVE_ARTICLELINKSBYARTICLEGROUP";
export function receiveArticleLinksByArticleGroup(json) {
    return {
        type: RECEIVE_ARTICLELINKSBYARTICLEGROUP,
        articleLinksByArticleGroup: json.data
    };
}

export function postArticleLink(articlesId, articleGroupsId) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ articlesId, articleGroupsId })
    };
    return function(dispatch) {
        return fetch(
            config.backendHost + "/ArticleLinks/AddNewArticleLink",
            requestOptions
        )
            .then(response => handleResponse(response))
            .then(result =>
                dispatch(fetchArticlesByArticleGroup(articleGroupsId))
            );
    };
}

export function deleteArticleLink(articlesId, articleGroupsId) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ articlesId, articleGroupsId })
    };
    return function(dispatch) {
        return fetch(
            config.backendHost + "/ArticleLinks/Delete",
            requestOptions
        )
            .then(response => handleResponse(response))
            .then(result =>
                dispatch(fetchArticlesByArticleGroup(articleGroupsId))
            );
    };
}
