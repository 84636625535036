import { userConstants } from "../actions/userActions";

export function users(state = {}, action) {
    switch (action.type) {
        case userConstants.GETALL_REQUEST:
            return Object.assign({}, state, {
                loading: true,
            });
        case userConstants.GETALL_SUCCESS:
            return Object.assign({}, state, {
                items: action.users,
            });
        case userConstants.GETALL_FAILURE:
            return Object.assign({}, state, {
                error: action.error,
            });
        case userConstants.GETALLROLES_REQUEST:
            return Object.assign({}, state, {
                loading: true,
            });
        case userConstants.GETALLROLES_SUCCESS:
            return Object.assign({}, state, {
                allRoles: action.roles,
            });
        case userConstants.GETALLROLES_FAILURE:
            return Object.assign({}, state, {
                error: action.error,
            });
        case userConstants.GETUSERROLES_REQUEST:
            return Object.assign({}, state, {
                loading: true,
            });
        case userConstants.GETUSERROLES_SUCCESS:
            return Object.assign({}, state, {
                allUserRoles: action.userroles,
            });
        case userConstants.GETUSERROLES_FAILURE:
            return Object.assign({}, state, {
                error: action.error,
            });
        case userConstants.GETUSERRIGHTS_REQUEST:
            return Object.assign({}, state, {
                loading: true,
            });
        case userConstants.GETUSERRIGHTS_SUCCESS:
            return Object.assign({}, state, {
                allUserRights: action.userrights,
            });
        case userConstants.GETUSERRIGHTS_FAILURE:
            return Object.assign({}, state, {
                error: action.error,
            });
        case userConstants.DELETE_REQUEST:
            // add 'deleting:true' property to user being deleted
            return Object.assign({}, state, {
                ...state,
                items: state.items.map((user) => (user.id === action.id ? { ...user, deleting: true } : user)),
            });
        case userConstants.DELETE_SUCCESS:
            // remove deleted user from state
            return Object.assign({}, state, {
                items: state.items.filter((user) => user.id !== action.id),
            });
        case userConstants.DELETE_FAILURE:
            // remove 'deleting:true' property and add 'deleteError:[error]' property to user
            return Object.assign({}, state, {
                ...state,
                items: state.items.map((user) => {
                    if (user.id === action.id) {
                        // make copy of user without 'deleting:true' property
                        const { deleting, ...userCopy } = user;
                        // return copy of user with 'deleteError:[error]' property
                        return { ...userCopy, deleteError: action.error };
                    }

                    return user;
                }),
            });
        case userConstants.SET_NAVIGATIONFAVORITES_SUCCESS:
            return Object.assign({}, state, {
                ...state,
                favorites: action.favorites,
            });

        default:
            return state;
    }
}
