import { Button, Card, CardActions, CardContent, Typography } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Calendar, LoadPanel, Popup } from "devextreme-react";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.compact.css";
import { loadMessages, locale } from "devextreme/localization";
import deMessages from "devextreme/localization/messages/de.json";
import notify from "devextreme/ui/notify";
import moment from "moment";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import SwiperCore, { Navigation, Pagination } from "swiper";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import {
    fetchAllCookingProcessesByCustomerUIDAndDay,
    finishCookingProcessCustomer,
    setCookingProcessesCalendarDate,
    showCookingProcessesCalendar,
} from "../../../actions/cookingProcessActions";
import { getAllDaysToCookCustomerUid, getCustomerByCustomerUid } from "../../../actions/customerActions";
import { history } from "../../../helpers/history";
import { getToast } from "../../../helpers/requestHelpers";
import { formatGrammage, getGrammageUnit } from "../../../helpers/units";
import store from "../../../store";
import AllCookingProcessesOverviewForm from "./allCookingProcessesOverviewForm";

SwiperCore.use([Navigation, Pagination]);

class AllCookingProcessesOverviewCustomer extends Component {
    constructor(props) {
        super(props);
        SwiperCore.use([Pagination]);
        loadMessages(deMessages);

        locale(navigator.language);
        this.state = {
            cookingProcesses: [],
            cookingProcessesCompleted: [],
            completedCookingProcessesVisble: false,
            customersUid: this.props.match.params.customersUid ? this.props.match.params.customersUid : null,
            parentId: this.props.match.params.parentId ? parseInt(this.props.match.params.parentId) : null,
            showChildCookingProcesses: this.props.match.params.type ? this.props.match.params.type === "p" : true,
            isLoading: false,
            showPrintPopup: false,
            calendarVisible: false,
            requestedDeliveryTimes: [],
            nextCookingProcessDate: null,
        };

        this.finishCookingProcess = this.finishCookingProcess.bind(this);
        this.toggleLoadPanel = this.toggleLoadPanel.bind(this);
        this.groupCookingProcessesBypreferredConsumingDay =
            this.groupCookingProcessesBypreferredConsumingDay.bind(this);
        this.toggleCalendarVisible = this.toggleCalendarVisible.bind(this);
        this.cellTemplate = this.cellTemplate.bind(this);
        this.onCalendarClick = this.onCalendarClick.bind(this);
        this.findClosestPrevDate = this.findClosestPrevDate.bind(this);
        this.goToNextDate = this.goToNextDate.bind(this);
    }

    async componentDidMount() {
        const { t, i18n } = this.props;
        this.toggleLoadPanel(true);
        loadMessages({
            en: {
                Ok: t("Ok"),
                Cancel: t("Abbrechen"),
            },
        });
        localStorage.setItem("customersUid", this.state.customersUid);

        await Promise.all([
            store
                .dispatch(getCustomerByCustomerUid(this.state.customersUid))
                .then((response) => {
                    this.setState({ customer: response });
                    i18n.changeLanguage(response.selectedLanguage);
                })
                .catch((err) => {
                    notify(getToast(t("CookingProcess.ErrorLoadingCustomerData"), "error"));
                }),
            store
                .dispatch(getAllDaysToCookCustomerUid(this.state.customersUid))
                .then((response) => {
                    this.setState({ requestedDeliveryTimes: response });
                })
                .catch((err) => {
                    notify(getToast(t("CookingProcess.ErrorLoadingCustomerData"), "error"));
                }),
            store
                .dispatch(
                    fetchAllCookingProcessesByCustomerUIDAndDay(
                        this.state.customersUid,
                        this.props.cookingProcess.cookingProcessesCalendarDate
                    )
                )
                .then((response) => {
                    this.setState({ cookingProcesses: response });
                })
                .catch((err) => {
                    notify(getToast(t("CookingProcess.LoadingError"), "error"));
                }),
        ]);
        this.toggleLoadPanel(false);
        if (this.state.cookingProcesses.length === 0) {
            store
                .dispatch(getAllDaysToCookCustomerUid(this.state.customersUid))
                .then((response) => {
                    var now = new Date(Date.now());
                    var nextDate = this.findClosestPrevDate(response, now);
                    if (nextDate != null) this.setState({ nextCookingProcessDate: nextDate });
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { t } = this.props;
        let parentId = null;
        let showChildCookingProcesses = true;
        localStorage.setItem("customersUid", this.state.customersUid);
        if (this.props.match.params.parentId) {
            parentId = parseInt(this.props.match.params.parentId);
        }
        if (this.props.match.params.type) {
            showChildCookingProcesses = this.props.match.params.type === "p";
        }

        if (this.state.parentId !== parentId) {
            this.setState({
                parentId: parentId,
            });
        }
        if (this.state.showChildCookingProcesses !== showChildCookingProcesses) {
            this.setState({
                showChildCookingProcesses: showChildCookingProcesses,
            });
        }
        if (
            this.props.cookingProcess.cookingProcessesCalendarDate !==
            prevProps.cookingProcess.cookingProcessesCalendarDate
        ) {
            this.toggleLoadPanel(true);
            store
                .dispatch(
                    fetchAllCookingProcessesByCustomerUIDAndDay(
                        this.state.customersUid,
                        this.props.cookingProcess.cookingProcessesCalendarDate
                    )
                )
                .then((response) => {
                    this.toggleLoadPanel(false);
                    this.toggleCalendarVisible();
                    console.log(response);
                    this.setState({ cookingProcesses: response });
                })
                .catch((err) => {
                    this.toggleLoadPanel(false);
                    notify(getToast(t("CookingProcess.LoadingError"), "error"));
                });
        }
    }

    toggleCalendarVisible() {
        store.dispatch(showCookingProcessesCalendar(false));
    }

    finishCookingProcess(cookingProcessId, date) {
        const { t } = this.props;
        this.toggleLoadPanel(true);
        store
            .dispatch(finishCookingProcessCustomer(cookingProcessId, this.state.customersUid))
            .then((response) => {
                notify(getToast(t("CookingProcess.FinishSuccess"), "success"));
                store
                    .dispatch(fetchAllCookingProcessesByCustomerUIDAndDay(this.state.customersUid, date ? date : null))
                    .then((response) => {
                        console.log(response);
                        this.setState({ cookingProcesses: response, isLoading: false }, () => {
                            history.push("/guest/allCookingProcessesCustomer/" + this.state.customersUid + "/p");
                        });
                    });
            })
            .catch((err) => {
                this.toggleLoadPanel(false);
                notify(getToast(t("CookingProcess.FinishError"), "error"));
            });
    }

    toggleLoadPanel(isLoading) {
        this.setState({
            isLoading: isLoading,
        });
    }

    groupCookingProcessesBypreferredConsumingDay(cookingProcesses) {
        var parentId = this.state.parentId;
        var reducedList = cookingProcesses.reduce(function (rv, x) {
            if (x.parentId === parentId) {
                (rv[x.preferredConsumingDay] = rv[x.preferredConsumingDay] || []).push(x);
            }
            return rv;
        }, {});
        var groupedCookingProcesses = [];
        for (const [key, value] of Object.entries(reducedList)) {
            groupedCookingProcesses.push({ key: key, value: value });
        }
        groupedCookingProcesses.sort(function (a, b) {
            return new Date(a.key) - new Date(b.key);
        });
        return groupedCookingProcesses;
    }

    onCalendarClick(e) {
        store.dispatch(setCookingProcessesCalendarDate(e.value));
    }

    cellTemplate(cell, requestedDeliveryTimes) {
        return <span className={this.getCellCssClass(cell.date, requestedDeliveryTimes)}>{cell.text}</span>;
    }

    getCellCssClass(date, requestedDeliveryTimes) {
        if (requestedDeliveryTimes.some((e) => this.datesAreOnSameDay(new Date(e), new Date(date)))) {
            return "requestedDeliveryDay";
        }
        return "";
    }

    cellDisabled(date, requestedDeliveryTimes) {
        if (date.view === "month")
            return !requestedDeliveryTimes.some((e) => this.datesAreOnSameDay(new Date(e), new Date(date.date)));
        else return false;
    }

    findClosestPrevDate(arr, target) {
        let targetDate = new Date(target);
        let previousDates = arr.filter((e) => targetDate - new Date(e) < 0);
        let sortedPreviousDates = previousDates.filter((a, b) => new Date(a) - new Date(b));
        return sortedPreviousDates[0] || null;
    }

    datesAreOnSameDay(first, second) {
        return (
            first.getFullYear() === second.getFullYear() &&
            first.getMonth() === second.getMonth() &&
            first.getDate() === second.getDate()
        );
    }

    goToNextDate() {
        store.dispatch(setCookingProcessesCalendarDate(this.state.nextCookingProcessDate));
    }

    render() {
        const { t } = this.props;
        const { cookingProcess } = this.props;
        moment.locale(cookingProcess.customerLanguage);
        locale(cookingProcess.customerLanguage);
        return (
            <div id="cookingProcessCustomer">
                {this.state.cookingProcess && this.state.cookingProcess.ParentCookingProcess && (
                    <div className="parentCookingProcess">
                        <h1 className="title">
                            {this.state.cookingProcess && this.state.cookingProcess.ParentCookingProcess.weight === 0
                                ? ""
                                : formatGrammage(
                                      this.state.cookingProcess && this.state.cookingProcess.ParentCookingProcess.weight
                                  ) +
                                  " " +
                                  getGrammageUnit(
                                      this.state.cookingProcess && this.state.cookingProcess.ParentCookingProcess.weight
                                  )}{" "}
                            {this.state.cookingProcess && this.state.cookingProcess.ParentCookingProcess.Article.name}
                        </h1>
                    </div>
                )}
                {this.state.showChildCookingProcesses && (
                    <div className="cookingProcessCustomerHeader">
                        <p className="cookingProcessCustomerWelcomeText">
                            <span className="title">
                                {t("CookingProcess.hello")}
                                {this.state.customer
                                    ? " " + this.state.customer.firstName + " " + this.state.customer.lastName
                                    : ""}
                                ,
                            </span>{" "}
                            <span>{t("CookingProcess.haveFun")}</span>
                        </p>
                    </div>
                )}
                <div id="allCookingProcessesOverview" className="guest">
                    <LoadPanel visible={this.state.isLoading} />
                    <div className="tilesContainerWrapper">
                        <div id="tilesContainer">
                            {this.state.cookingProcesses && (
                                <React.Fragment>
                                    <div className="groupedCookingProcessesCustomer">
                                        <Swiper
                                            spaceBetween={20}
                                            autoHeight={true}
                                            centeredSlides={false}
                                            pagination={{ el: ".my-custom-pagination-div", clickable: true }}
                                            paginationstyle={{ position: "absolute", bottom: "15" }}
                                            breakpoints={{
                                                768: {
                                                    slidesPerView: 2,
                                                    autoHeight: false,
                                                },
                                                1024: {
                                                    slidesPerView: 3,
                                                    autoHeight: false,
                                                },
                                                1366: {
                                                    slidesPerView: 4,
                                                    autoHeight: false,
                                                },
                                                1680: {
                                                    slidesPerView: 5,
                                                    autoHeight: false,
                                                },
                                                1920: {
                                                    slidesPerView: 6,
                                                    autoHeight: false,
                                                },
                                            }}
                                        >
                                            {!this.state.isLoading && (!this.state.cookingProcesses ||
                                                this.state.cookingProcesses.length === 0) && (
                                                <Card variant="outlined" onClick={this.goToNextDate}>
                                                    <CardContent>
                                                        <Typography
                                                            variant="h6"
                                                            component="div"
                                                            sx={{ fontSize: 14 }}
                                                            gutterBottom
                                                        >
                                                            Für heute sind keine Kochprozesse verfügbar.
                                                        </Typography>
                                                    </CardContent>
                                                    <CardActions style={{ justifyContent: "flex-end" }}>
                                                        <Button onClick={this.goToNextDate}>
                                                            Nächster Kochprozess{" "}
                                                            {moment(new Date(this.state.nextCookingProcessDate)).format(
                                                                "dd DD. MMM"
                                                            )}{" "}
                                                            <ArrowForwardIcon />
                                                        </Button>
                                                    </CardActions>
                                                </Card>
                                            )}
                                            {this.state.cookingProcesses &&
                                                this.state.showChildCookingProcesses &&
                                                this.state.cookingProcesses.map((cookingProcess, index) => {
                                                    if (
                                                        cookingProcess.parentId === this.state.parentId ||
                                                        !cookingProcess.parentId
                                                    ) {
                                                        return (
                                                            <SwiperSlide key={index}>
                                                                <AllCookingProcessesOverviewForm
                                                                    key={cookingProcess.cookingProcessId}
                                                                    data={cookingProcess}
                                                                    finishCookingProcess={this.finishCookingProcess}
                                                                    isCookingProcessCustomer={true}
                                                                    customersUid={this.state.customersUid}
                                                                />
                                                            </SwiperSlide>
                                                        );
                                                    } else return <React.Fragment />;
                                                })}
                                            {this.state.cookingProcesses &&
                                                !this.state.showChildCookingProcesses &&
                                                this.state.cookingProcesses.map((cookingProcess, index) => {
                                                    if (
                                                        cookingProcess.cookingProcessId === this.state.parentId ||
                                                        !cookingProcess.parentId
                                                    ) {
                                                        return (
                                                            <SwiperSlide key={index}>
                                                                <AllCookingProcessesOverviewForm
                                                                    key={cookingProcess.cookingProcessId}
                                                                    data={cookingProcess}
                                                                    finishCookingProcess={this.finishCookingProcess}
                                                                    isCookingProcessCustomer={true}
                                                                    customersUid={this.state.customersUid}
                                                                />
                                                            </SwiperSlide>
                                                        );
                                                    } else return <React.Fragment />;
                                                })}
                                        </Swiper>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                width: "100%",
                                            }}
                                        >
                                            <div
                                                className="my-custom-pagination-div"
                                                style={{ position: "absolute", bottom: "30px" }}
                                            />
                                        </div>
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </div>
                <Popup
                    visible={cookingProcess.cookingProcessesCalendarVisible}
                    onHiding={this.toggleCalendarVisible}
                    closeOnOutsideClick={true}
                    width="auto"
                    height="auto"
                    maxWidth={300}
                    minWidth={200}
                    showCloseButton={true}
                    title={t("Tag auswählen")}
                    className="cookingProcessesCalendarPopup"
                >
                    <div id="iconPopups">
                        <Calendar
                            id="calendar-container"
                            firstDayOfWeek={1}
                            customerLanguage={this.props.cookingProcess.customerLanguage}
                            onValueChanged={this.onCalendarClick}
                            value={this.props.cookingProcess.cookingProcessesCalendarDate}
                            disabledDates={(cell) => this.cellDisabled(cell, this.state.requestedDeliveryTimes)}
                            cellRender={(cell) => this.cellTemplate(cell, this.state.requestedDeliveryTimes)}
                        />
                    </div>
                </Popup>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { cookingProcess } = state;
    return {
        cookingProcess,
    };
}

export default compose(
    connect(mapStateToProps),
    withTranslation(["dynamicTranslation"])
)(AllCookingProcessesOverviewCustomer);
