import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PrintIcon from "@material-ui/icons/Print";
import DoneIcon from "@material-ui/icons/Done";
import ReplayIcon from "@material-ui/icons/Replay";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.compact.css";
import notify from "devextreme/ui/notify";
import React, { Component } from "react";
import { history } from "../../../helpers/history";
import { connect } from "react-redux";
import { Button, Popup, LoadPanel, LoadIndicator } from "devextreme-react";
import {
    fetchCookingProcessById,
    fetchCookingProcessCustomerById,
    finishCookingProcess,
    finishCookingProcessCustomer,
    restartCookingProcess,
    restartCookingProcessCustomer,
    restartCurrentPhase,
    restartCurrentPhaseCustomer,
    updateCookingProcessArticleSizes,
    updateCookingProcessArticleWeights,
} from "../../../actions/cookingProcessActions";
import { createCookingProcessRecipe, createCookingIngredientsRecipe } from "../../../actions/recipeAction";
import { getToast } from "../../../helpers/requestHelpers";
import store from "../../../store";
import CreateCookingProcessPopup from "../dishes/createCookingProcessPopup";
import "./allCookingProcessOverview.scss";
import CookingProcessStep from "./cookingProcessStep";
import Timer from "./timer/timer";
import CookingProcessCompact from "./cookingProcessCompact/cookingProcessCompact";
import { createPdf } from "../../../helpers/pdf";
import { ReactComponent as DoubleArrow } from "./../../../icons/double_arrow-white-24dp.svg";
import { formatGrammage, getGrammageUnit } from "../../../helpers/units";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

class AllCookingProcessesOverviewForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedArticle: undefined,
            sortedSteps: [],
            showDeletePopup: false,
            showFinishPopup: false,
            togglePrintPopup: false,
            toggleRestartPopup: false,
            isLoading: false,
            cookingProcess: undefined,
        };

        this.restartCurrentPhase = this.restartCurrentPhase.bind(this);
        this.restartCookingProcess = this.restartCookingProcess.bind(this);
        this.onChangeArticleSizesClick = this.onChangeArticleSizesClick.bind(this);
        this.printRecipe = this.printRecipe.bind(this);
        this.printIngredientsList = this.printIngredientsList.bind(this);
        this.onDeleteCookingProcessClick = this.onDeleteCookingProcessClick.bind(this);
        this.onFinishCookingProcessClick = this.onFinishCookingProcessClick.bind(this);
        this.onFinishChildCookingProcessClick = this.onFinishChildCookingProcessClick.bind(this);
        this.handleTogglePopup = this.handleTogglePopup.bind(this);
        this.handleSaveArticleSizes = this.handleSaveArticleSizes.bind(this);
        this.handleSaveArticleWeights = this.handleSaveArticleWeights.bind(this);
        this.sortSteps = this.sortSteps.bind(this);
        this.toggleDeletePopup = this.toggleDeletePopup.bind(this);
        this.toggleFinishPopup = this.toggleFinishPopup.bind(this);
        this.togglePrintPopup = this.togglePrintPopup.bind(this);
        this.toggleRestartPopup = this.toggleRestartPopup.bind(this);
        this.toggleLoadPanel = this.toggleLoadPanel.bind(this);
        this.getWeight = this.getWeight.bind(this);
        this.goToParentCookingProcess = this.goToParentCookingProcess.bind(this);
        this.handleUpdateCookingProcesses = this.handleUpdateCookingProcesses.bind(this);
        this.loadCookingProcess = this.loadCookingProcess.bind(this);
    }

    componentDidMount() {
        this.loadCookingProcess();
    }

    loadCookingProcess() {
        if (this.props.isCookingProcessCustomer) {
            store
                .dispatch(fetchCookingProcessCustomerById(this.props.customersUid, this.props.data.cookingProcessId))
                .then((result) => {
                    this.setState(
                        {
                            cookingProcess: result,
                        },
                        () => {
                            this.sortSteps(result);
                        }
                    );
                });
        } else {
            store.dispatch(fetchCookingProcessById(this.props.data.cookingProcessId)).then((result) => {
                this.setState(
                    {
                        cookingProcess: result,
                    },
                    () => {
                        this.sortSteps(result);
                    }
                );
            });
        }
    }

    handleUpdateCookingProcesses() {
        this.loadCookingProcess();
    }

    sortSteps(cookingProcess) {
        let data = cookingProcess ? cookingProcess : this.state.cookingProcess;
        let sortedSteps = data.CurrentPhase.Steps.sort(function (a, b) {
            return a.sequenceNumber - b.sequenceNumber;
        });

        this.setState({
            sortedSteps: sortedSteps,
        });
    }

    sortStepsByArray(steps) {
        let sortedSteps = steps.sort(function (a, b) {
            return a.sequenceNumber - b.sequenceNumber;
        });

        return sortedSteps;
    }

    toggleDeletePopup() {
        this.setState({
            showDeletePopup: !this.state.showDeletePopup,
        });
    }

    toggleFinishPopup() {
        this.setState({
            showFinishPopup: !this.state.showFinishPopup,
        });
    }

    togglePrintPopup() {
        this.setState({
            showPrintPopup: !this.state.showPrintPopup,
        });
    }

    toggleRestartPopup() {
        this.setState({
            showRestartPopup: !this.state.showRestartPopup,
        });
    }

    onDeleteCookingProcessClick() {
        this.props.deleteCookingProcess(this.props.data.cookingProcessId);
        this.toggleDeletePopup();
    }

    onFinishChildCookingProcessClick(cookingProcessId) {
        const { t } = this.props;
        this.setState({
            isLoading: true,
        });
        if (this.props.isCookingProcessCustomer) {
            store
                .dispatch(finishCookingProcessCustomer(cookingProcessId, this.props.customersUid))
                .then((response) => {
                    notify(getToast(t("CookingProcess.FinishSuccess"), "success"));
                    store
                        .dispatch(
                            fetchCookingProcessCustomerById(this.props.customersUid, this.props.data.cookingProcessId)
                        )
                        .then((response) => this.setState({ cookingProcess: response, isLoading: false }));
                })
                .catch((err) => {
                    this.toggleLoadPanel();
                    notify(getToast(t("CookingProcess.FinishError"), "error"));
                });
        } else {
            store
                .dispatch(finishCookingProcess(cookingProcessId))
                .then((response) => {
                    notify(getToast(t("CookingProcess.FinishSuccess"), "success"));
                    store
                        .dispatch(fetchCookingProcessById(this.props.data.cookingProcessId))
                        .then((response) => this.setState({ cookingProcess: response, isLoading: false }));
                })
                .catch((err) => {
                    this.toggleLoadPanel();
                    notify(getToast(t("CookingProcess.FinishError"), "error"));
                });
        }
    }

    onFinishCookingProcessClick() {
        this.toggleFinishPopup();
        this.props.finishCookingProcess(this.props.data.cookingProcessId, this.props.data.cookingProcessesCalendarDate);
    }

    restartCurrentPhase() {
        const { t } = this.props;
        this.toggleLoadPanel();
        if (this.props.customersUid) {
            store
                .dispatch(restartCurrentPhaseCustomer(this.props.data.cookingProcessId, this.props.customersUid))
                .then((response) => {
                    this.toggleLoadPanel();
                    this.setState({ cookingProcess: response });
                    this.toggleRestartPopup();
                })
                .catch((err) => {
                    this.toggleLoadPanel();
                    notify(getToast(t("CookingProcess.ErrorRestartingPhase"), "error"));
                });
        } else {
            store
                .dispatch(restartCurrentPhase(this.props.data.cookingProcessId))
                .then((response) => {
                    this.toggleLoadPanel();
                    this.setState({ cookingProcess: response });
                    this.toggleRestartPopup();
                })
                .catch((err) => {
                    this.toggleLoadPanel();
                    notify(getToast(t("CookingProcess.ErrorRestartingPhase"), "error"));
                });
        }
    }

    restartCookingProcess() {
        const { t } = this.props;
        this.toggleLoadPanel();
        if (this.props.customersUid) {
            store
                .dispatch(restartCookingProcessCustomer(this.props.data.cookingProcessId, this.props.customersUid))
                .then((response) => {
                    this.toggleLoadPanel();
                    this.setState({ cookingProcess: response });
                    this.sortSteps(response);
                    this.toggleRestartPopup();
                })
                .catch((err) => {
                    this.toggleLoadPanel();
                    notify(getToast(t("CookingProcess.ErrorRestarting"), "error"));
                });
        } else {
            store
                .dispatch(restartCookingProcess(this.props.data.cookingProcessId))
                .then((response) => {
                    this.toggleLoadPanel();
                    this.setState({ cookingProcess: response });
                    this.sortSteps(response);
                    this.toggleRestartPopup();
                })
                .catch((err) => {
                    this.toggleLoadPanel();
                    notify(getToast(t("CookingProcess.ErrorRestarting"), "error"));
                });
        }
    }

    onChangeArticleSizesClick() {
        this.setState({
            selectedArticle: this.state.cookingProcess.Article,
        });
    }

    getWeight() {
        if (this.state.cookingProcess.weight === 0) return "";
        else {
            return (
                formatGrammage(this.state.cookingProcess.weight) +
                " " +
                getGrammageUnit(this.state.cookingProcess.weight)
            );
        }
    }

    handleTogglePopup() {
        this.setState({
            selectedArticle: undefined,
        });
    }

    handleSaveArticleSizes(amountForSizes, existingWeights) {
        const { t } = this.props;
        this.setState({
            isLoading: true,
        });

        store
            .dispatch(
                updateCookingProcessArticleSizes(
                    this.state.cookingProcess.cookingProcessId,
                    amountForSizes,
                    existingWeights
                )
            )
            .then((response) => {
                this.setState({
                    isLoading: false,
                });
                if (response.reload === true) {
                    store.dispatch(fetchCookingProcessById(this.props.data.cookingProcessId)).then((result) => {
                        this.setState({
                            cookingProcess: result,
                        });
                    });
                }
                notify(getToast(t("CookingProcess.AmountChangeSuccessfull"), "success"));
            })
            .catch((err) => {
                this.setState({
                    isLoading: false,
                });
                notify(getToast(t("CookingProcess.ErrorChangingAmounts"), "error"));
            });
    }

    handleSaveArticleWeights(articleWeights, existingWeights) {
        const { t } = this.props;
        this.setState({
            isLoading: true,
        });

        store
            .dispatch(
                updateCookingProcessArticleWeights(
                    this.state.cookingProcess.cookingProcessId,
                    articleWeights,
                    existingWeights
                )
            )
            .then((response) => {
                this.setState({
                    isLoading: false,
                });
                if (response.reload === true) {
                    store.dispatch(fetchCookingProcessById(this.props.data.cookingProcessId)).then((result) => {
                        this.setState({
                            cookingProcess: result,
                        });
                    });
                }
                notify(getToast(t("CookingProcess.AmountChangeSuccessfull"), "success"));
            })
            .catch((err) => {
                this.setState({
                    isLoading: false,
                });
                notify(getToast(t("CookingProcess.ErrorChangingAmounts"), "error"));
            });
    }

    printRecipe(recursive) {
        const { t } = this.props;
        this.toggleLoadPanel();
        notify(getToast(t("CookingProcess.PDFwait"), "success"));

        store
            .dispatch(createCookingProcessRecipe(this.state.cookingProcess.cookingProcessId, recursive))
            .then((response) => {
                this.toggleLoadPanel();
                if (!response.ok) {
                    notify(getToast(t("CookingProcess.PrintError"), "error"));
                    return;
                }

                createPdf(response, "Rezept_" + this.state.cookingProcess.Article.name);
            })
            .catch((err) => {
                this.toggleLoadPanel();
                notify(getToast(t("CookingProcess.PrintError"), "error"));
            });

        this.togglePrintPopup();
    }

    goToParentCookingProcess() {
        if (this.props.isCookingProcessCustomer) {
            history.push(
                "/guest/allCookingProcessesCustomer/" +
                    this.props.customersUid +
                    "/p/" +
                    this.state.cookingProcess.cookingProcessId
            );
        } else {
            history.push("/kueche/kochprozesse/p/" + this.state.cookingProcess.cookingProcessId);
        }
    }

    printIngredientsList() {
        const { t } = this.props;
        this.toggleLoadPanel();
        notify(getToast(t("CookingProcess.PDFWait"), "success"));

        store
            .dispatch(createCookingIngredientsRecipe(this.state.cookingProcess.cookingProcessId))
            .then((response) => {
                this.toggleLoadPanel();
                if (!response.ok) {
                    notify(getToast(t("CookingProcess.missingPrintRight"), "error"));
                    return;
                }

                createPdf(response, t("CookingProcess.IngredientList") + "_" + this.state.cookingProcess.Article.name);
            })
            .catch((err) => {
                this.toggleLoadPanel();
                notify(getToast(t("CookingProcess.PrintError"), "error"));
            });

        this.togglePrintPopup();
    }

    shadeColor(color) {
        try {
            var R = parseInt(color.substring(1, 3), 16);
            var G = parseInt(color.substring(3, 5), 16);
            var B = parseInt(color.substring(5, 7), 16);

            var hsp = Math.sqrt(0.299 * (R * R) + 0.587 * (G * G) + 0.114 * (B * B));
            if (hsp > 127.5) {
                return "#000000";
            } else {
                return "#ffffff";
            }
        } catch (err) {
            return "#000000";
        }
    }

    toggleLoadPanel() {
        this.setState({
            isLoading: !this.state.isLoading,
        });
    }

    render() {
        const { t } = this.props;
        return (
            <div className="cookingProcessForm">
                <LoadPanel visible={this.state.isLoading} />
                <div
                    id="tiles"
                    style={{
                        borderLeft:
                            !this.state.cookingProcess ||
                            !this.state.cookingProcess.CookingProcesses ||
                            this.state.cookingProcess.CookingProcesses.length > 0
                                ? "none"
                                : "10px solid " + this.state.cookingProcess.CurrentPhase.PhaseType.color,
                    }}
                >
                    <div>
                        <header className="cookingProcessHeader">
                            {this.state.cookingProcess ? this.getWeight() : ""} {t(this.props.data.name)}
                            {this.state.cookingProcess && this.props.data.parentId && (
                                <div
                                    style={{
                                        fontSize: 14,
                                    }}
                                >
                                    (für {t(this.state.cookingProcess.parentCookingProcessName)})
                                </div>
                            )}
                        </header>
                        {this.state.cookingProcess && (
                            <div className="cookingProcessButtons">
                                {this.state.cookingProcess.cookingProcessStarttime && (
                                    <div
                                        className="cookingProcessButton"
                                        onClick={this.toggleRestartPopup}
                                        title={t("Kochprozess neustarten")}
                                    >
                                        <ReplayIcon fontSize="small" className="buttonIcon" />
                                    </div>
                                )}
                                {!this.props.data.parentId &&
                                    !this.props.isCookingProcessCustomer &&
                                    !this.state.cookingProcess.cookingProcessStarttime && (
                                        <div
                                            className="cookingProcessButton"
                                            onClick={this.onChangeArticleSizesClick}
                                            title={t("Mengen anpassen")}
                                        >
                                            <EditIcon fontSize="small" className="buttonIcon" />
                                        </div>
                                    )}

                                <div
                                    className="cookingProcessButton"
                                    onClick={this.togglePrintPopup}
                                    title={t("Drucken")}
                                >
                                    <PrintIcon fontSize="small" className="buttonIcon" />
                                </div>
                                {this.state.cookingProcess && !this.state.cookingProcess.cookingProcessStarttime && (
                                    <div
                                        className="cookingProcessButton"
                                        onClick={this.toggleFinishPopup}
                                        title={t("Kochprozess abschließen")}
                                    >
                                        <DoneIcon fontSize="small" className="buttonIcon" />
                                    </div>
                                )}
                                {!this.props.data.parentId && !this.props.isCookingProcessCustomer && (
                                    <div
                                        className="cookingProcessButton"
                                        onClick={this.toggleDeletePopup}
                                        title={t("Kochprozess löschen")}
                                    >
                                        <DeleteIcon fontSize="small" className="buttonIcon" />
                                    </div>
                                )}
                                {this.state.cookingProcess &&
                                    this.state.cookingProcess.CookingProcesses &&
                                    this.state.cookingProcess.CookingProcesses.length > 1 && (
                                        <div
                                            className="cookingProcessButton"
                                            onClick={this.goToParentCookingProcess}
                                            title={t("Untergeordnete Kochprozesse anzeigen")}
                                        >
                                            <DoubleArrow className="buttonIcon doubleArrow" />
                                        </div>
                                    )}
                            </div>
                        )}
                        {!this.state.cookingProcess && (
                            <div style={{ justifyContent: "center", display: "flex" }}>
                                <LoadIndicator id="medium-indicator" height={40} width={40} />
                            </div>
                        )}
                        {this.state.cookingProcess &&
                            this.state.cookingProcess.CookingProcesses &&
                            this.state.cookingProcess.CookingProcesses.length > 0 && (
                                <div className="content">
                                    {this.state.cookingProcess.CookingProcesses.map((childCookingProcess, index) => {
                                        return (
                                            <div key={index}>
                                                <CookingProcessCompact
                                                    cookingProcessId={childCookingProcess.cookingProcessId}
                                                    isCookingProcessCustomer={this.props.isCookingProcessCustomer}
                                                    customersUid={this.props.customersUid}
                                                    showButtons={this.props.data.cookingProcessId !== childCookingProcess.cookingProcessId}
                                                    weight={childCookingProcess.weight}
                                                    onFinishChildCookingProcessClick={
                                                        this.onFinishChildCookingProcessClick
                                                    }
                                                    toggleLoadPanel={this.toggleLoadPanel}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        {this.state.cookingProcess &&
                            (!this.state.cookingProcess.CookingProcesses ||
                                this.state.cookingProcess.CookingProcesses.length === 0) && (
                                <div className="content">
                                    {this.state.cookingProcess.CurrentPhase &&
                                        this.state.cookingProcess.CurrentPhase.PhaseType && (
                                            <h3>{t(this.state.cookingProcess.CurrentPhase.PhaseType.name)}</h3>
                                        )}
                                    {this.state.cookingProcess.currentTimerStart &&
                                        false &&
                                        this.state.cookingProcess.CurrentStep.stepTimeSec !== null && (
                                            <div id="pies-container">
                                                <Timer
                                                    duration={this.state.cookingProcess.CurrentStep.stepTimeSec}
                                                    currentTimerStart={this.state.cookingProcess.timerStartTimeStamp}
                                                />
                                            </div>
                                        )}
                                    {this.state.sortedSteps.map((step) => {
                                        return (
                                            <CookingProcessStep
                                                step={step}
                                                key={step.sequenceNumber}
                                                customersUid={this.props.customersUid}
                                                isCookingProcessCustomer={this.props.isCookingProcessCustomer}
                                                disabledMessage={this.state.cookingProcess.disabledMessage}
                                                cookingProcessId={parseInt(this.props.data.cookingProcessId)}
                                                isDone={
                                                    step.sequenceNumber <
                                                    this.state.cookingProcess.CurrentStep.sequenceNumber
                                                }
                                                isCurrentStep={
                                                    step.sequenceNumber ===
                                                    this.state.cookingProcess.CurrentStep.sequenceNumber
                                                }
                                                showButtons={true}
                                                currentTimerStart={this.state.cookingProcess.timerStartTimeStamp}
                                                toggleLoadPanel={this.toggleLoadPanel}
                                                stepNumber={this.state.sortedSteps.indexOf(step) + 1}
                                                phase={this.state.cookingProcess.CurrentPhase}
                                            />
                                        );
                                    })}
                                </div>
                            )}
                        {this.state.cookingProcess && !this.props.isCookingProcessCustomer && (
                            <div style={{ float: "right" }}>
                                {this.state.cookingProcess.createdByEmployee},{" "}
                                {new Date(this.state.cookingProcess.createdDateTime).toLocaleString()}
                            </div>
                        )}
                    </div>
                </div>
                {this.state.cookingProcess && (
                    <CreateCookingProcessPopup
                        visible={this.state.selectedArticle}
                        togglePopup={this.handleTogglePopup}
                        article={this.state.selectedArticle}
                        handleSaveArticleSizes={this.handleSaveArticleSizes}
                        handleSaveArticleWeights={this.handleSaveArticleWeights}
                        cookingProcess={this.state.cookingProcess}
                    />
                )}
                {this.state.cookingProcess && (
                    <Popup
                        visible={this.state.showDeletePopup}
                        onHiding={this.toggleDeletePopup}
                        closeOnOutsideClick={true}
                        width="auto"
                        height="auto"
                        maxWidth={400}
                        minWidth={300}
                        showCloseButton={true}
                        title={t("CookingProcess.delete")}
                    >
                        <div id="iconPopups">
                            <p>{t("CookingProcess.DeleteDialog") + "?"}</p>
                            <div className="popupButtons">
                                <Button text={t("Nein")} type="normal" onClick={this.toggleDeletePopup} />
                                <Button text={t("ja")} type="default" onClick={this.onDeleteCookingProcessClick} />
                            </div>
                        </div>
                    </Popup>
                )}
                {this.state.cookingProcess && (
                    <Popup
                        visible={this.state.showFinishPopup}
                        onHiding={this.toggleFinishPopup}
                        closeOnOutsideClick={true}
                        width="auto"
                        height="auto"
                        maxWidth={400}
                        minWidth={300}
                        showCloseButton={true}
                        title={t("CookingProcess.finish")}
                    >
                        <div id="iconPopups">
                            <p>
                                {t("CookingProcess.AreYouSure.first")} {this.getWeight()}{" "}
                                {this.state.cookingProcess.Article.name} {t("CookingProcess.AreYouSure.second")}
                            </p>
                            <div className="popupButtons">
                                <Button text={t("CookingProcess.No")} type="normal" onClick={this.toggleFinishPopup} />
                                <Button
                                    text={t("CookingProcess.Yes")}
                                    type="default"
                                    onClick={this.onFinishCookingProcessClick}
                                />
                            </div>
                        </div>
                    </Popup>
                )}
                {this.state.cookingProcess && (
                    <Popup
                        visible={this.state.showPrintPopup}
                        onHiding={this.togglePrintPopup}
                        closeOnOutsideClick={true}
                        width="auto"
                        height="auto"
                        maxWidth={700}
                        minWidth={300}
                        showCloseButton={true}
                        title={t("CookingProcess.Print")}
                    >
                        <div id="iconPopups">
                            <p>{t("CookingProcess.whatToPrint")}</p>
                            <div className="popupButtons">
                                {!this.props.isCookingProcessCustomer && (
                                    <Button
                                        style={{ marginRight: "10px" }}
                                        text={t("CookingProcess.Recipe")}
                                        type="default"
                                        onClick={() => this.printRecipe(false)}
                                    />
                                )}
                                {!this.props.isCookingProcessCustomer && (
                                    <Button
                                        style={{ marginRight: "10px" }}
                                        text={t("Rezept (mit Vorprodukten)")}
                                        type="default"
                                        onClick={() => this.printRecipe(true)}
                                    />
                                )}
                                <Button
                                    text={t("CookingProcess.IngredientList")}
                                    type="default"
                                    onClick={this.printIngredientsList}
                                />
                            </div>
                        </div>
                    </Popup>
                )}
                {this.state.cookingProcess && (
                    <Popup
                        visible={this.state.showRestartPopup}
                        onHiding={this.toggleRestartPopup}
                        closeOnOutsideClick={true}
                        width="auto"
                        height="auto"
                        maxWidth={900}
                        minWidth={300}
                        showCloseButton={true}
                        title={t("Neustarten")}
                    >
                        <div id="iconPopups">
                            <p>{t("CookingProcess.WhatToRestart")}</p>
                            <div className="popupButtons">
                                <Button
                                    text={t("Kochprozess")}
                                    type="default"
                                    onClick={this.restartCookingProcess}
                                    style={{ marginRight: 10 }}
                                />
                                {!this.state.cookingProcess.isFirstPhase && !this.state.cookingProcess.isFirstStep && <Button
                                    text={t("CookingProcess.CurrentPhase")}
                                    type="default"
                                    onClick={this.restartCurrentPhase}
                                />}
                            </div>
                        </div>
                    </Popup>
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { identity } = state;
    return {
        identity,
    };
}

export default compose(
    connect(mapStateToProps),
    withTranslation(["dynamicTranslation"])
)(AllCookingProcessesOverviewForm);
