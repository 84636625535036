import { LoadIndicator } from "devextreme-react";
import React, { Component } from "react";
import { getThemeForUrl } from "../../../actions/themeActions";
import "../../../css/dx.generic.dynamic.css";
import "../../../scss/theme.scss";
import store from "../../../store";
import "./themeSelector.scss";

export default class ThemeSelector extends Component {
    constructor() {
        super();
        this.state = {
            accentColor: "",
            backgroundColor: "",
            textColor: "",
            warningColor: "",
            errorColor: "",
            successColor: "",
            logoURL: "",
            loading: true,
        };
    }

    componentDidMount() {
        store.dispatch(getThemeForUrl(window.location.origin)).then(() => this.setState({ loading: false }));
    }

    render() {
        const { loading } = this.state;

        return (
            <React.Fragment>
                <div id="themeLoading" className={loading ? "" : "hidden"}>
                    <LoadIndicator width={80} height={80} />
                </div>
                {this.props.children}
            </React.Fragment>
        );
    }
}
