import React, { Component } from "react";
import { DataGrid } from "devextreme-react";
import { Sorting, Editing, Column } from "devextreme-react/data-grid";
import { withTranslation } from "react-i18next";

class IngredientsDatagrid extends Component {
    render() {
        var ingredients = [];
        const { t } = this.props;
        // eslint-disable-next-line
        this.props.parentCookingProcess.ingredientsWithoutCookingProcesses.map((ingredient) => {
            var weight = 0;
            if (this.props.switchedOn) {
                // eslint-disable-next-line
                this.props.articleSizesWithoutDefaultSize.map((articleSize) => {
                    weight += articleSize.cookingProcessAmountHelper
                        ? (ingredient.weight / 100) * articleSize.cookingProcessAmountHelper * articleSize.portionSize
                        : 0;
                });
            } else {
                weight += (ingredient.weight / 100) * this.props.parentCookingProcess.existingWeight;
            }
            ingredients.push({
                name: ingredient.name,
                weight: weight,
                articlesId: ingredient.articlesId,
            });
        });
        // eslint-disable-next-line
        this.props.childCookingProcesses.map((childCookingProcess) => {
            // eslint-disable-next-line
            childCookingProcess.ingredientsWithoutCookingProcesses.map((ingredient) => {
                var childCookingProcessWeight = 0;
                if (this.props.switchedOn) {
                    // eslint-disable-next-line
                    this.props.articleSizesWithoutDefaultSize.map((articleSize) => {
                        childCookingProcessWeight += articleSize.cookingProcessAmountHelper
                            ? (childCookingProcess.weight / 100) *
                              articleSize.cookingProcessAmountHelper *
                              articleSize.portionSize
                            : 0;
                    });
                } else {
                    childCookingProcessWeight +=
                        (childCookingProcess.weight / 100) * this.props.parentCookingProcess.existingWeight;
                }

                var weight =
                    (ingredient.weight * Math.max(0, childCookingProcessWeight - childCookingProcess.existingWeight)) /
                    100;

                if (ingredients.find((i) => i.articlesId === ingredient.articlesId)) {
                    ingredients.find((i) => i.articlesId === ingredient.articlesId).weight += weight;
                } else {
                    ingredients.push({
                        name: ingredient.name,
                        weight: weight,
                        articlesId: ingredient.articlesId,
                    });
                }
            });
        });

        return (
            <div>
                <DataGrid
                    id={"gridContainer"}
                    dataSource={ingredients}
                    columnAutoWidth={true}
                    allowColumnReordering={false}
                    rowAlternationEnabled={true}
                    wordWrapEnabled={true}
                >
                    <Sorting mode="none" />
                    <Editing mode={"cell"} allowUpdating={true} selectTextOnEditStart={true} />
                    <Column
                        caption={t("Rohstoffe")}
                        dataField={"name"}
                        calculateDisplayValue={(e) => t(e.name)}
                        allowEditing={false}
                    />
                    <Column
                        caption={t("benötigt (g)")}
                        width={200}
                        dataField={"weight"}
                        allowEditing={false}
                        calculateDisplayValue={(e) => {
                            return Math.round(e.weight);
                        }}
                    />
                </DataGrid>
            </div>
        );
    }
}

export default withTranslation(["dynamicTranslation"])(IngredientsDatagrid);
