import { Button, TextBox, ValidationGroup, Validator } from "devextreme-react";
import { RequiredRule } from "devextreme-react/validator";
import notify from "devextreme/ui/notify";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { updateLicense, licenseIsValid } from "../../../../actions/licenseActions";
import { history } from "../../../../helpers/history";
import { getToast } from "../../../../helpers/requestHelpers";
import store from "../../../../store";
import "./licenseForm.scss";

class LicenseForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            key: "",
            forceUpdate: this.props.forceUpdate,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.redirectLoggedInUser = this.redirectLoggedInUser.bind(this);

        this.handleKeyChange = (e) => {
            this.setState({
                key: e.value,
            });
        };
    }

    componentDidMount() {
        if (!this.state.forceUpdate) this.redirectLoggedInUser();
    }

    componentDidUpdate() {
        if (!this.state.forceUpdate) this.redirectLoggedInUser();
    }

    redirectLoggedInUser() {
        if (this.props.identity.user.Token) {
            history.push("/");
        }
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        const { t } = this.props;
        e.preventDefault();

        const { key } = this.state;
        if (key) {
            store.dispatch(updateLicense(key)).then((response) => {
                store.dispatch(licenseIsValid()).then((res) => {
                    if (!this.state.forceUpdate) {
                        history.push("/");
                    } else {
                        notify(getToast(t("Login.LicenseUpdatedSuccessfully"), "success"));
                    }
                });
            });
        }
    }
    render() {
        const { t } = this.props;
        const { loginTranslations } = this.props.settings;
        return (
            <React.Fragment>
                <form onSubmit={this.handleSubmit}>
                    <ValidationGroup>
                        <div className={"dx-field"}>
                            <TextBox
                                name="key"
                                placeholder={loginTranslations ? loginTranslations.licenseKey : "Lizenzschlüssel"}
                                required
                                onValueChanged={this.handleKeyChange}
                            >
                                <Validator>
                                    <RequiredRule
                                        message={
                                            loginTranslations
                                                ? loginTranslations.fieldIsRequired
                                                : "Dieses Feld wird benötigt"
                                        }
                                    />
                                </Validator>
                            </TextBox>
                        </div>
                        <div className={"dx-field"}>
                            <div className={"dx-field-value"}>
                                <Button text={t("Login.Check")} type="default" useSubmitBehavior={true} />
                            </div>
                        </div>
                    </ValidationGroup>
                </form>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { identity, settings } = state;
    return {
        identity,
        settings,
    };
}

export default compose(connect(mapStateToProps), withTranslation(["dynamicTranslation"]))(LicenseForm);
