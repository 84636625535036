import React from "react";
import Config from "../../../../Config";

export default function Image(props) {
    var config = new Config();
    return (
        <React.Fragment>
            <div className={props.step.guidFileName === null ? "box empty image" : "box image"}>
                {props.step.guidFileName !== null && (
                    <div
                        className="content"
                        style={{
                            backgroundImage:
                                "url(" + config.imageStorageBlobURL + encodeURI(props.step.guidFileName) + ")",
                        }}
                    ></div>
                )}
                {props.step.guidFileName === null && (
                    <div className="content">
                        <i className="dx-icon-photo"></i>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
}
