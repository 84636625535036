import { Drawer } from "devextreme-react";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import "./content.scss";
import Navigation from "./navigation/navigation";
import Page from "./page/page";

class Content extends Component {
    constructor(props) {
        super(props);

        this.state = {
            navigationOpen: false,
        };

        this.toggleNavigation = this.toggleNavigation.bind(this);
    }

    static propTypes = {
        location: PropTypes.object.isRequired,
    };

    toggleNavigation() {
        this.setState({ navigationOpen: !this.state.navigationOpen });
    }

    render() {
        return (
            <React.Fragment>
                {!this.props.noNav && (
                    <Drawer
                        opened={this.props.identity.user.Username ? this.state.navigationOpen : false}
                        openedStateMode="shrink"
                        revealMode="expand"
                        position="left"
                        component={() => (
                            <Navigation toggleNavigation={this.toggleNavigation} open={this.state.navigationOpen} />
                        )}
                        closeOnOutsideClick={true}
                        minSize={this.props.identity.user.Username ? 55 : 0}
                        maxSize={350}
                    >
                        <div id="main" className={this.props.location.pathname === "/" ? "home" : ""}>
                            <div id="content" className="content">
                                <Page />
                            </div>
                        </div>
                    </Drawer>
                )}

                {this.props.noNav && (
                    <div id="main" className="guest">
                        <div id="content" className="content">
                            <Page />
                        </div>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

Content.defaultProps = {
    noNav: false,
};

function mapStateToProps(state) {
    const { navigation, identity } = state;
    return {
        navigation,
        identity,
    };
}

export default connect(mapStateToProps)(withRouter(Content));
