import React, { Component } from "react";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.compact.css";
import PieChart, { Legend, Series, Animation, CommonSeriesSettings } from "devextreme-react/pie-chart";
import "../allCookingProcessOverview.scss";

class Timer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            duration: this.props.duration * 1000,
            time: new Date(this.props.currentTimerStart).getTime() + this.props.duration * 1000 - new Date().getTime(),
            minutes:
                new Date(this.props.currentTimerStart).getTime() + this.props.duration * 1000 > new Date().getTime()
                    ? Math.floor(
                          (new Date(this.props.currentTimerStart).getTime() +
                              this.props.duration * 1000 -
                              new Date().getTime()) /
                              60 /
                              1000
                      )
                    : 0,
            seconds:
                new Date(this.props.currentTimerStart).getTime() + this.props.duration * 1000 > new Date().getTime()
                    ? Math.floor(
                          (new Date(this.props.currentTimerStart).getTime() +
                              this.props.duration * 1000 -
                              new Date().getTime()) /
                              1000
                      ) % 60
                    : 0,
            progress: [
                {
                    timer: "timer",
                    value:
                        new Date().getTime() <
                        new Date(this.props.currentTimerStart).getTime() + this.props.duration * 1000
                            ? new Date().getTime() - new Date(this.props.currentTimerStart).getTime()
                            : this.props.duration * 1000,
                },
                {
                    timer: "timer",
                    value:
                        new Date().getTime() <
                        new Date(this.props.currentTimerStart).getTime() + this.props.duration * 1000
                            ? new Date(this.props.currentTimerStart).getTime() +
                              this.props.duration * 1000 -
                              new Date().getTime()
                            : 0,
                },
            ],
            colorPalette:
                Math.floor(
                    (new Date(this.props.currentTimerStart).getTime() +
                        this.props.duration * 1000 -
                        new Date().getTime()) /
                        60 /
                        1000
                ) < 2
                    ? ["#ff0000", "#ffb3b3"]
                    : ["#008000", "#b3ffb3"],
            TimerAlreadyFinished:
                new Date(this.props.currentTimerStart).getTime() + this.props.duration * 1000 < new Date().getTime()
                    ? true
                    : false,
        };

        this.customizeLabel = this.customizeLabel.bind(this);
        this.TooltipTemplate = this.TooltipTemplate.bind(this);
        this.beep = this.beep.bind(this);
        this.beepOnMinute = this.beepOnMinute.bind(this);
        this.ac = new AudioContext();
    }

    componentDidMount() {
        this.myInterval = setInterval(() => {
            const { seconds, minutes } = this.state;
            if (seconds > 0) {
                if (minutes <= 1) {
                    this.setState({
                        seconds: seconds - 1,
                        progress: [
                            { timer: "timer", value: this.state.progress[0].value + 1000 },
                            { timer: "timer", value: this.state.progress[1].value - 1000 },
                        ],
                        colorPalette: ["#ff0000", "#ffb3b3"],
                    });
                } else {
                    this.setState({
                        seconds: seconds - 1,
                        progress: [
                            { timer: "timer", value: this.state.progress[0].value + 1000 },
                            { timer: "timer", value: this.state.progress[1].value - 1000 },
                        ],
                    });
                }
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    //this.beepOnMinute()
                    clearInterval(this.myInterval);
                } else if (minutes === 2) {
                    this.setState({
                        minutes: minutes - 1,
                        seconds: 59,
                        progress: [
                            { timer: "timer", value: this.state.progress[0].value + 1000 },
                            { timer: "timer", value: this.state.progress[1].value - 1000 },
                        ],
                        colorPalette: ["#ff0000", "#ffb3b3"],
                    });
                } else {
                    this.setState({
                        minutes: minutes - 1,
                        seconds: 59,
                        progress: [
                            { timer: "timer", value: this.state.progress[0].value + 1000 },
                            { timer: "timer", value: this.state.progress[1].value - 1000 },
                        ],
                    });
                }
            }
        }, 1000);
    }

    beep() {
        let v = this.ac.createOscillator();
        let u = this.ac.createGain();
        v.connect(u);
        v.frequency.value = 1760;
        v.type = "square";
        u.connect(this.ac.destination);
        u.gain.value = 0.5;
        v.start(this.ac.currentTime);
        v.stop(this.ac.currentTime + 0.3);
    }

    beepOnMinute() {
        this.startTime = new Date().getTime();
        if (this.state.TimerAlreadyFinished === false) {
            this.interval = setInterval(() => {
                let currTime = new Date().getTime();
                if (currTime - this.startTime > 60000) {
                    clearInterval(this.interval);
                }
                this.beep();
            }, 1000);
        }
    }

    componentWillUnmount() {
        clearInterval(this.myInterval);
        clearInterval(this.interval);
    }

    customizeLabel(e) {
        return `${this.state.minutes}`;
    }

    TooltipTemplate(pieChart) {
        return (
            <svg>
                <text textAnchor="middle" x="30" y="60" style={{ fontSize: 15, fill: "#000000" }}>
                    <tspan x="50">
                        {this.state.minutes} : {this.state.seconds < 10 ? `0${this.state.seconds}` : this.state.seconds}
                    </tspan>
                </text>
            </svg>
        );
    }

    render() {
        return (
            <PieChart
                sizeGroup="piesGroup"
                type="doughnut"
                dataSource={this.state.progress}
                innerRadius={1}
                centerRender={this.TooltipTemplate}
                palette={this.state.colorPalette}
                startAngle={90}
            >
                <Animation enabled={false} />
                <Series argumentField="timer" valueField="value"></Series>
                <Legend visible={false} />
                <CommonSeriesSettings hoverMode="none" />
            </PieChart>
        );
    }
}

export default Timer;
