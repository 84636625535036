import Config from "../../Config";
import { authHeader, handleResponse, translateResponse } from "../../helpers/requestHelpers";

const config = new Config();

export function fetchTools(t) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Tools/get", requestOptions)
            .then((response) => handleResponse(response))
            .then((response) => translateResponse(response, t));
    };
}
export function fetchToolsForStep() {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Tools/getToolsForStep", requestOptions)
            .then((response) => handleResponse(response))
            .then((response) => dispatch(receiveTools(response)));
    };
}

export const RECEIVE_TOOLS = "RECEIVE_TOOLS";
export function receiveTools(json) {
    return {
        type: RECEIVE_TOOLS,
        tools: json,
    };
}

export function updateTools(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Tools/update", requestOptions).then((response) => handleResponse(response));
    };
}

export function insertTools(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Tools/insert", requestOptions).then((response) => handleResponse(response));
    };
}

export function deleteTools(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Tools/delete", requestOptions).then((response) => handleResponse(response));
    };
}
