import { DRAGGED_ITEM, DROPPED_ON_ITEM } from "../actions/dragAndDropAction";

const initialState = {
    draggedItem: undefined,
    droppedOnItem: undefined
}

export default (state = initialState, action) => {
    switch (action.type) {
        case DRAGGED_ITEM:
            return Object.assign({}, state, {
                draggedItem: action.item
            })
        case DROPPED_ON_ITEM:
            return Object.assign({}, state, {
                droppedOnItem: action.item
            })
        default:
            return state
    }
}