import fetch from "cross-fetch";
import Config from "../Config";
import { authHeader, handleResponse } from "../helpers/requestHelpers";

const config = new Config();

export const SET_REGISTRATION_STATUS = "SET_REGISTRATION_STATUS";
export function setRegistationStatus(json) {
    return {
        type: SET_REGISTRATION_STATUS,
        registrationStatus: json,
    };
}

export function register(user, userToRegister) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ user, userToRegister }),
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/users/register", requestOptions)
            .then((response) => {
                if (response.status === 200) {
                    return { Errors: [], Succeeded: true };
                } else {
                    return response.json();
                }
            })
            .then((json) => dispatch(setRegistationStatus(json)));
    };
}

export const SET_USER = "SET_USER";
export function setUser(json) {
    return {
        type: SET_USER,
        user: json,
    };
}

export const SET_USER_FAVORITES = "SET_USER_FAVORITES";
export function setUserFavorites(json) {
    return {
        type: SET_USER_FAVORITES,
        user: json,
    };
}

export const LOGOUT_USER = "LOGOUT_USER";
export function logoutUser() {
    return {
        type: LOGOUT_USER,
    };
}

export function login(username, password, selectedLanguage) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ username, password, selectedLanguage }),
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/users/authenticate", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => dispatch(setUser(json)));
    };
}
