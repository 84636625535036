import {
    ADD_TO_CLIPBOARD,
    REMOVE_FROM_CLIPBOARD,
    RESET_CONTENT_TO_COPY,
    SET_CONTENT_TO_COPY,
} from "../actions/menucardActions/articleDetailsPageAction";

const initialState = {
    clipboard: [],
    contentToCopy: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ADD_TO_CLIPBOARD:
            return {
                ...state,
                clipboard: [...state.clipboard, action.payload],
            };
        case REMOVE_FROM_CLIPBOARD:
            return {
                ...state,
                clipboard: state.clipboard.filter((item) => item.articleDetailsContentsId !== action.payload),
            };
        case SET_CONTENT_TO_COPY:
            return {
                ...state,
                contentToCopy: action.payload,
            };
        case RESET_CONTENT_TO_COPY:
            return {
                ...state,
                contentToCopy: null,
            };
        default:
            return state;
    }
};
