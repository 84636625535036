import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./store";

import "typeface-roboto";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import config from "devextreme/core/config";
import Config from "./Config";
import { LoadIndicator } from "devextreme-react";

config({
    defaultCurrency: "EUR",
});

const Configuration = new Config();

ReactDOM.render(
    <Suspense
        fallback={
            <div id="themeLoading">
                <LoadIndicator width={80} height={80} />
            </div>
        }
    >
        <Provider store={store}>
            <App />
        </Provider>
    </Suspense>,
    document.getElementById("root"),
    () => {
        document.getElementById("root").classList.add(Configuration.selectedTheme);
    }
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
