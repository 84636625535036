import { connection } from "../signalr/orderConnection";

export const SET_NEW_ORDERS_AVAILABLE = "SET_NEW_ORDERS_AVAILABLE";

export function setNewOrdersAvailable() {
    return {
        type: SET_NEW_ORDERS_AVAILABLE,
    };
}

export const UNSET_NEW_ORDERS_AVAILABLE = "UNSET_NEW_ORDERS_AVAILABLE";

export function unsetNewOrdersAvailable(orders) {
    return {
        type: UNSET_NEW_ORDERS_AVAILABLE,
    };
}

export function start() {
    return function () {
        if (connection.connectionState === "Disconnected") {
            return connection.start();
        } else {
            return null;
        }
    };
}

export function stop() {
    return function (dispatch) {
        dispatch(unsetNewOrdersAvailable());
        if (connection.connectionState === "Connected") {
            return connection.stop();
        } else {
            return null;
        }
    };
}
