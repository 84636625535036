import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./cookingProcessCompact.scss";
import CookingProcessCompactInfo from "./cookingProcessCompactInfo";
import DoneIcon from "@material-ui/icons/Done";
import FinishPopup from "../popups/finishPopup";
import { formatGrammage, getGrammageUnit } from "../../../../helpers/units";
import store from "../../../../store";
import { fetchCookingProcessById, fetchCookingProcessCustomerById } from "../../../../actions/cookingProcessActions";
import { LoadIndicator } from "devextreme-react";
import { withTranslation } from "react-i18next";

class CookingProcessCompact extends Component {
    constructor() {
        super();

        this.state = {
            showFinishPopup: false,
            cookingProcess: undefined,
            sortedSteps: [],
        };

        this.toggleFinishPopup = this.toggleFinishPopup.bind(this);
        this.getWeight = this.getWeight.bind(this);
        this.onFinishCookingProcessClick = this.onFinishCookingProcessClick.bind(this);
        this.sortSteps = this.sortSteps.bind(this);
        this.loadCookingProcess = this.loadCookingProcess.bind(this);
    }

    loadCookingProcess() {
        if (this.props.isCookingProcessCustomer) {
            store
                .dispatch(fetchCookingProcessCustomerById(this.props.customersUid, this.props.cookingProcessId))
                .then((result) => {
                    this.setState(
                        {
                            cookingProcess: result,
                        },
                        () => {
                            this.sortSteps(result);
                        }
                    );
                });
        } else {
            store.dispatch(fetchCookingProcessById(this.props.cookingProcessId)).then((result) => {
                this.setState(
                    {
                        cookingProcess: result,
                    },
                    () => {
                        this.sortSteps(result);
                    }
                );
            });
        }
    }

    componentDidMount() {
        this.loadCookingProcess();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.cookingProcessId !== this.props.cookingProcessId || prevProps.weight !== this.props.weight) {
            this.loadCookingProcess();
        }
    }

    sortSteps(cookingProcess) {
        let sortedSteps = cookingProcess.CurrentPhase.Steps.sort(function (a, b) {
            return a.sequenceNumber - b.sequenceNumber;
        });

        this.setState({
            sortedSteps: sortedSteps,
        });
    }

    toggleFinishPopup() {
        this.setState({
            showFinishPopup: !this.state.showFinishPopup,
        });
    }

    onFinishCookingProcessClick() {
        this.setState(
            {
                showFinishPopup: !this.state.showFinishPopup,
            },
            () => this.props.onFinishChildCookingProcessClick(this.props.cookingProcessId)
        );
    }

    getWeight() {
        if (this.state.cookingProcess.weight === 0) return "";
        else {
            return (
                formatGrammage(this.state.cookingProcess.weight) +
                " " +
                getGrammageUnit(this.state.cookingProcess.weight)
            );
        }
    }

    render() {
        const { toggleLoadPanel, t } = this.props;
        return (
            <React.Fragment>
                {!this.state.cookingProcess && (
                    <div style={{ justifyContent: "center", display: "flex" }}>
                        <LoadIndicator id="medium-indicator" height={40} width={40} />
                    </div>
                )}
                {this.state.cookingProcess && (
                    <div
                        className="cookingProcessComptact"
                        style={{
                            borderLeft: "10px solid " + this.state.cookingProcess.CurrentPhase.PhaseType.color,
                        }}
                    >
                        {/* <div
                            className="phaseType"
                            style={
                                cookingProcess.CurrentPhase.PhaseType.color
                                    ? { backgroundColor: cookingProcess.CurrentPhase.PhaseType.color }
                                    : null
                            }
                        >
                            {cookingProcess.CurrentPhase.PhaseType.name}
                        </div> */}
                        <header>
                            <h3>
                                {this.getWeight()} {t(this.state.cookingProcess.Article.name)}
                            </h3>
                            {this.props.showButtons && (
                                <div className="cookingProcessCompactHeaderButtons">
                                    {!this.state.cookingProcess.cookingProcessStarttime && (
                                        <div 
                                            className="cookingProcessButton" 
                                            onClick={this.toggleFinishPopup}
                                            title={t("Kochprozess abschließen")}
                                        >
                                            <DoneIcon fontSize="small" className="buttonIcon" />
                                        </div>
                                    )}
                                    {this.state.cookingProcess.disabledMessage !== null && this.props.customersUid && (
                                        <Link
                                            className={"blink"}
                                            to={
                                                "/guest/allCookingProcessesCustomer/" +
                                                this.props.customersUid +
                                                "/c/" +
                                                this.props.cookingProcessId
                                            }
                                            title={t("Zum Kochprozess springen")}
                                        >
                                            <ChevronRightIcon />
                                        </Link>
                                    )}
                                    {this.state.cookingProcess.disabledMessage !== null && !this.props.customersUid && (
                                        <Link
                                            className={"blink"}
                                            to={"/kueche/kochprozesse/c/" + this.props.cookingProcessId}
                                            title={t("Zum Kochprozess springen")}
                                        >
                                            <ChevronRightIcon />
                                        </Link>
                                    )}
                                </div>
                            )}
                        </header>
                        <div className="info">
                            <h3>{t(this.state.cookingProcess.CurrentPhase.PhaseType.name)}</h3>
                            <div className="compactSteps">
                                <CookingProcessCompactInfo
                                    cookingProcess={this.state.cookingProcess}
                                    sortedSteps={this.state.sortedSteps}
                                    isCookingProcessCustomer={this.props.isCookingProcessCustomer}
                                    customersUid={this.props.customersUid}
                                    toggleLoadPanel={toggleLoadPanel}
                                    showButtons={this.props.showButtons}
                                />
                            </div>
                        </div>
                        <FinishPopup
                            show={this.state.showFinishPopup}
                            onHiding={this.toggleFinishPopup}
                            cookingProcess={this.state.cookingProcess}
                            onFinishCookingProcessClick={this.onFinishCookingProcessClick}
                        />
                    </div>
                )}
            </React.Fragment>
        );
    }
}
export default withTranslation(["dynamicTranslation"])(CookingProcessCompact);
