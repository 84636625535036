import Config from "../../Config";
import { authHeader, handleResponse } from "../../helpers/requestHelpers";

const config = new Config();

/*Diese Funktion fügt eine neue Zutat für einen Artikel hinzu*/
export function addIngredient(articlesId, ingredientsId) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ articlesId, ingredientsId }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Ingredients/AddIngredient", requestOptions)
            .then((response) => handleResponse(response))
            .then((result) => {
                dispatch(fetchArticleIngredients(articlesId));
                return result;
            });
    };
}

export function updateIngredientInformations(ingredientsId, key, newData) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ ingredientsId, key, newData }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Ingredients/UpdateIngredientInformations", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                dispatch(receiveArticleIngredients(json.ingredients));
                return json;
            });
    };
}

export function updateIngredientGrammages(ingredientsId, articlesId, key, newData) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ ingredientsId, articlesId, key, newData }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Ingredients/UpdateIngredientGrammages", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => dispatch(receiveArticleIngredients(json)));
    };
}

/*Diese Funktion löscht eine Zutat für einen Artikel*/
export function deleteIngredient(ingredientsId) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ ingredientsId }),
    };
    return function () {
        return fetch(config.backendHost + "/Articles/DeleteIngredient", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export const REQUEST_ARTICLEINGREDIENTS = "REQUEST_ARTICLEINGREDIENTS";
export function requestArticleIngredients() {
    return {
        type: REQUEST_ARTICLEINGREDIENTS,
    };
}

export const RECEIVE_ARTICLEINGREDIENTS = "RECEIVE_ARTICLEINGREDIENTS";
export function receiveArticleIngredients(json) {
    return {
        type: RECEIVE_ARTICLEINGREDIENTS,
        articleIngredients: json,
    };
}

/*Diese Funktion lädt alle Zutaten und Grammaturen eines Artikels*/
export function fetchArticleIngredients(articlesId) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ articlesId }),
    };
    return function (dispatch) {
        dispatch(requestArticleIngredients());
        return fetch(config.backendHost + "/Ingredients/GetIngredientsByArticle", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => dispatch(receiveArticleIngredients(json)));
    };
}
