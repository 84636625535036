import {
    COOKING_PROCESSES_WITH_TIMER_STARTED,
    GET_COOKING_PROCESSES_WITH_TIMER_STARTED,
    SET_COOKING_PROCESSES_CALENDAR_DATE,
    SHOW_COOKING_PROCESSES_CALENDAR,
    UPDATE_CUSTOMER_LANGUAGE,
} from "../actions/cookingProcessActions";

const initialState = {
    isFetching: false,
    cookingProcesses: [],
    cookingProcessesCalendarVisible: false,
    cookingProcessesCalendarDate: new Date(),
    customerLanguage: "de",
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_COOKING_PROCESSES_WITH_TIMER_STARTED:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case COOKING_PROCESSES_WITH_TIMER_STARTED:
            return Object.assign({}, state, {
                isFetching: false,
                cookingProcesses: action.cookingProcesses,
            });
        case SHOW_COOKING_PROCESSES_CALENDAR:
            return Object.assign({}, state, {
                cookingProcessesCalendarVisible: action.cookingProcessesCalendarVisible,
            });
        case UPDATE_CUSTOMER_LANGUAGE:
            return Object.assign({}, state, {
                customerLanguage: action.customerLanguage,
            });
        case SET_COOKING_PROCESSES_CALENDAR_DATE:
            return Object.assign({}, state, {
                cookingProcessesCalendarDate: action.cookingProcessesCalendarDate,
            });
        default:
            return state;
    }
};
