import {
    ADD_ARTICLE_TO_CART,
    DELETE_ORDER_DETAIL,
    DELETE_TEMP_ORDER_DETAIL,
    RECEIVE_ORDERTYPES_FOR_ORDER,
    SET_ARTICLES_LOADING,
    SET_CART_ITEM_IS_PENDING,
    SET_LOADING,
    SET_ORDER,
    SET_ORDER_DETAIL,
    SET_ORDER_TYPE,
    SET_ROOT_ARTICLE_GROUPS,
    TOGGLE_SEARCH_POPUP,
    UNSET_ORDER,
    UPDATE_CURRENT_ARTICLES,
    UPDATE_CURRENT_ARTICLE_GROUPS,
    UPDATE_CUSTOMER_ADDRESS_FOR_ORDER,
    UPDATE_CUSTOMER_FOR_ORDER,
    UPDATE_ORDER_DETAILS,
    UPDATE_ORDER_DETAIL_AMOUNT,
    UPDATE_ORDER_SUMS,
    UPDATE_ORDER_VALIDATION,
    UPDATE_SELECTED_ARTICLE_GROUP,
} from "../actions/orderManagementActions";

const initialState = {
    isLoading: false,
    isArticlesLoading: false,
    tempOrder: null,
    orderValidation: { fullfilled: false },
    orderSums: [],
    orderTypes: [],
    rootArticleGroups: [],
    selectedArticleGroup: null,
    articleGroups: [],
    articles: [],
    isSearchPopupOpen: false,
    cartItemIsPending: false,
    selectedOrderType: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
    switch (action.type) {
        case SET_LOADING:
            return { ...state, isLoading: action.payload };
        case SET_ORDER:
            return { ...state, tempOrder: action.payload };
        case UNSET_ORDER:
            return initialState;
        case UPDATE_ORDER_DETAILS:
            return { ...state, tempOrder: { ...state.tempOrder, OrderDetails: action.payload } };
        case ADD_ARTICLE_TO_CART:
            return {
                ...state,
                tempOrder: {
                    ...state.tempOrder,
                    OrderDetails: [
                        ...state.tempOrder.OrderDetails,
                        {
                            TotalSalesPrice: 0,
                            amount: 1,
                            articleName: action.payload.name,
                            orderDetailsId: 0,
                            size: "",
                            articlesId: action.payload.articleId,
                            cartItemId: state.tempOrder.OrderDetails.length + 1,
                        },
                    ],
                },
            };

        case SET_ORDER_DETAIL:
            return {
                ...state,
                tempOrder: {
                    ...state.tempOrder,
                    OrderDetails: state.tempOrder.OrderDetails.map((orderDetail) => {
                        if (orderDetail.cartItemId === action.payload.cartItemId) {
                            return action.payload;
                        }
                        return orderDetail;
                    }),
                },
                cartItemIsPending: false,
            };
        case SET_CART_ITEM_IS_PENDING:
            return { ...state, cartItemIsPending: action.payload };
        case UPDATE_ORDER_VALIDATION:
            return { ...state, orderValidation: action.payload };
        case UPDATE_ORDER_SUMS:
            return { ...state, orderSums: action.payload };
        case UPDATE_CUSTOMER_FOR_ORDER:
            return {
                ...state,
                tempOrder: {
                    ...state.tempOrder,
                    Customer: action.payload,
                    Address: action.payload
                        ? action.payload.Addresses.find((address) => address.isDefaultDeliveryAddress)
                            ? action.payload.Addresses.find((address) => address.isDefaultDeliveryAddress)
                            : action.payload.Addresses[0]
                        : null,
                },
            };
        case UPDATE_ORDER_DETAIL_AMOUNT:
            return {
                ...state,
                tempOrder: {
                    ...state.tempOrder,
                    OrderDetails: state.tempOrder.OrderDetails.map((orderDetail) => {
                        if (orderDetail.orderDetailsId === action.payload.orderDetailsId) {
                            return { ...action.payload };
                        }
                        return orderDetail;
                    }),
                },
            };
        case DELETE_ORDER_DETAIL:
            return {
                ...state,
                tempOrder: {
                    ...state.tempOrder,
                    OrderDetails: [
                        ...state.tempOrder.OrderDetails.filter((orderDetail) => {
                            return orderDetail.orderDetailsId !== action.payload;
                        }),
                    ],
                },
            };
        case DELETE_TEMP_ORDER_DETAIL:
            return {
                ...state,
                tempOrder: {
                    ...state.tempOrder,
                    OrderDetails: [
                        ...state.tempOrder.OrderDetails.filter((orderDetail) => {
                            return orderDetail.cartItemId !== action.payload;
                        }),
                    ],
                },
            };
        case UPDATE_CUSTOMER_ADDRESS_FOR_ORDER:
            return {
                ...state,
                tempOrder: {
                    ...state.tempOrder,
                    Address: action.payload,
                },
            };
        case RECEIVE_ORDERTYPES_FOR_ORDER:
            return { ...state, orderTypes: action.payload.filter((orderType) => !orderType.isEshop) };
        case SET_ORDER_TYPE:
            return {
                ...state,
                tempOrder: {
                    ...state.tempOrder,
                    orderTypeName: action.payload.name,
                    orderTypesId: action.payload.id,
                },
                selectedOrderType: action.payload,
            };
        case SET_ARTICLES_LOADING:
            return { ...state, isArticlesLoading: action.payload };
        case SET_ROOT_ARTICLE_GROUPS:
            return { ...state, rootArticleGroups: action.payload, articleGroups: action.payload };
        case UPDATE_SELECTED_ARTICLE_GROUP:
            return { ...state, selectedArticleGroup: action.payload };
        case UPDATE_CURRENT_ARTICLE_GROUPS:
            return { ...state, articleGroups: action.payload };
        case UPDATE_CURRENT_ARTICLES:
            return { ...state, articles: action.payload };
        case TOGGLE_SEARCH_POPUP:
            return { ...state, isSearchPopupOpen: action.payload };
        default:
            return state;
    }
};
