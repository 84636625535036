import React from 'react'
import CountDownTimer from '../../../core/countDownTimer/countDownTimer'

export default function Timer(props) {
    return (
        <React.Fragment>
            {props.step.stepTimeSec && <div className="timer-box">
                    <div className="content">
                        <CountDownTimer
                            stepsId={props.step.stepsId}
                            duration={props.step.stepTimeSec}
                            startTime={props.currentTimerStart}
                            afterTimerStart={props.setCurrentTimerStart}
                            afterTimerEnd={props.unsetCurrentTimerStart}
                            timerIsRunning={props.timerIsRunning}
                            timerIsExpired={props.timerIsExpired}
                            forceStopTimer={props.forceStopTimer}
                            soundEnabled={true}
                            type={"digital"}
                        />
                    </div>
            </div>}
            {!props.step.stepTimeSec &&
                <div className="box empty timer">
                    <div className="content">
                        <i className="dx-icon-clock"></i>
                    </div>
                </div>
            }

        </React.Fragment>
    )
}
