import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { PrivateRoute } from "../../../../core/privateRoute/privateRoute";
import aboutThisShop from "./aboutThisShop/aboutThisShop";
import shopRecommendations from "./shopRecommendations/shopRecommendations";

class OnlineShopSettings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            animationClass: "",
        };
    }

    componentDidMount() {
        var self = this;
        this.animationTimeout = setTimeout(() => {
            self.setState({
                animationClass: "revealed",
            });
        }, 300);
    }

    componentWillUnmount() {
        if (this.animationTimeout) {
            clearTimeout(this.animationTimeout);
        }
    }
    render() {
        return (
            <div id="menuPlus" className="page">
                {this.props.match.isExact && (
                    <div className="tilesContainerWrapper">
                        <div id="tilesContainer">
                            <div className="tiles">
                                <div className={this.state.animationClass}>
                                    {this.props.identity.user && (
                                        <Link className="" to={`${this.props.match.url}/impressum`}>
                                            <FontAwesomeIcon icon="utensil-spoon" /> <span>Impressum</span>
                                        </Link>
                                    )}
                                    {this.props.identity.user && (
                                        <Link className="" to={`${this.props.match.url}/empfehlungen`}>
                                            <FontAwesomeIcon icon="network-wired" />{" "}
                                            <span>Online Shop Empfehlungen</span>
                                        </Link>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div className="content">
                    <PrivateRoute path={`${this.props.match.path}/impressum`} component={aboutThisShop} />
                    <PrivateRoute path={`${this.props.match.path}/empfehlungen`} component={shopRecommendations} />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { identity } = state;
    return {
        identity,
    };
}

export default compose(connect(mapStateToProps), withTranslation(["dynamicTranslation"]))(OnlineShopSettings);
