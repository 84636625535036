import {
    REQUEST_ARTICLEARTICLEGROUP,
    RECEIVE_ARTICLEARTICLEGROUP,
    REQUEST_ARTICLEGROUPSIZES,
    RECEIVE_ARTICLEGROUPSIZES,
    REQUEST_ADDARTICLEPRICE,
    RECEIVE_ADDARTICLEPRICE,
    REQUEST_ARTICLEPRICES,
    RECEIVE_ARTICLEPRICES,
    REQUEST_ALLARTICLEADDITIVES,
    RECEIVE_ALLARTICLEADDITIVES,
    REQUEST_ARTICLEINGREDIENTS,
    RECEIVE_ARTICLEINGREDIENTS,
    RECEIVE_ARTICLEGROUPS,
    REQUEST_ARTICLEGROUPS,
    REQUEST_ALLARTICLESIZES,
    RECEIVE_ALLARTICLESIZES,
    REQUEST_ARTICLESIZES,
    RECEIVE_ARTICLESIZES,
    RECEIVE_CALCULATENUTRITIONALVALUES,
    RECEIVE_ABSOLUTGRAMMAGESBYSIZE,
    REQUEST_ARTICLEGROUPARTICLEPRICES,
    RECEIVE_ARTICLEGROUPARTICLEPRICES,
    RECEIVE_ALLCATEGORIES,
    REQUEST_PRICEGROUPS,
    RECEIVE_PRICEGROUPS,
    REQUEST_ORDERTYPES,
    RECEIVE_ORDERTYPES,
    REQUEST_ARTICLEDEPENDENCIES,
    RECEIVE_ARTICLEDEPENDENCIES,
    REQUEST_ARTICLEDEPENDENCYGROUPS,
    RECEIVE_ARTICLEDEPENDENCYGROUPS,
    RECEIVE_ARTICLESBYARTICLEGROUP,
    REQUEST_ARTICLEDEPENDENCIESTREELIST,
    RECEIVE_ARTICLEDEPENDENCIESTREELIST,
    RECEIVE_ARTICLELINKSBYARTICLEGROUP,
    RECEIVE_SERVERMENUCARDTIMESTAMP,
    RECEIVE_ARTICLES,
    REQUEST_ARTICLESWITHSAMENAME,
    RECEIVE_ARTICLESWITHSAMENAME,
} from "../actions/menuCardAction";

const initialState = {
    isFetchingAllArticleAdditives: false,
    isFetching: false,
    isFetchingSizes: false,
    isFetchingArticleSizes: false,
    isFetchingAddPrices: false,
    isFetchingPrice: false,
    isFetchingIngredients: false,
    isFetchingArticleGroups: false,
    isFetchingArticleGroupArticlePrices: false,
    isFetchingOrderTypes: false,
    isFetchingPriceGroups: false,
    isFetchingArticleDependencies: false,
    isFetchingArticleDependenciesTreeList: false,
    isFetchingArticleDependencyGroups: false,
    isFetchingArticlesWithSameName: false,
    isArticle: false,
    isLink: false,
    articleArticleGroup: null,
    articleGroupSizes: [],
    articlePrices: [],
    allArticleAdditives: [],
    articleGroups: [],
    articleIngredients: [],
    allArticleSizes: [],
    articleSizes: [],
    articleGroupArticlePrices: [],
    priceGroups: [],
    orderTypes: [],
    articleDependencies: [],
    articleDependenciesTreeList: [],
    articleDependencyGroups: [],
    nutritionalValuesByGrammages: "",
    absolutGrammagesBySize: "",
    articlesByArticleGroup: [],
    articleLinksByArticleGroup: [],
    categories: [],
    articles: [],
    serverMenucardTimestamp: null,
    articlesWithSameName: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_ARTICLEARTICLEGROUP:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case RECEIVE_ARTICLEARTICLEGROUP:
            return Object.assign({}, state, {
                isFetching: false,
                articleArticleGroup: action.articleArticleGroup,
                isArticle: action.isArticle,
                isLink: action.isLink,
            });
        case REQUEST_ARTICLEGROUPSIZES:
            return Object.assign({}, state, {
                isFetchingSizes: true,
            });
        case RECEIVE_ARTICLEGROUPSIZES:
            return Object.assign({}, state, {
                articleGroupSizes: action.articleGroupSizes,
                isFetchingSizes: false,
            });
        case REQUEST_ADDARTICLEPRICE:
            return Object.assign({}, state, {
                isFetchingAddPrice: true,
            });
        case RECEIVE_ADDARTICLEPRICE:
            return Object.assign({}, state, {
                isFetchingAddPrice: false,
            });
        case REQUEST_ARTICLEPRICES:
            return Object.assign({}, state, {
                isFetchingPrices: true,
            });
        case RECEIVE_ARTICLEPRICES:
            return Object.assign({}, state, {
                isFetchingPrices: false,
                articlePrices: action.articlePrices,
            });
        case RECEIVE_ALLARTICLEADDITIVES:
            return Object.assign({}, state, {
                isFetchingAllArticleAdditives: false,
                allArticleAdditives: action.allArticleAdditives,
            });
        case REQUEST_ALLARTICLEADDITIVES:
            return Object.assign({}, state, {
                isFetchingAllArticleAdditives: true,
            });
        case RECEIVE_ALLCATEGORIES:
            return Object.assign({}, state, {
                categories: action.categories,
            });
        case REQUEST_ARTICLEGROUPS:
            return Object.assign({}, state, {
                isFetchingArticleGroups: true,
            });
        case RECEIVE_ARTICLEGROUPS:
            return Object.assign({}, state, {
                isFetchingArticleGroups: false,
                articleGroups: action.articleGroups,
            });
        case REQUEST_ARTICLEINGREDIENTS:
            return Object.assign({}, state, {
                isFetchingIngredients: false,
            });
        case RECEIVE_ARTICLEINGREDIENTS:
            return Object.assign({}, state, {
                isFetchingIngredients: true,
                articleIngredients: action.articleIngredients,
            });
        case REQUEST_ALLARTICLESIZES:
            return Object.assign({}, state, {
                isFetchingAllArticleSizes: true,
            });
        case RECEIVE_ALLARTICLESIZES:
            return Object.assign({}, state, {
                isFetchingAllArticleSizes: false,
                allArticleSizes: action.allArticleSizes,
            });
        case REQUEST_ARTICLESIZES:
            return Object.assign({}, state, {
                isFetchingArticleSizes: true,
            });
        case RECEIVE_ARTICLESIZES:
            return Object.assign({}, state, {
                articleSizes: action.articleSizes,
                isFetchingArticleSizes: false,
            });
        case RECEIVE_CALCULATENUTRITIONALVALUES:
            return Object.assign({}, state, {
                nutritionalValuesByGrammages: action.nutritionalValuesByGrammages,
            });
        case RECEIVE_ABSOLUTGRAMMAGESBYSIZE:
            return Object.assign({}, state, {
                absolutGrammagesBySize: action.absolutGrammagesBySize,
            });
        case REQUEST_ARTICLEGROUPARTICLEPRICES:
            return Object.assign({}, state, {
                isFetchingArticleGroupArticlePrices: true,
            });
        case RECEIVE_ARTICLESBYARTICLEGROUP:
            return Object.assign({}, state, {
                isFetchingArticleSizes: false,
                articlesByArticleGroup: action.articlesByArticleGroup,
            });
        case RECEIVE_ARTICLEGROUPARTICLEPRICES:
            return Object.assign({}, state, {
                isFetchingArticleGroupArticlePrices: false,
                articleGroupArticlePrices: action.articleGroupArticlePrices,
            });
        case REQUEST_PRICEGROUPS:
            return Object.assign({}, state, {
                isFetchingPriceGroups: true,
            });
        case RECEIVE_PRICEGROUPS:
            return Object.assign({}, state, {
                isFetchingPriceGroups: false,
                priceGroups: action.priceGroups,
            });
        case REQUEST_ORDERTYPES:
            return Object.assign({}, state, {
                isFetchingOrderTypes: true,
            });
        case RECEIVE_ORDERTYPES:
            return Object.assign({}, state, {
                isFetchingOrderTypes: false,
                orderTypes: action.orderTypes,
            });
        case REQUEST_ARTICLEDEPENDENCIES:
            return Object.assign({}, state, {
                isFetchingArticleDependencies: true,
            });
        case RECEIVE_ARTICLEDEPENDENCIES:
            return Object.assign({}, state, {
                isFetchingArticleDependencies: false,
                articleDependencies: action.articleDependencies,
            });
        case REQUEST_ARTICLEDEPENDENCYGROUPS:
            return Object.assign({}, state, {
                isFetchingArticleDependencyGroups: true,
            });
        case RECEIVE_ARTICLEDEPENDENCYGROUPS:
            return Object.assign({}, state, {
                isFetchingArticleDependencyGroups: false,
                articleDependencyGroups: action.articleDependencyGroups,
            });
        case REQUEST_ARTICLEDEPENDENCIESTREELIST:
            return Object.assign({}, state, {
                isFetchingArticleDependenciesTreeList: true,
            });
        case RECEIVE_ARTICLEDEPENDENCIESTREELIST:
            return Object.assign({}, state, {
                isFetchingArticleDependenciesTreeList: false,
                articleDependenciesTreeList: action.articleDependenciesTreeList,
            });
        case RECEIVE_ARTICLELINKSBYARTICLEGROUP:
            return Object.assign({}, state, {
                isFetchingArticleSizes: false,
                articleLinksByArticleGroup: action.articleLinksByArticleGroup,
            });
        case RECEIVE_ARTICLES:
            return Object.assign({}, state, {
                articles: action.articles,
            });
        case RECEIVE_SERVERMENUCARDTIMESTAMP:
            return Object.assign({}, state, {
                serverMenucardTimestamp: action.serverMenucardTimestamp,
            });
        case REQUEST_ARTICLESWITHSAMENAME:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case RECEIVE_ARTICLESWITHSAMENAME:
            return Object.assign({}, state, {
                isFetching: false,
                articlesWithSameName: action.articlesWithSameName,
            });
        default:
            return state;
    }
};
