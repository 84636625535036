import React from "react";
import { useTranslation } from "react-i18next";

export default function Hint(props) {
    const { t } = useTranslation(["dynamicTranslation"]);
    return (
        <React.Fragment>
            {props.step.hint && (
                <div className="inner-box hint">
                    <header>{t("Tipp")}</header>
                    <div className="inner-box-content">
                        <table>
                            <tbody>
                                <tr>
                                    <td className="name">{t(props.step.hint)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
            {!props.step.hint && (
                <div className="box empty hint">
                    <div className="content">
                        <i className="dx-icon-info"></i>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
}
