import Config from "../../Config";
import { authHeader, handleResponse } from "../../helpers/requestHelpers";

const config = new Config();

export const REQUEST_ARTICLEDEPENDENCYGROUPS =
    "REQUEST_ARTICLEDEPENDENCYGROUPS";
export function requestArticleDependencyGroups() {
    return {
        type: REQUEST_ARTICLEDEPENDENCYGROUPS
    };
}

export const RECEIVE_ARTICLEDEPENDENCYGROUPS =
    "RECEIVE_ARTICLEDEPENDENCYGROUPS";
export function receiveArticleDependencyGroups(json) {
    return {
        type: RECEIVE_ARTICLEDEPENDENCYGROUPS,
        articleDependencyGroups: json
    };
}

/*Diese Funktion lädt alle Abhängigkeitengruppen*/
export function fetchArticleDependencyGroups() {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };
    return function (dispatch) {
        dispatch(requestArticleDependencyGroups());
        return fetch(
            config.backendHost + "/ArticleDependencyGroups/get",
            requestOptions
        )
            .then(response => handleResponse(response))
            .then(json => dispatch(receiveArticleDependencyGroups(json)));
    };
}

export const REQUEST_ARTICLEDEPENDENCIES = "REQUEST_ARTICLEDEPENDENCIES";
export function requestArticleDependencies() {
    return {
        type: REQUEST_ARTICLEDEPENDENCIES
    };
}

export const RECEIVE_ARTICLEDEPENDENCIES = "RECEIVE_ARTICLEDEPENDENCIES";
export function receiveArticleDependencies(string) {
    return {
        type: RECEIVE_ARTICLEDEPENDENCIES,
        articleDependencies: string
    };
}

/*Diese Funktion lädt alle Abhängigkeiten eines Artikels oder einer Artikelgruppe*/
export function fetchArticleDependencies(id, isArticle) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ id, isArticle })
    };
    return function (dispatch) {
        dispatch(requestArticleDependencies());
        return fetch(
            config.backendHost +
            "/ArticleDependencyGroups/GetArticleDependencies",
            requestOptions
        )
            .then(response => handleResponse(response))
            .then(s => dispatch(receiveArticleDependencies(s)));
    };
}

export const REQUEST_ARTICLEDEPENDENCIESTREELIST =
    "REQUEST_ARTICLEDEPENDENCIESTREELIST";
export function requestArticleDependenciesTreeList() {
    return {
        type: REQUEST_ARTICLEDEPENDENCIESTREELIST
    };
}

export const RECEIVE_ARTICLEDEPENDENCIESTREELIST =
    "RECEIVE_ARTICLEDEPENDENCIESTREELIST";
export function receiveArticleDependenciesTreeList(json) {
    return {
        type: RECEIVE_ARTICLEDEPENDENCIESTREELIST,
        articleDependenciesTreeList: json
    };
}

/*Diese Funktion lädt alle Abhängigkeiten eines Artikels oder einer Artikelgruppe*/
export function fetchArticleDependenciesTreeList(id, adgId, isArticle) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ id, isArticle, adgId })
    };
    return function (dispatch) {
        dispatch(requestArticleDependenciesTreeList());
        return fetch(
            config.backendHost +
            "/ArticleDependencyGroups/GetDummyListDependencies",
            requestOptions
        )
            .then(response => handleResponse(response))
            .then(json => dispatch(receiveArticleDependenciesTreeList(json)));
    };
}

/*Diese Funktion updatet eine Abhängigkeit eines Artikels oder einer Artikelgruppe*/
export function updateArticleDependency(
    id,
    dependId,
    adgId,
    add,
    isArticle,
    isDependArticle
) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({
            id,
            dependId,
            adgId,
            add,
            isArticle,
            isDependArticle
        })
    };
    return function (dispatch) {
        return fetch(
            config.backendHost +
            "/ArticleDependencyGroups/UpdateDependency", requestOptions)
            .then(response => handleResponse(response))
            .then(result => dispatch(fetchArticleDependencies(id, isArticle)));
    };
}

export function updateArticleDependencyGroups(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return function (dispatch) {
        return fetch(
            config.backendHost + "/ArticleDependencyGroups/update",
            requestOptions
        ).then(response => handleResponse(response));
    };
}

export function insertArticleDependencyGroups(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return function (dispatch) {
        return fetch(
            config.backendHost + "/ArticleDependencyGroups/insert",
            requestOptions
        ).then(response => handleResponse(response));
    };
}

export function deleteArticleDependencyGroups(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return function (dispatch) {
        return fetch(
            config.backendHost + "/ArticleDependencyGroups/delete",
            requestOptions
        ).then(response => handleResponse(response));
    };
}
