import numeral from "numeral";
import Config from "../Config";

export function formatGrammage(grammage) {
    if (grammage < 1000) {
        return numeral(Math.ceil(grammage)).format(new Config().grammagesFormat);
    } else {
        return numeral(grammage / 1000).format(new Config().grammagesFormat);
    }
}

export function getGrammageUnit(grammage) {
    if (grammage < 1000) {
        return "g";
    } else {
        return "kg";
    }
}