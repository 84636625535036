import React from "react";
import CookingProcessStep from "../cookingProcessStep";

export default function CookingProcessCompactInfo({ cookingProcess, sortedSteps, toggleLoadPanel, showButtons, isCookingProcessCustomer, customersUid }) {
    return (
        <div className="cookingProcessCompactInfo">
            <CookingProcessStep
                step={cookingProcess.CurrentStep}
                key={cookingProcess.CurrentStep.sequenceNumber}
                disabledMessage={cookingProcess.disabledMessage}
                isCookingProcessCustomer={isCookingProcessCustomer}
                cookingProcessId={parseInt(cookingProcess.cookingProcessId)}
                customersUid={customersUid}
                isDone={false}
                isCurrentStep={true}
                showButtons={showButtons}
                currentTimerStart={cookingProcess.timerStartTimeStamp}
                toggleLoadPanel={toggleLoadPanel}
                stepNumber={sortedSteps.map(function (x) { return x.stepsId; }).indexOf(cookingProcess.CurrentStep.stepsId) + 1}
                phase={cookingProcess.CurrentPhase}
                singleView={true}
            />
        </div>
    );
}
