import Config from "../Config";
import { authHeader, handleResponse } from "../helpers/requestHelpers";

const config = new Config();

export const REQUEST_MEDIAFOLDER = "REQUEST_MEDIAFOLDER";
export function requestMediaFolder() {
    return {
        type: REQUEST_MEDIAFOLDER,
    };
}

export const RECEIVE_MEDIAFOLDER = "RECEIVE_MEDIAFOLDER";
export function receiveMediaFolder(json) {
    return {
        type: RECEIVE_MEDIAFOLDER,
        mediaFolder: json,
    };
}

export function createMediaFolder(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/mediafolder/create", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function getRootFoldersForLicenseNumber() {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };

    return function (dispatch) {
        dispatch(requestMediaFolder);
        return fetch(config.backendHost + "/mediafolder/getRootFoldersForLicenseNumber", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => dispatch(receiveMediaFolder(json)));
    };
}

export function getChildrenById(mediaFolderId) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ mediaFolderId: mediaFolderId }),
    };

    return function (dispatch) {
        dispatch(requestMediaFolder);
        return fetch(config.backendHost + "/mediafolder/getChildrenById", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => json);
    };
}

export function updateMediaFolder(mediaFolder) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(mediaFolder),
    };
    return function () {
        return fetch(config.backendHost + "/mediafolder/update", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => json);
    };
}

export function checkIfFolderIsInFolder(folderId, folderToCheckId) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ folderId: folderId, folderToCheckId: folderToCheckId }),
    };

    return function (dispatch) {
        dispatch(requestMediaFolder);
        return fetch(config.backendHost + "/mediafolder/checkIfFolderIsInFolder", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => json);
    };
}

export function getChildrenByIdWithExceptionList(mediaFolderId, mediaFolderIds) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ mediaFolderId: mediaFolderId, mediaFolderIds: mediaFolderIds }),
    };

    return function (dispatch) {
        dispatch(requestMediaFolder);
        return fetch(config.backendHost + "/mediafolder/getChildrenByIdWithExceptionList", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => json);
    };
}

export function getMediaFoldersContains(searchString) {
    let encodedSearchString = encodeURIComponent(searchString.toLowerCase());
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };

    return function () {
        return fetch(config.backendHost + "/mediafolder/getByString/" + encodedSearchString, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => json);
    };
}

export function deleteMediaFolder(mediaFolderId) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ mediaFolderId: mediaFolderId }),
    };
    let requestURL = "/mediafolder/delete";
    return function () {
        return fetch(config.backendHost + requestURL, requestOptions).then((response) => handleResponse(response));
    };
}
