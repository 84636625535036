import { LoadPanel } from "devextreme-react";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.compact.css";
import React, { Component } from "react";
import {
    fetchCookingProcessStepById,
    fetchStepIngredients,
    fetchCookingProcessCustomerStepById,
    fetchCustomerStepIngredients,
} from "../../../../actions/cookingProcessActions";
import {
    getCustomerTempIngredientsForStep,
    getTempIngredientsForStep,
} from "../../../../actions/tempIngredientActions";
import store from "../../../../store";
import Hint from "./hint";
import Image from "./image";
import Instructions from "./instructions";
import "./stepPage.scss";
import Timer from "./timer";

export default class StepPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            step: undefined,
            stepIngredients: [],
            tempIngredients: [],
            isLoading: false,
        };

        this.getData = this.getData.bind(this);
    }

    async getData(props) {
        const { cookingProcessId, stepsId, customersUid } = props;
        let step = undefined;
        let stepIngredients = [];
        let tempIngredients = [];

        if (stepsId && cookingProcessId) {
            if (customersUid) {
                await Promise.all([
                    store.dispatch(fetchCookingProcessCustomerStepById(customersUid, stepsId)).then((response) => {
                        step = response;
                    }),
                    store
                    .dispatch(fetchCustomerStepIngredients(cookingProcessId, stepsId, customersUid))
                        .then((response) => {
                            stepIngredients = response;
                        }),
                    store.dispatch(getCustomerTempIngredientsForStep(stepsId)).then((response) => {
                        tempIngredients = response;
                    }),
                ]).then(() => {
                    if (step) {
                        this.setState(
                            {
                                step: step,
                                stepIngredients: stepIngredients,
                                tempIngredients: tempIngredients,
                                isLoading: false
                            }
                        );
                    }
                });
            } else {
                await Promise.all([
                    store.dispatch(fetchCookingProcessStepById(stepsId)).then((response) => {
                        step = response;
                    }),
                    store.dispatch(fetchStepIngredients(cookingProcessId, stepsId)).then((response) => {
                        stepIngredients = response;
                    }),
                    store.dispatch(getTempIngredientsForStep(stepsId)).then((response) => {
                        tempIngredients = response;
                    }),
                ]).then(() => {
                    if (step) {
                        this.setState(
                            {
                                step: step,
                                stepIngredients: stepIngredients,
                                tempIngredients: tempIngredients,
                                isLoading: false
                            }
                        );
                    }
                });
            }
        }
    }

    componentDidMount() {
        this.setState(
            {
                isLoading: true,
            },
            () => this.getData(this.props)
        );
    }

    componentDidUpdate(prevProps) {
        if (this.props.stepsId !== prevProps.stepsId) {
            this.setState(
                {
                    isLoading: true,
                },
                () => this.getData(this.props)
            );
        }
    }

    render() {
        const { step, stepIngredients, tempIngredients } = this.state;
        return (
            <div id="StepPage" className="content">
                <LoadPanel visible={this.state.isLoading} />
                {step && (
                    <div className="content-wrapper">
                        <div className="top">
                            {step.text && (
                                <Instructions
                                    phase={this.props.phase}
                                    step={step}
                                    steps={this.props.steps}
                                    toggleAllSteps={this.props.toggleAllSteps}
                                    showAllSteps={this.props.showAllSteps}
                                    goToNextStepEnabled={this.props.goToNextStepEnabled}
                                    goToNext={this.props.goToNext}
                                    stepIngredients={stepIngredients}
                                    tempIngredients={tempIngredients}
                                />
                            )}
                        </div>
                        <div className="bottom">
                            <div className="box-wrapper-left">
                                <Hint step={step} />
                            </div>
                            <div className="box-wrapper-middle">
                                <Image step={step}/>
                            </div>
                            <div className="box-wrapper-right">
                                <Timer
                                    step={step}
                                    currentTimerStart={this.props.currentTimerStart}
                                    setCurrentTimerStart={this.props.setCurrentTimerStart}
                                    unsetCurrentTimerStart={this.props.unsetCurrentTimerStart}
                                    timerIsRunning={this.props.timerIsRunning}
                                    timerIsExpired={this.props.timerIsExpired}
                                    forceStopTimer={this.props.forceStopTimer}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
