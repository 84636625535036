import { SET_THEME } from "../actions/themeActions";

export const defaultTheme = {
    title: "BIOS",
    URL: "",
    logoGuid: "",
    logoUrl: "",
    colors: {
        accentColor: "84, 64%, 35%",
        accentColorl44: "84, 64%, 79%",
        accentColorl14: "84, 56%, 49%",
        accentColords8l10: "84, 56%, 45%",
        accentColordl6: "84, 64%, 29%",
        accentColordl8: "84, 64%, 27%",
        accentColordl10: "84, 64%, 25%",
        accentColordl12: "84, 64%, 23%",
        accentColordl16: "84, 64%, 19%",
        accentColordl24: "84, 64%, 11%",
        textColor: "0, 0%, 20%",
        backgroundColor: "0, 0%, 100%",
        borderColor: "0, 0%, 87%",
        tilesColor: "0, 0%, 87%",
        tilesBackgroundColor: "0, 0%, 100%",
        successColor: "120, 39%, 54%",
        successColordl4: "120, 39%, 50%",
        successColordl8: "120, 39%, 46%",
        successColordl10: "120, 39%, 44%",
        successColordl12: "120, 39%, 42%",
        successColordl16: "120, 39%, 38%",
        successColordl20: "120, 39%, 34%",
        successColordl24: "120, 39%, 30%",
        warningColor: "35, 84%, 62%",
        dangerColor: "2, 64%, 58%",
        dangerColorl16: "2, 64%, 74%",
        dangerColordl4: "2, 64%, 54%",
        dangerColordl8: "2, 64%, 50%",
        dangerColordl10: "2, 64%, 48%",
        dangerColordl12: "2, 64%, 46%",
        dangerColordl16: "2, 64%, 42%",
        dangerColordl20: "2, 64%, 38%",
        dangerColordl24: "2, 64%, 34%",
        headerBackgroundColor: "84, 64%, 35%",
        footerBackgroundColor: "84, 64%, 35%",
        buttonBackgroundColor: "84, 64%, 35%",
    },
};

const initialState = {
    ...defaultTheme,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_THEME:
            return Object.assign({}, state, {
                title: action.theme.title,
                URL: action.theme.URL,
                logoUrl: action.theme.logoUrl,
                colors: action.theme.colors,
            });
        default:
            return state;
    }
};
