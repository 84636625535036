import { InputLabel, MenuItem, Select } from "@material-ui/core";
import React, { useState } from "react";

export const NutritionSelect = ({nutritionalValues, propertyName, name, defaultValue, onChange = () => {}}) => {
    const [value, setValue] = useState(defaultValue);
    console.log(value);
    return <div class="nutrition-select" style={{ marginTop: 20 }}>
            <InputLabel>{name}</InputLabel>
            <Select
                variant="outlined"
                value={value !== undefined ? value.id : ""}
                label="Bitte Nährwert auswählen"
                onChange={(e) => {
                    console.log(e);
                    onChange({id: e.target.value, name: propertyName});
                    setValue(nutritionalValues.find(obj => obj.id === e.target.value))
                }}
                fullWidth
                select
            >
                {nutritionalValues.map((field, index) => {
                    return (
                        <MenuItem key={index} value={field.id}>
                            {field.name} ({field.nutritionalValueGroup})
                        </MenuItem>
                    );
                })}
            </Select>
    </div>;
}