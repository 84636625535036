import Config from "../Config";
import { authHeader, handleResponse } from "../helpers/requestHelpers";

const config = new Config();

export const REQUEST_STORETIMES = "REQUEST_STORETIMES";
export function requestStoreTimes() {
    return {
        type: REQUEST_STORETIMES
    };
}

export const RECEIVE_STORETIMES = "RECEIVE_STORETIMES";
export function receiveStoreTimes(json) {
    return {
        type: RECEIVE_STORETIMES,
        storeTimes: json
    };
}

export const ADD_STORETIME = "ADD_STORETIME";
export function addStoreTime(dummyStoreTime) {
    return {
        type: ADD_STORETIME,
        storeTime: dummyStoreTime
    };
}

export function fetchStoreTimes() {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };
    return function (dispatch) {
        dispatch(requestStoreTimes());
        return fetch(config.backendHost + "/StoreTimes/Get", requestOptions)
            .then(response => handleResponse(response))
            .then(json => dispatch(receiveStoreTimes(json)));
    };
}

export function fetchStoreTimesForWeeklyPlanComponent() {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };
    return function (dispatch) {
        dispatch(requestStoreTimes());
        return fetch(config.backendHost + "/StoreTimes/GetStoreTimesForWeeklyPlanComponents", requestOptions)
            .then(response => handleResponse(response));
    };
}

export function insertStoreTime(storeTime) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(storeTime)
    };
    return function (dispatch) {
        return fetch(
            config.backendHost + "/StoreTimes/Insert",
            requestOptions
        ).then(response => handleResponse(response));
    };
}

