import notify from "devextreme/ui/notify";
import { getToast } from "./requestHelpers";

export function createPdf(blob, reportName) {
    blob.blob().then((resp) => {
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should

        let newDate = new Date();
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();

        var newBlob = new Blob([resp], { type: "application/pdf" });

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob, reportName + "_" + date + "_" + month + "_" + year + ".pdf");
            return;
        }

        const data = window.URL.createObjectURL(newBlob);
        var newWin = window.open(data);             

        if(!newWin || newWin.closed || typeof newWin.closed=='undefined') 
        { 
            notify(getToast("Fehler beim Öffnen des Dokumentes. Bitte erlaube Popups in den Browsereinstellungen", "error"));
        }

        setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
        }, 100);
    });
}
