import React, { Component } from "react";
import LoginForm from "./loginForm/loginForm";
import LicenseForm from "./licenseForm/licenseForm";
import "./login.scss";
import store from "./../../../store";
import { licenseIsValid } from "./../../../actions/licenseActions";
import { withTranslation } from "react-i18next";
import { LoadIndicator } from "devextreme-react";
import { connect } from "react-redux";
import { compose } from "redux";
import { loadLoginTranslation } from "../../../actions/menuCardAction";
import Config from "../../../Config";

const config = new Config();
class Login extends Component {
    constructor(props) {
        super(props);
        this.state = { licenseIsValid: undefined, loading: true };
    }

    componentDidMount() {
        const { i18n } = this.props;
        var language =
            this.props.identity && this.props.identity.user ? this.props.identity.user.selectedLanguage : "de";
        this.setState({ loading: true });
        i18n.changeLanguage(language);
        store.dispatch(licenseIsValid()).then((res) => {
            this.setState({ licenseIsValid: res }, this.setState({ loading: false }));
        });
        store.dispatch(loadLoginTranslation(config.backendHost + "/settings/loadLoginTranslation/" + language));
    }

    render() {
        const { loginTranslations } = this.props.settings;
        return (
            <React.Fragment>
                {this.state.loading && (
                    <div id="themeLoading">
                        <LoadIndicator width={80} height={80} />
                    </div>
                )}
                {!this.state.loading && (
                    <div className="login">
                        {this.state.licenseIsValid && (
                            <div className="loginBox">
                                <h2>{loginTranslations ? loginTranslations.login : "Login"}</h2>
                                <LoginForm />
                            </div>
                        )}
                        {!this.state.licenseIsValid && (
                            <div className="loginBox">
                                <h2>
                                    {loginTranslations
                                        ? loginTranslations.enterLicenseKey
                                        : "Bitte gib einen gültigen Lizenzschlüssel ein"}
                                </h2>
                                <LicenseForm forceUpdate={false} />
                            </div>
                        )}
                    </div>
                )}
            </React.Fragment>
        );
    }
}
function mapStateToProps(state) {
    const { settings } = state;
    return {
        settings,
    };
}

export default compose(connect(mapStateToProps), withTranslation(["dynamicTranslation"]))(Login);
