import Config from "../../Config";
import { authHeader, handleResponse, translateResponse } from "../../helpers/requestHelpers";

const config = new Config();

export const REQUEST_ORDERTYPES = "REQUEST_ORDERTYPES";
export function requestOrderTypes() {
    return {
        type: REQUEST_ORDERTYPES,
    };
}

export const RECEIVE_ORDERTYPES = "RECEIVE_ORDERTYPES";
export function receiveOrderTypes(json) {
    return {
        type: RECEIVE_ORDERTYPES,
        orderTypes: json,
    };
}

export function fetchOrderTypes(includeDeleted, t) {
    return function (dispatch) {
        dispatch(requestOrderTypes());
        return fetch(config.backendHost + "/OrderTypes/get", {
            method: "post",
            headers: authHeader(),
            body: includeDeleted,
        })
            .then((response) => handleResponse(response))
            .then((response) => translateResponse(response, t))
            .then((json) => dispatch(receiveOrderTypes(json)));
    };
}

export function updateOrderTypes(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/OrderTypes/update", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function insertOrderTypes(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/OrderTypes/insert", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function deleteOrderTypes(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/OrderTypes/delete", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}
