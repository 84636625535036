import React from "react";
import { useTranslation } from "react-i18next";
import "./countDownTimer.scss";

function DigitalWatch(props) {
    const { t } = useTranslation(["dynamicTranslation"]);
    return (
        <span className={props.className}>
            {props.initDays > 0 && (
                <span>
                    {props.runningBackwards ? "-" : ""} {props.initDays} {t("Tage")}
                </span>
            )}
            <span>
                {props.runningBackwards ? "-" : ""}
                {String(props.hours).padStart(2, "0")}:
            </span>
            <span>{String(props.minutes).padStart(2, "0")}:</span>
            <span>{String(props.seconds).padStart(2, "0")}</span>
        </span>
    );
}

export default DigitalWatch;
