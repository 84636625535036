import Config from "../Config";
import { authHeader, handleResponse } from "../helpers/requestHelpers";

const config = new Config();

export function getAllTempIngredients() {
    return function (dispatch) {
        const requestOptions = {
            method: "GET",
            headers: authHeader(),
        };
        return fetch(config.backendHost + "/TempIngredients/get", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function insertTempIngredient(name) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(name),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/TempIngredients/insert", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function updateTempIngredient(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/TempIngredients/update", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function deleteTempIngredient(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/TempIngredients/delete", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function updateTempIngredientSteps(stepsId, tempIngredientsIds) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ stepsId: stepsId, tempIngredients: tempIngredientsIds }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/TempIngredients/updateTempIngredientSteps", requestOptions).then(
            (response) => handleResponse(response)
        );
    };
}

export function updateTempIngredientsUsedInStep(tempIngredientsId, stepsId) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ tempIngredientsId: tempIngredientsId, stepsId: stepsId }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/TempIngredients/updateTempIngredientsUsedInStep", requestOptions).then(
            (response) => handleResponse(response)
        );
    };
}

export function getTempIngredientsWithStepsId(stepsId) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(stepsId),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/TempIngredients/getTempIngredientsWithStepsId", requestOptions).then(
            (response) => handleResponse(response)
        );
    };
}

export function getTempIngredientsUsedByStep(stepsId) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(stepsId),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/TempIngredients/getTempIngredientsUsedByStep", requestOptions).then(
            (response) => handleResponse(response)
        );
    };
}
export function getTempIngredientsForStep(stepsId) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(stepsId),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/TempIngredients/getTempIngredientsForStep", requestOptions).then(
            (response) => handleResponse(response)
        );
    };
}
export function getCustomerTempIngredientsForStep(stepsId) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(stepsId),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/TempIngredients/getCustomerTempIngredientsForStep", requestOptions).then(
            (response) => handleResponse(response)
        );
    };
}
export function getTempIngredientsFromPreviousSteps(stepsId) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(stepsId),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/TempIngredients/getTempIngredientsFromPreviousSteps", requestOptions).then(
            (response) => handleResponse(response)
        );
    };
}

export function getAllTempIngredientsForRecipe(articlesId) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(articlesId),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/TempIngredients/getAllTempIngredientsForRecipe", requestOptions).then(
            (response) => handleResponse(response)
        );
    };
}

export function updatePrintLabel(tempIngredientsId, print) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ tempIngredientsId: tempIngredientsId, print: print }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/TempIngredients/updatePrintLabel", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}
