import { RECEIVE_STORETIMES, REQUEST_STORETIMES, ADD_STORETIME } from "../actions/storeTimesAction";

const initialState = {
    isFetching: false,
    storeTimes: [],
	weekdays: [
        {id: 1, name: 'Montag'},
        {id: 2, name: 'Dienstag'},
        {id: 3, name: 'Mittwoch'},
        {id: 4, name: 'Donnerstag'},
        {id: 5, name: 'Freitag'},
        {id: 6, name: 'Samstag'},
        {id: 7, name: 'Sonntag'},
    ],
}

export default (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_STORETIMES:
            return Object.assign({}, state, {
                isFetching: true
            })
        case RECEIVE_STORETIMES:
            return Object.assign({}, state, {
                isFetching: false,
                storeTimes: action.storeTimes
            })
        case ADD_STORETIME:
            return {
                ...state,
                storeTimes: [...state.storeTimes, action.payload]
            }
        default:
            return state;

    }
}