import React, { Component } from "react";
import "./countDownTimer.scss";
import { ProgressBar } from "devextreme-react/progress-bar";
import ReactHowler from "react-howler";
import DigitalWatch from "./digitalWatch";
import { withTranslation } from "react-i18next";

class CountDownTimer extends Component {
    constructor(props) {
        super(props);
        const { t } = props;
        this.timerInterval = undefined;
        this.stopInterval = false;

        this.state = {
            startTime: this.props.startTime ? new Date(this.props.startTime) : new Date(),
            timerButtonText: this.props.startButtonText ? this.props.startButtonText : t("Start"),
            alertText: this.props.alertText ? this.props.alertText : t("Timer abgelaufen!"),
            duration: this.props.duration,
            afterTimerStart: this.props.afterTimerStart ? this.props.afterTimerStart : undefined,
            afterTimerEnd: this.props.afterTimerEnd ? this.props.afterTimerEnd : undefined,
            type: this.props.type ? this.props.type : "digital",
            showAlert: false,
            timerStarted: false,
            timerExpired: false,
            timerStopped: false,
            status: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
            initHours: 0,
            initMinutes: 0,
            initSeconds: 0,
            playingBeep: false,
            playingBeep2: false,
            playingFinalBeep: false,
        };

        this.startTimer = this.startTimer.bind(this);
        this.stopAlert = this.stopAlert.bind(this);
        this.statusFormat = this.statusFormat.bind(this);
        this.initializeState = this.initializeState.bind(this);
        this.resetTimer = this.resetTimer.bind(this);
        this.timerTick = this.timerTick.bind(this);
        this.stopTimer = this.stopTimer.bind(this);

        this.countDownDate = null;
    }

    componentDidMount() {
        this.initializeState();
    }

    componentDidUpdate(prevProps) {
        if (this.props.stepsId !== prevProps.stepsId) {
            this.resetTimer();
            this.initializeState();
        }
        if (this.props.forceStopTimer && this.props.forceStopTimer !== prevProps.forceStopTimer) {
            this.stopTimer();
        }
    }

    componentWillUnmount() {
        clearInterval(this.timerInterval);
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                {this.state.type === "digital" && (
                    <div id="countDownTimer" className={this.currentState()}>
                        {this.currentState() === "idle" && (
                            <div id="start" onClick={this.startTimer}>
                                <span>{this.state.timerButtonText}</span>

                                <DigitalWatch
                                    className={"total"}
                                    runningBackwards={false}
                                    hours={this.state.initHours}
                                    minutes={this.state.initMinutes}
                                    seconds={this.state.initSeconds}
                                />
                            </div>
                        )}
                        {this.currentState() === "running" && (
                            <div id="timer">
                                <DigitalWatch
                                    className={"total"}
                                    runningBackwards={false}
                                    hours={this.state.hours}
                                    minutes={this.state.minutes}
                                    seconds={this.state.seconds}
                                />
                            </div>
                        )}
                        {this.currentState() === "runningBackward" && (
                            <div className="alert" onClick={this.stopAlert}>
                                <p>{this.state.alertText}</p>
                                <DigitalWatch
                                    className={"total"}
                                    runningBackwards={true}
                                    hours={this.state.hours}
                                    minutes={this.state.minutes}
                                    seconds={this.state.seconds}
                                />
                            </div>
                        )}
                        {this.currentState() === "timerStopped" && (
                            <div id="alert" onClick={this.stopAlert}>
                                <span>{t("Timer beendet")}</span>
                            </div>
                        )}
                    </div>
                )}
                {this.state.type === "progressbar" && (
                    <ProgressBar
                        min={0}
                        className={this.state.status > 0.9 * this.state.duration ? "complete" : ""}
                        max={this.state.duration}
                        statusFormat={this.statusFormat}
                        value={this.state.status}
                    />
                )}
                <ReactHowler src="/beep.mp3" playing={this.state.playingBeep} />
                <ReactHowler src="/beep2.mp3" playing={this.state.playingBeep2} />
                <ReactHowler src="/finalBeep.mp3" playing={this.state.playingFinalBeep} />
            </div>
        );
    }

    stopTimer() {
        // clearInterval(this.timerInterval);
        this.setState({
            timerStarted: false,
            timerExpired: false,
            timerStopped: true,
            playingBeep: false,
            playingBeep2: false,
            playingFinalBeep: false,
        });
    }

    resetTimer() {
        this.setState({
            timerStarted: false,
            timerExpired: false,
            timerStopped: false,
        });
        clearInterval(this.timerInterval);
    }

    statusFormat(value) {
        const { t } = this.props;
        return (
            t("Timer") +
            ": " +
            (this.currentState() === "runningBackward" ? "-" : "") +
            (String(this.state.hours).padStart(2, "0") +
                ":" +
                String(this.state.minutes).padStart(2, "0") +
                ":" +
                String(this.state.seconds).padStart(2, "0"))
        );
    }

    initializeState() {
        var startDate = this.props.startTime ? new Date(this.props.startTime) : new Date();

        var countDownDate = startDate.getTime();
        countDownDate = countDownDate + this.props.duration * 1000;
        this.countDownDate = new Date(countDownDate);

        var now = new Date().getTime();

        var initDistance = countDownDate - now;

        if (initDistance <= 0) {
            countDownDate = new Date().getTime() + this.props.duration * 1000;
            initDistance = countDownDate - now;
        }

        var initHours = Math.floor(initDistance / (1000 * 60 * 60));
        var initMinutes = Math.floor((initDistance % (1000 * 60 * 60)) / (1000 * 60));
        var initSeconds = Math.floor((initDistance % (1000 * 60)) / 1000);

        this.setState({
            initHours: initHours,
            initMinutes: initMinutes,
            initSeconds: initSeconds,
        });

        if (this.props.startTime != null) {
            this.startTimer();
        }
    }

    timerTick() {
        this.setState({
            playingBeep: false,
            playingBeep2: false,
            playingFinalBeep: false,
        });

        var now = new Date().getTime();

        let timeUntilZero = this.countDownDate - now;

        if (timeUntilZero < 0) {
            if (this.props.timerIsExpired) {
                this.props.timerIsExpired();
            }
            this.setState({
                timerExpired: true,
            });
        } else {
            if (this.props.timerIsRunning) {
                this.props.timerIsRunning();
            }
        }

        if (this.state.timerStarted) {
            var hours, minutes, seconds;

            if (timeUntilZero >= 0) {
                hours = Math.floor(timeUntilZero / (1000 * 60 * 60));
                minutes = Math.floor((timeUntilZero % (1000 * 60 * 60)) / (1000 * 60));
                seconds = Math.floor((timeUntilZero % (1000 * 60)) / 1000);
            } else {
                hours = Math.abs(Math.ceil(timeUntilZero / (1000 * 60 * 60)));
                minutes = Math.abs(Math.ceil((timeUntilZero % (1000 * 60 * 60)) / (1000 * 60)));
                seconds = Math.abs(Math.ceil((timeUntilZero % (1000 * 60)) / 1000));
            }

            var status = Math.floor(timeUntilZero / 1000);

            this.setState({
                hours: hours,
                minutes: minutes,
                seconds: seconds,
                status: this.state.duration - status,
            });

            if (this.props.soundEnabled) {
                if (status >= 0 && status <= 10) {
                    this.setState({
                        playingBeep: true,
                    });
                }
                if (status < 0 && status >= -10) {
                    this.setState({
                        playingBeep2: true,
                    });
                }
                if (status < -10) {
                    this.setState({
                        playingFinalBeep: true,
                    });
                }
            }
        }
    }

    startTimer() {
        var startDate = this.props.startTime ? new Date(this.props.startTime) : new Date();

        var countDownDate = startDate.getTime() + this.props.duration * 1000;
        this.countDownDate = new Date(countDownDate);

        var now = new Date().getTime();

        var initDistance = countDownDate - now;

        var initHours = Math.floor(initDistance / (1000 * 60 * 60));
        var initMinutes = Math.floor((initDistance % (1000 * 60 * 60)) / (1000 * 60));
        var initSeconds = Math.floor((initDistance % (1000 * 60)) / 1000);

        this.setState(
            {
                timerStarted: true,
                hours: initHours,
                minutes: initMinutes,
                seconds: initSeconds,
            },
            () => {
                if (this.state.afterTimerStart !== undefined) {
                    this.state.afterTimerStart(startDate.toUTCString());
                }
            }
        );

        this.timerTick();
        this.timerInterval = setInterval(this.timerTick, 1000);
    }

    currentState() {
        if (!this.state.timerStarted) {
            return "idle";
        }

        if (this.state.timerStarted && !this.state.timerExpired && !this.state.timerStopped) {
            return "running";
        }

        if (this.state.timerExpired && !this.state.timerStopped) {
            return "runningBackward";
        }

        if (this.state.timerStopped) {
            return "timerStopped";
        }
    }

    stopAlert() {
        clearInterval(this.timerInterval);
        if (this.state.afterTimerEnd !== undefined) {
            this.state.afterTimerEnd();
            this.setState({
                timerExpired: false,
                timerStopped: true,
                playingBeep: false,
                playingBeep2: false,
                playingFinalBeep: false,
            });
        }
    }
}

export default withTranslation(["dynamicTranslation"])(CountDownTimer);
