import {
    RECEIVE_RECIPES,
    RECEIVE_RECIPE,
    RECEIVE_RECIPETOOLS,
    RECEIVE_ARTICLEDIETS,
    RECEIVE_DUMMIES,
    RECEIVE_ARTICLESWORECIPE,
    RECEIVE_RECIPEWORKINGSTATIONS,
    RECEIVE_PHASETYPES,
    //    RECEIVE_STEPS
} from "../actions/recipeAction";

import { RECEIVE_TOOLS } from "../actions/menucardActions/toolsAction";

const initialState = {
    all: [],
    dummies: [],
    selected: [],
    tools: [],
    diets: [],
    articlesworecipe: [],
    workingStations: [],
    phaseTypes: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case RECEIVE_RECIPES:
            return Object.assign({}, state, {
                all: action.all,
            });
        // case RECEIVE_STEPS:
        //     return Object.assign({}, state, {
        //         steps: action.steps
        //         //selected: action.selected
        //     });
        case RECEIVE_RECIPE:
            return Object.assign({}, state, {
                selected: action.selected,
            });
        case RECEIVE_DUMMIES:
            return Object.assign({}, state, {
                dummies: action.dummies,
            });
        case RECEIVE_RECIPETOOLS:
            return Object.assign({}, state, {
                tools: action.tools,
            });
        case RECEIVE_TOOLS:
            return Object.assign({}, state, {
                isFetchingPrices: false,
                tools: action.tools,
            });
        case RECEIVE_ARTICLEDIETS:
            return Object.assign({}, state, {
                diets: action.diets,
            });
        case RECEIVE_PHASETYPES:
            return Object.assign({}, state, {
                phaseTypes: action.phaseTypes,
            });
        case RECEIVE_ARTICLESWORECIPE:
            return Object.assign({}, state, {
                articlesworecipe: action.articlesworecipe,
            });
        case RECEIVE_RECIPEWORKINGSTATIONS:
            return Object.assign({}, state, {
                workingStations: action.workingStations,
            });
        default:
            return state;
    }
};
