import Config from "../../Config";
import { authHeader, handleResponse, translateResponse } from "../../helpers/requestHelpers";

const config = new Config();

/*Diese Funktion lädt alle Additives und übergibt isChecked wenn diese in diesem Artikels Vorkommen*/
export function fetchAllArticleAdditives(articlesId) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ articlesId }),
    };
    return function (dispatch) {
        dispatch(requestAllArticleAdditives());
        return fetch(config.backendHost + "/Additives/GetAdditiveAdditiveGroups", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => dispatch(receiveAllArticleAdditives(json)));
    };
}

export const REQUEST_ALLARTICLEADDITIVES = "REQUEST_ALLARTICLEADDITIVES";
export function requestAllArticleAdditives() {
    return {
        type: REQUEST_ALLARTICLEADDITIVES,
    };
}

export const RECEIVE_ALLARTICLEADDITIVES = "RECEIVE_ALLARTICLEADDITIVES";
export function receiveAllArticleAdditives(json) {
    return {
        type: RECEIVE_ALLARTICLEADDITIVES,
        allArticleAdditives: json,
    };
}

export function requestAdditiveChange(articlesId, additivesId, state) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ articlesId, additivesId, state }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Additives/ArticleAdditiveChange", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}
export function fetchAdditives(t) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Additives/get", requestOptions)
            .then((response) => handleResponse(response))
            .then((response) => translateResponse(response, t));
    };
}

export function updateAdditives(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Additives/update", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function insertAdditives(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Additives/insert", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function deleteAdditives(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Additives/delete", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}
