import { Button, SelectBox, TextBox, ValidationGroup, Validator } from "devextreme-react";
import { Button as TextBoxButton } from "devextreme-react/text-box";
import { RequiredRule } from "devextreme-react/validator";
import React from "react";
import { connect } from "react-redux";
import { register, SET_REGISTRATION_STATUS } from "../../../../actions/identityActions";
import { userActions } from "../../../../actions/userActions";
import store from "../../../../store";
import notify from "devextreme/ui/notify";
import { getToast } from "../../../../helpers/requestHelpers";
import { withRouter } from "react-router";
import { compose } from "redux";
import { LoadPanel } from "devextreme-react/load-panel";
import { withTranslation } from "react-i18next";
import eyeIcon from "../../../../img/eye-solid.svg";
import eyeSlashIcon from "../../../../img/eye-slash-solid.svg";

class RegisterForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: {
                username: "",
                password: generatePassword(),
                role: "Admin",
                isLoading: false,
                showPassword: false,
            },
            errors: [],
            userLoggedIn: this.props.identity.user,
        };

        this.handleChange = this.handleChange.bind(this);
        this.setRole = this.setRole.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleRoleChange = this.handleRoleChange.bind(this);
        this.resetRegistationStatus = this.resetRegistationStatus.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.event.target;
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                [name]: value,
            },
        });
    }

    handleRoleChange(e) {
        const name = "role";
        const value = e.value;
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                [name]: value,
            },
        });
    }

    setRole(event) {
        this.setState({
            user: {
                role: event.target.value,
            },
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({
            isLoading: true,
        });
        const { user, userLoggedIn } = this.state;
        if (user.username && user.password && user.role) {
            store.dispatch(register(userLoggedIn, user)).then((response) => {
                if (!response.registrationStatus.Succeeded) {
                    notify(getToast("Anlegen des Benutzers fehlgeschlagen.", "error"));
                    this.setState({
                        errors: response.registrationStatus.errors,
                        isLoading: false,
                    });
                } else {
                    notify(getToast("Benutzer anlegen erfolgreich.", "success"));
                    this.setState({
                        errors: [],
                        isLoading: false,
                    });
                    this.props.history.push("/management/mitarbeiterverwaltung/mitarbeiter");
                }
            });
        }
    }

    resetRegistationStatus() {
        store.dispatch({
            type: SET_REGISTRATION_STATUS,
            registrationStatus: { Errors: [], Succeeded: null },
        });
        this.setState({
            user: {
                username: "",
                password: generatePassword(),
            },
        });
    }

    componentDidUpdate() {
        if (this.props.identity && this.props.identity.registrationStatus.Succeeded) {
            //setTimeout(this.resetRegistationStatus, 2000);
        }
    }
    componentDidMount() {
        this.props.dispatch(userActions.getAllRoles());
    }

    render() {
        const { t } = this.props;
        var roles = [];
        if (this.props.users != null && this.props.users.allRoles != null) {
            this.props.users.allRoles.forEach((element) => {
                roles.push(element.role);
            });
        }
        return (
            <React.Fragment>
                {/* {this.props.identity &&
                    this.props.identity.registrationStatus.Succeeded ===
                        false && (
                        <div>
                            {this.props.identity.registrationStatus.Errors.map(
                                (error, i) => {
                                    return <div>{error.Description}</div>;
                                }
                            )}
                        </div>
                    )}

                {this.props.identity &&
                    this.props.identity.registrationStatus.Succeeded && (
                        <div>Der neue Benutzer wurde erfolgreich angelegt!</div>
                    )} */}
                <LoadPanel visible={this.state.isLoading} container="#dishes" />
                <form onSubmit={this.handleSubmit} autoComplete="off">
                    <ValidationGroup>
                        <div className={"dx-field"}>
                            <TextBox
                                name="username"
                                mode="email"
                                placeholder={t("Register.Email")}
                                onValueChanged={this.handleChange}
                            >
                                <Validator>
                                    <RequiredRule message={t("Register.ThisFieldIsRequired")} />
                                </Validator>
                            </TextBox>
                        </div>

                        <div className={"dx-field"}>
                            <TextBox
                                name="password"
                                mode={this.state.showPassword ? "text" : "password"}
                                placeholder={t("Register.Password")}
                                onValueChanged={this.handleChange}
                            >
                                <TextBoxButton
                                    name="showPassword"
                                    style={{ marginRight: 5 }}
                                    options={{
                                        location: "after",
                                        type: "default",
                                        color: "#fff",
                                        icon: this.state.showPassword ? eyeSlashIcon : eyeIcon,
                                        onClick: () => {
                                            this.setState({ showPassword: !this.state.showPassword });
                                        },
                                    }}
                                ></TextBoxButton>
                                <Validator>
                                    <RequiredRule message={t("Register.ThisFieldIsRequired")} />
                                </Validator>
                            </TextBox>
                        </div>
                        <div className={"dx-field"}>
                            <TextBox
                                name="firstName"
                                mode="text"
                                placeholder={t("Register.FirstName")}
                                onValueChanged={this.handleChange}
                            >
                                <Validator>
                                    <RequiredRule message={t("Register.ThisFieldIsRequired")} />
                                </Validator>
                            </TextBox>
                        </div>
                        <div className={"dx-field"}>
                            <TextBox
                                name="lastName"
                                mode="text"
                                placeholder={t("Register.LastName")}
                                onValueChanged={this.handleChange}
                            >
                                <Validator>
                                    <RequiredRule message={t("Register.ThisFieldIsRequired")} />
                                </Validator>
                            </TextBox>
                        </div>
                        <div className={"dx-field"}>
                            <TextBox
                                name="phone"
                                mode="text"
                                placeholder={t("Telefonnummer")}
                                onValueChanged={this.handleChange}
                            >
                                <Validator>
                                    <RequiredRule message={t("Register.ThisFieldIsRequired")} />
                                </Validator>
                            </TextBox>
                        </div>
                        <div className={"dx-field"}>
                            <TextBox
                                name="organisation"
                                mode="text"
                                placeholder={t("Settings.Customer.Company")}
                                onValueChanged={this.handleChange}
                            >
                                <Validator>
                                    <RequiredRule message={t("Register.ThisFieldIsRequired")} />
                                </Validator>
                            </TextBox>
                        </div>

                        <div className={"dx-field"}>
                            <SelectBox
                                items={roles}
                                name="role"
                                mode="text"
                                placeholder={t("Register.SelectRole") + ":"}
                                onValueChanged={this.handleRoleChange}
                            >
                                <Validator>
                                    <RequiredRule message={t("Register.ThisFieldIsRequired")} />
                                </Validator>
                            </SelectBox>
                        </div>
                        {!this.state.errors ||
                            (this.state.errors.length === 0 && (
                                <div style={{ fontSize: 11, marginBottom: 5 }}>
                                    <span>{t("Passwords must be at least 6 characters.") + "."}</span>
                                    <br />
                                    <span>
                                        {t("Passwords must have at least one non alphanumeric character.") + "."}
                                    </span>
                                    <br />
                                    <span>{t("Passwords must have at least one digit ('0'-'9').") + "."}</span>
                                    <br />
                                    <span>{t("Passwords must have at least one uppercase ('A'-'Z').") + "."}</span>
                                    <br />
                                    <span>{t("Passwords must have at least one lowercase ('a'-'z').") + "."}</span>{" "}
                                </div>
                            ))}
                        {this.state.errors &&
                            this.state.errors.length > 0 &&
                            this.state.errors.map((error, index) => {
                                return (
                                    <div style={{ color: "red" }} key={index}>
                                        {t(error.description)}
                                    </div>
                                );
                            })}
                        <div style={{ marginTop: 5 }} className={"dx-field"}>
                            <div className={"dx-field-value"}>
                                <Button text={t("Register.Save")} type="default" useSubmitBehavior={true} />
                            </div>
                        </div>
                    </ValidationGroup>
                </form>
            </React.Fragment>
        );
    }
}

function generatePassword() {
    var length = 32,
        charsetLower = "abcdefghijklmnopqrstuvwxyz",
        charsetUpper = "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
        charsetNumeric = "0123456789",
        charsetAlphaNumeric = "!?-_()",
        retVal = "";
    for (var i = 0, n = charsetLower.length; i < length / 4; ++i) {
        retVal += charsetLower.charAt(Math.floor(Math.random() * n));
    }
    for (i = 0, n = charsetUpper.length; i < length / 4; ++i) {
        retVal += charsetUpper.charAt(Math.floor(Math.random() * n));
    }
    for (i = 0, n = charsetNumeric.length; i < length / 4; ++i) {
        retVal += charsetNumeric.charAt(Math.floor(Math.random() * n));
    }
    for (i = 0, n = charsetAlphaNumeric.length; i < length / 4; ++i) {
        retVal += charsetAlphaNumeric.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
}

function mapStateToProps(state) {
    const { identity, users } = state;
    return {
        identity,
        users,
    };
}

export default compose(connect(mapStateToProps), withTranslation(["dynamicTranslation"]), withRouter)(RegisterForm);
