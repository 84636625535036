import { LOGOUT_USER, SET_REGISTRATION_STATUS, SET_USER, SET_USER_FAVORITES } from "../actions/identityActions";
import { userConstants } from "../actions/userActions";

let user = JSON.parse(localStorage.getItem("user"));
const initialState = {
    user: user ? user : {},
    registrationStatus: {
        Errors: [],
        Succeeded: null,
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_REGISTRATION_STATUS:
            return Object.assign({}, state, {
                registrationStatus: action.registrationStatus,
            });
        case SET_USER:
            localStorage.setItem("user", JSON.stringify(action.user));
            return Object.assign({}, state, {
                user: action.user,
            });
        case LOGOUT_USER:
            localStorage.removeItem("user");
            return Object.assign({}, state, {
                user: {},
            });
        case SET_USER_FAVORITES:
            localStorage.setItem("user", JSON.stringify({ ...state.user, Favorites: action.user }));
            return Object.assign({}, state, {
                user: { ...state.user, Favorites: action.user },
            });
        case userConstants.UPDATE_USER_LANGUAGE_SUCCESS:
            localStorage.setItem("user", JSON.stringify({ ...state.user, selectedLanguage: action.language }));
            return Object.assign({}, state, {
                user: { ...state.user, selectedLanguage: action.language },
            });
        default:
            return state;
    }
};
