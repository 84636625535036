import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { setCurrentPage } from "../../../../actions/pagesActions";
import store from "../../../../store";
import NavigationPage from "./navigationPage";

class DynamicPage extends Component {
    componentDidMount() {
        store.dispatch(setCurrentPage(this.props.page));
    }

    render() {
        if (this.props.page.isNavigationPage)
            return (
                <React.Fragment>
                    <NavigationPage page={this.props.page} changePage={this.props.changePage} />
                </React.Fragment>
            );
        else return <React.Fragment>{<this.props.page.component {...this.props} />}</React.Fragment>;
    }
}

function mapStateToProps(state) {
    const { settings } = state;
    return {
        settings,
    };
}

export default compose(connect(mapStateToProps), withTranslation(["dynamicTranslation"]))(DynamicPage);
