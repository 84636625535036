import { RECEIVE_LICENSE, REQUEST_LICENSE } from "../actions/licenseActions";

const initialState = {
    isFetching: false,
    data: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_LICENSE:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case RECEIVE_LICENSE:
            return Object.assign({}, state, {
                isFetching: false,
                data: action.data,
            });
        default:
            return state;
    }
};
