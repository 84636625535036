import React from "react";
import { useTranslation } from "react-i18next";
import { formatGrammage, getGrammageUnit } from "../../../../helpers/units";

export default function Ingredients(props) {
    const { t } = useTranslation(["dynamicTranslation"]);
    return (
        <React.Fragment>
            {(props.stepIngredients.length > 0 || props.tempIngredients.length > 0) && (
                <div
                    className={
                        props.stepIngredients.length < 1 ? "inner-box empty ingredients" : "inner-box ingredients"
                    }
                >
                    <header>{t("CookingProcess.Ingredients")}</header>
                    <div className="inner-box-content">
                        {((props.stepIngredients && props.stepIngredients.length > 0) || (props.tempIngredients && props.tempIngredients.length > 0)) && (
                            <table>
                                <tbody>
                                    {props.stepIngredients && props.stepIngredients.length > 0 && props.stepIngredients.map((stepIngredient, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td className="grammage">
                                                        {formatGrammage(stepIngredient.totalGrammage)}
                                                    </td>
                                                    <td className="unit">
                                                        {getGrammageUnit(stepIngredient.totalGrammage)}
                                                    </td>
                                                    <td className="name">{t(stepIngredient.Ingredient.name)}</td>
                                                    <td className="name">
                                                        {stepIngredient.Ingredient.commentRecipe
                                                            ? "(" + t(stepIngredient.Ingredient.commentRecipe) + ")"
                                                            : ""}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    {props.tempIngredients && props.tempIngredients.length > 0 && (
                                        <tr style={{height:"10px"}}>
                                            <td className="grammage"></td>
                                            <td className="unit"></td>
                                            <td className="name"></td>
                                            <td className="name"></td>
                                        </tr>
                                    )}
                                    {props.tempIngredients && props.tempIngredients.length > 0 && props.tempIngredients.map((tempIngredient, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className="grammage"></td>
                                                <td className="unit"></td>
                                                <td className="name">{t(tempIngredient.name)}</td>
                                                <td className="name"></td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        )}
                    </div>
                    {props.stepIngredients.length < 1 && props.tempIngredients.length < 1 && (
                        <div className="content">
                            <i className="dx-icon-bulletlist"></i>
                        </div>
                    )}
                </div>
            )}
        </React.Fragment>
    );
}
