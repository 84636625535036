import Config from "../../Config";
import { authHeader, handleResponse } from "../../helpers/requestHelpers";

const config = new Config();

export const RECEIVE_ABSOLUTGRAMMAGESBYSIZE = "RECEIVE_ABSOLUTGRAMMAGESBYSIZE";
export function receiveAbsolutGrammagesBySize(string) {
    return {
        type: RECEIVE_ABSOLUTGRAMMAGESBYSIZE,
        absolutGrammagesBySize: string
    };
}

/*Diese Funktion berechnet das Gesamtgewicht eines Artikels anhand seiner Größe*/
export function getAbsolutGrammagesBySize(articlesId, sizesId) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ articlesId, sizesId })
    };
    return function(dispatch) {
        return fetch(
            config.backendHost + "/Articles/GetAbsolutGrammagesBySize",
            requestOptions
        )
            .then(response => handleResponse(response))
            .then(string => dispatch(receiveAbsolutGrammagesBySize(string)));
    };
}
