import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button } from "devextreme-react";
import { getAllNutritionalValues, setNutritionalValues } from "../../../../../../actions/menuCardAction";
import store from "../../../../../../store";
import { NutritionSelect } from "./nutritionSelect";

class Nutrition extends Component {
    constructor(props) {
        super(props);
        const { t } = props;
        this.state = {
            fields: [],
            nutrition: {
                kcal: "",
                kjoule: "",
                fat: "",
                saturatedFat: "",
                carbohydrates: "",
                sugar: "",
                protein: "",
                salt: "",
                fiber: "",
            },
            nutritionNames: {
                kcal: t("Brennwert(Kilokalorien)"),
                kjoule: t("Brennwert(Kilojoule)"),
                fat: t("Fett"),
                saturatedFat: t("Gesättigte Fettsäuren"),
                carbohydrates: t("Kohlenhydrate"),
                sugar: t("Zucker"),
                protein: t("Eiweiß"),
                salt: t("Salz"),
                fiber: t("Ballaststoffe"),
            },
            selectedValues: {},
            isLoading: false,
        };

        this.handleNutritionalValueChange = this.handleNutritionalValueChange.bind(this);
        this.handleSaveAssignedValues = this.handleSaveAssignedValues.bind(this);
    }

    componentDidMount() {
        this.setState({
            isLoading: true,
        });

        store.dispatch(getAllNutritionalValues()).then((response) => {
            this.setState({
                fields: response,
            });
            if (response.some((obj) => obj.assignmentNutritionalValueId !== null)) {
                var currentNutritionalValues = response.filter(
                    (obj) => obj.assignmentNutritionalValueId !== null || obj.assignmentNutritionalValueId !== undefined
                );
                currentNutritionalValues.forEach((nv) => {
                    switch (nv.orderIndex) {
                        case 10:
                            this.setState((prevState) => ({
                                nutrition: {
                                    ...prevState.nutrition,
                                    kcal: nv.assignmentNutritionalValueId,
                                },
                                selectedValues: {
                                    ...prevState.selectedValues,
                                    kcal: response.find((obj) => obj.id === nv.assignmentNutritionalValueId),
                                },
                            }));
                            break;
                        case 20:
                            this.setState((prevState) => ({
                                nutrition: {
                                    ...prevState.nutrition,
                                    kjoule: nv.assignmentNutritionalValueId,
                                },
                                selectedValues: {
                                    ...prevState.selectedValues,
                                    kjoule: response.find((obj) => obj.id === nv.assignmentNutritionalValueId),
                                },
                            }));
                            break;
                        case 30:
                            this.setState((prevState) => ({
                                nutrition: {
                                    ...prevState.nutrition,
                                    fat: nv.assignmentNutritionalValueId,
                                },
                                selectedValues: {
                                    ...prevState.selectedValues,
                                    fat: response.find((obj) => obj.id === nv.assignmentNutritionalValueId),
                                },
                            }));
                            break;
                        case 31:
                            this.setState((prevState) => ({
                                nutrition: {
                                    ...prevState.nutrition,
                                    saturatedFat: nv.assignmentNutritionalValueId,
                                },
                                selectedValues: {
                                    ...prevState.selectedValues,
                                    saturatedFat: response.find((obj) => obj.id === nv.assignmentNutritionalValueId),
                                },
                            }));
                            break;
                        case 40:
                            this.setState((prevState) => ({
                                nutrition: {
                                    ...prevState.nutrition,
                                    carbohydrates: nv.assignmentNutritionalValueId,
                                },
                                selectedValues: {
                                    ...prevState.selectedValues,
                                    carbohydrates: response.find((obj) => obj.id === nv.assignmentNutritionalValueId),
                                },
                            }));
                            break;
                        case 41:
                            this.setState((prevState) => ({
                                nutrition: {
                                    ...prevState.nutrition,
                                    sugar: nv.assignmentNutritionalValueId,
                                },
                                selectedValues: {
                                    ...prevState.selectedValues,
                                    sugar: response.find((obj) => obj.id === nv.assignmentNutritionalValueId),
                                },
                            }));
                            break;
                        case 50:
                            this.setState((prevState) => ({
                                nutrition: {
                                    ...prevState.nutrition,
                                    fiber: nv.assignmentNutritionalValueId,
                                },
                                selectedValues: {
                                    ...prevState.selectedValues,
                                    fiber: response.find((obj) => obj.id === nv.assignmentNutritionalValueId),
                                },
                            }));
                            break;
                        case 60:
                            this.setState((prevState) => ({
                                nutrition: {
                                    ...prevState.nutrition,
                                    protein: nv.assignmentNutritionalValueId,
                                },
                                selectedValues: {
                                    ...prevState.selectedValues,
                                    protein: response.find((obj) => obj.id === nv.assignmentNutritionalValueId),
                                },
                            }));
                            break;
                        case 70:
                            this.setState((prevState) => ({
                                nutrition: {
                                    ...prevState.nutrition,
                                    salt: nv.assignmentNutritionalValueId,
                                },
                                selectedValues: {
                                    ...prevState.selectedValues,
                                    salt: response.find((obj) => obj.id === nv.assignmentNutritionalValueId),
                                },
                            }));
                            break;
                        default:
                            break;
                    }
                });
            }
        });

        this.setState({ isLoading: false });
    }

    handleNutritionalValueChange(changeValue) {
        var newNutritionalValue = this.state.fields.find((obj) => obj.id === changeValue.id);
        if (newNutritionalValue !== undefined) {
            this.setState((prevState) => ({
                nutrition: {
                    ...prevState.nutrition,
                    [changeValue.name]: changeValue.id,
                },
                selectedValues: {
                    ...prevState.selectedValues,
                    [changeValue.name]: newNutritionalValue.name,
                },
            }));
        }
    }

    handleSaveAssignedValues() {
        var nutritionalValues = [];
        for (const propertyName in this.state.nutrition) {
            if (this.state.nutrition.hasOwnProperty(propertyName)) {
                switch (propertyName) {
                    case "kcal":
                        var kcalObj = {
                            name: "Brennwert",
                            unit: "kcal",
                            orderIndex: 10,
                            nutritionalValueGroup: "Durchschnittliche Nährwertangaben",
                            assignmentNutritionalValueId: this.state.nutrition[propertyName],
                        };
                        nutritionalValues.push(kcalObj);
                        break;
                    case "kjoule":
                        var kjouleObj = {
                            name: "Brennwert",
                            unit: "kj",
                            orderIndex: 20,
                            nutritionalValueGroup: "Durchschnittliche Nährwertangaben",
                            assignmentNutritionalValueId: this.state.nutrition[propertyName],
                        };
                        nutritionalValues.push(kjouleObj);
                        break;
                    case "fat":
                        var fatObj = {
                            name: "Fett",
                            unit: "g",
                            orderIndex: 30,
                            nutritionalValueGroup: "Durchschnittliche Nährwertangaben",
                            assignmentNutritionalValueId: this.state.nutrition[propertyName],
                        };
                        nutritionalValues.push(fatObj);
                        break;
                    case "saturatedFat":
                        var saturatedFatObj = {
                            name: "- davon gesättigte Fettsäuren",
                            unit: "g",
                            orderIndex: 31,
                            nutritionalValueGroup: "Durchschnittliche Nährwertangaben",
                            parentId: this.state.nutrition["fat"],
                            assignmentNutritionalValueId: this.state.nutrition[propertyName],
                        };
                        nutritionalValues.push(saturatedFatObj);
                        break;
                    case "carbohydrates":
                        var carbohydratesObj = {
                            name: "Kohlenhydrate",
                            unit: "g",
                            orderIndex: 40,
                            nutritionalValueGroup: "Durchschnittliche Nährwertangaben",
                            assignmentNutritionalValueId: this.state.nutrition[propertyName],
                        };
                        nutritionalValues.push(carbohydratesObj);
                        break;
                    case "sugar":
                        var sugarObj = {
                            name: "- davon Zucker",
                            unit: "g",
                            orderIndex: 41,
                            nutritionalValueGroup: "Durchschnittliche Nährwertangaben",
                            parentId: this.state.nutrition["carbohydrates"],
                            assignmentNutritionalValueId: this.state.nutrition[propertyName],
                        };
                        nutritionalValues.push(sugarObj);
                        break;
                    case "fiber":
                        var fiberObj = {
                            name: "Ballaststoffe",
                            unit: "g",
                            orderIndex: 50,
                            nutritionalValueGroup: "Durchschnittliche Nährwertangaben",
                            assignmentNutritionalValueId: this.state.nutrition[propertyName],
                        };
                        nutritionalValues.push(fiberObj);
                        break;
                    case "protein":
                        var proteinObj = {
                            name: "Eiweiß",
                            unit: "g",
                            orderIndex: 60,
                            nutritionalValueGroup: "Durchschnittliche Nährwertangaben",
                            assignmentNutritionalValueId: this.state.nutrition[propertyName],
                        };
                        nutritionalValues.push(proteinObj);
                        break;
                    case "salt":
                        var saltObj = {
                            name: "Salz",
                            unit: "g",
                            orderIndex: 70,
                            nutritionalValueGroup: "Durchschnittliche Nährwertangaben",
                            assignmentNutritionalValueId: this.state.nutrition[propertyName],
                        };
                        nutritionalValues.push(saltObj);
                        break;
                    default:
                        break;
                }
            }
        }

        store.dispatch(setNutritionalValues(nutritionalValues)).then((response) => {
            console.log(response);
        });
    }

    render() {
        const { fields, nutrition, nutritionNames, isLoading, selectedValues } = this.state;

        console.log(selectedValues);
        return (
            <div>
                {!isLoading && fields.length > 0 && (
                    <div style={{ margin: 20 }}>
                        {Object.keys(nutrition).map((propertyName, propertyIndex) => {
                            console.log(propertyName);
                            if (selectedValues[propertyName] !== undefined) {
                                return (
                                    <NutritionSelect
                                        key={propertyIndex}
                                        nutritionalValues={fields}
                                        propertyName={propertyName}
                                        name={nutritionNames[propertyName]}
                                        defaultValue={selectedValues[propertyName]}
                                        onChange={this.handleNutritionalValueChange}
                                    />
                                );
                            } else {
                                return null;
                            }
                        })}

                        <Button
                            type="default"
                            style={{ marginTop: 20, height: 35 }}
                            onClick={this.handleSaveAssignedValues}
                        >
                            Zuordnung speichern
                        </Button>
                    </div>
                )}
            </div>
        );
    }
}

export default withTranslation(["dynamicTranslation"])(Nutrition);
