import Color from "color";
import Config from "../Config";
import { authHeader, handleResponse } from "../helpers/requestHelpers";
import store from "../store";

const config = new Config();

export const SET_THEME = "SET_THEME";
export function set_theme(json) {
    let root = document.documentElement;
    if (json) {
        const theme = {};
        theme.URL = json.URL;
        theme.logoGuid = json.logoGuid;
        theme.title = json.title;
        theme.logoUrl = json.logoUrl;
        theme.customCSS = json.customCSS;
        theme.colors = {
            accentColor: json.accentColor,
            accentColorl44: adjustColor(json.accentColor, 44),
            accentColorl14: adjustColor(json.accentColor, 14),
            accentColords8l10: adjustColor(json.accentColor, 10, -8),
            accentColordl6: adjustColor(json.accentColor, -6),
            accentColordl8: adjustColor(json.accentColor, -8),
            accentColordl10: adjustColor(json.accentColor, -10),
            accentColordl12: adjustColor(json.accentColor, -12),
            accentColordl16: adjustColor(json.accentColor, -16),
            accentColordl24: adjustColor(json.accentColor, -24),
            textColor: json.textColor,
            backgroundColor: json.backgroundColor,
            backgroundColordl10: adjustColor(json.backgroundColor, -10),
            backgroundColordl20: adjustColor(json.backgroundColor, -20),
            backgroundColordl30: adjustColor(json.backgroundColor, -30),
            borderColor: json.borderColor,
            tilesColor: json.tilesColor ? json.tilesColor : json.borderColor,
            tilesBackgroundColor: json.tilesBackgroundColor ? json.tilesBackgroundColor : json.backgroundColor,
            successColor: json.successColor,
            successColordl4: adjustColor(json.successColor, -4),
            successColordl8: adjustColor(json.successColor, -8),
            successColordl10: adjustColor(json.successColor, -10),
            successColordl12: adjustColor(json.successColor, -12),
            successColordl16: adjustColor(json.successColor, -16),
            successColordl20: adjustColor(json.successColor, -20),
            successColordl24: adjustColor(json.successColor, -24),
            warningColor: json.warningColor,
            dangerColor: json.dangerColor,
            dangerColorl16: adjustColor(json.dangerColor, 16),
            dangerColordl4: adjustColor(json.dangerColor, -4),
            dangerColordl8: adjustColor(json.dangerColor, -8),
            dangerColordl10: adjustColor(json.dangerColor, -10),
            dangerColordl12: adjustColor(json.dangerColor, -12),
            dangerColordl16: adjustColor(json.dangerColor, -16),
            dangerColordl20: adjustColor(json.dangerColor, -20),
            dangerColordl24: adjustColor(json.dangerColor, -24),
            headerBackgroundColor: json.headerBackgroundColor ? json.headerBackgroundColor : json.accentColor,
            footerBackgroundColor: json.footerBackgroundColor ? json.footerBackgroundColor : json.accentColor,
            buttonBackgroundColor: json.buttonBackgroundColor ? json.buttonBackgroundColor : json.accentColor,
        };

        root.removeAttribute("style");

        root.style.setProperty("--accent-color", theme.colors.accentColor);
        root.style.setProperty("--accent-color-l-44", theme.colors.accentColorl44);
        root.style.setProperty("--accent-color-l-14", theme.colors.accentColorl14);
        root.style.setProperty("--accent-color-ds-8-l-10", theme.colors.accentColords8l10);
        root.style.setProperty("--accent-color-dl-6", theme.colors.accentColordl6);
        root.style.setProperty("--accent-color-dl-8", theme.colors.accentColordl8);
        root.style.setProperty("--accent-color-dl-10", theme.colors.accentColordl10);
        root.style.setProperty("--accent-color-dl-12", theme.colors.accentColordl12);
        root.style.setProperty("--accent-color-dl-16", theme.colors.accentColordl16);
        root.style.setProperty("--accent-color-dl-24", theme.colors.accentColordl24);
        root.style.setProperty("--text-color", theme.colors.textColor);
        root.style.setProperty("--background-color", theme.colors.backgroundColor);
        root.style.setProperty("--background-color-dl-10", theme.colors.backgroundColordl10);
        root.style.setProperty("--background-color-dl-20", theme.colors.backgroundColordl20);
        root.style.setProperty("--background-color-dl-30", theme.colors.backgroundColordl30);
        root.style.setProperty("--border-color", theme.colors.borderColor);
        root.style.setProperty("--tiles-color", theme.colors.tilesColor);
        root.style.setProperty("--tiles-background-color", theme.colors.tilesBackgroundColor);
        root.style.setProperty("--success-color", theme.colors.successColor);
        root.style.setProperty("--success-color-dl-4", theme.colors.successColordl4);
        root.style.setProperty("--success-color-dl-8", theme.colors.successColordl8);
        root.style.setProperty("--success-color-dl-10", theme.colors.successColordl10);
        root.style.setProperty("--success-color-dl-12", theme.colors.successColordl12);
        root.style.setProperty("--success-color-dl-16", theme.colors.successColordl16);
        root.style.setProperty("--success-color-dl-20", theme.colors.successColordl20);
        root.style.setProperty("--success-color-dl-24", theme.colors.successColordl24);
        root.style.setProperty("--warning-color", theme.colors.warningColor);
        root.style.setProperty("--danger-color", theme.colors.dangerColor);
        root.style.setProperty("--danger-color-l-16", theme.colors.dangerColorl16);
        root.style.setProperty("--danger-color-dl-4", theme.colors.dangerColordl4);
        root.style.setProperty("--danger-color-dl-8", theme.colors.dangerColordl8);
        root.style.setProperty("--danger-color-dl-10", theme.colors.dangerColordl10);
        root.style.setProperty("--danger-color-dl-12", theme.colors.dangerColordl12);
        root.style.setProperty("--danger-color-dl-16", theme.colors.dangerColordl16);
        root.style.setProperty("--danger-color-dl-20", theme.colors.dangerColordl20);
        root.style.setProperty("--danger-color-dl-24", theme.colors.dangerColordl24);
        root.style.setProperty("--header-background-color", theme.colors.headerBackgroundColor);
        root.style.setProperty("--footer-background-color", theme.colors.footerBackgroundColor);
        root.style.setProperty("--button-background-color", theme.colors.buttonBackgroundColor);
        root.style.setProperty("--button-background-color-hover", adjustColor(theme.colors.buttonBackgroundColor, -8));
        root.style.setProperty(
            "--button-background-color-active",
            adjustColor(theme.colors.buttonBackgroundColor, -12)
        );

        if (document.getElementById("customCSS")) {
            document.getElementById("customCSS").remove();
        }

        const customStyle = document.createElement("style");
        customStyle.setAttribute("id", "customCSS");
        document.head.appendChild(customStyle);
        customStyle.innerHTML = theme.customCSS ? theme.customCSS : "";

        return {
            type: SET_THEME,
            theme: theme,
        };
    }

    return { type: SET_THEME };
}

export function saveTheme(theme) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({
            accentColor: theme.colors.accentColor,
            backgroundColor: theme.colors.backgroundColor,
            textColor: theme.colors.textColor,
            warningColor: theme.colors.warningColor,
            successColor: theme.colors.successColor,
            dangerColor: theme.colors.dangerColor,
            borderColor: theme.colors.borderColor,
            tilesColor: theme.colors.tilesColor,
            tilesBackgroundColor: theme.colors.tilesBackgroundColor,
            headerBackgroundColor: theme.colors.headerBackgroundColor,
            footerBackgroundColor: theme.colors.footerBackgroundColor,
            buttonBackgroundColor: theme.colors.buttonBackgroundColor,
            URL: theme.URL,
            logoGuid: theme.logoGuid,
            logoUrl: theme.logoUrl,
            title: theme.title,
            customCSS: theme.customCSS,
        }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Theme/saveTheme", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                store.dispatch(set_theme(json));
                return json;
            })
            .catch((err) => console.error(err));
    };
}

export function getThemeForUrl(url) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            url: url,
        }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/theme/getThemeForUrl", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                if (json && !json.status) {
                    store.dispatch(set_theme(json));
                    return json;
                } else {
                    return false;
                }
            })
            .catch((err) => console.error(err));
    };
}

export function adjustColor(hsl = "0,0%,0%", l = undefined, s = undefined) {
    let color = new Color("hsl(" + hsl + ")").round(0);

    let hue = color.array()[0];
    let saturation = color.array()[1];
    let lightness = color.array()[2];

    if (l) {
        lightness = lightness + l;
    }

    if (s) {
        saturation = saturation + s;
    }

    return hue.toString() + ", " + saturation.toString() + "%, " + lightness.toString() + "%";
}
