import React from "react";
import { Redirect } from "react-router-dom";
import SentryRoute from "../../../helpers/sentry";

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <SentryRoute
        {...rest}
        render={(props) => {
            let u = JSON.parse(localStorage.getItem("user"));

            return u && u.Id && u.Username && u.Token ? ( //user u enthält einen user (und keine Fehlermeldung)
                rest.hasRight ? (
                    <Component {...props} />
                ) : (
                    <div>
                        <h2 className="defaultText">Sie haben keine Berechtigung für diesen Bereich!</h2>
                    </div>
                )
            ) : (
                <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
            );
        }}
    />
);
