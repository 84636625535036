import { SET_NEW_ORDERS_AVAILABLE, UNSET_NEW_ORDERS_AVAILABLE } from "../actions/orderConnectionActions";

const initialState = {
    newOrdersAvailable: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_NEW_ORDERS_AVAILABLE:
            return Object.assign({}, state, {
                newOrdersAvailable: true,
            });
        case UNSET_NEW_ORDERS_AVAILABLE:
            return Object.assign({}, state, {
                newOrdersAvailable: false,
            });
        default:
            return state;
    }
};
