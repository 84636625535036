import React, { Component } from "react"
import { withTranslation } from "react-i18next";
import store from "../../../../../../store";
import { saveOnlineShopRecommendations, getRecipesAndArticlesForOnlineShop } from "../../../../../../actions/menuCardAction";
import { Form, LoadIndicator } from "devextreme-react";
import { GroupItem, Item, ButtonItem } from "devextreme-react/form";
import "./shopRecommendations.scss"

class ShopRecommendations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allArticles: [],
            allRecipes: [],
            isLoading: false,
        }

        this.onRecommendedArticleChanges = this.onRecommendedArticleChanges.bind(this);
        this.onRecommendedRecipeChanges = this.onRecommendedRecipeChanges.bind(this);
        this.saveRecommendations = this.saveRecommendations.bind(this);

        this.recommendedArticles = [];
        this.recommendedRecipes = [];
    }

    componentDidMount() {
        if (!this.state.isLoading && (this.state.allArticles.length === 0 && this.state.allRecipes.length === 0)) {
            this.setState({
                isLoading: true
            }, () => {
                store.dispatch(getRecipesAndArticlesForOnlineShop())
                    .then((response) => {
                        console.log(response);
                        this.recommendedArticles = response.recommendedArticles.filter(obj => obj.recommendedInOnlineShop === true);
                        this.recommendedRecipes = response.recommendedRecipes.filter(obj => obj.recommendedInOnlineShop === true);
                        console.log(this.recommendedArticles);
                        this.setState({
                            allArticles: response.recommendedArticles,
                            allRecipes: response.recommendedRecipes,
                            isLoading: false
                        });
                    })
            });
        }
    }

    onRecommendedArticleChanges(e) {
        if (e.value.length !== this.recommendedArticles.length) {
            this.recommendedArticles = e.value;
        }
    }

    onRecommendedRecipeChanges(e) {
        if (e.value.length !== this.recommendedRecipes.length) {
            this.recommendedRecipes = e.value;
        }
    }

    saveRecommendations(event) {
        event.preventDefault()
        store.dispatch(saveOnlineShopRecommendations({ recommendedArticles: this.recommendedArticles, recommendedRecipes: this.recommendedRecipes }))
            .then((response) => {
                console.log(response);
            });
    }

    render() {
        return (
            <div style={{ margin: 20 }} className={"recommendationsContainer"}>
                 {this.state.isLoading && (
                    <div id="loading" style={{ marginLeft: 7 }}>
                        <LoadIndicator visible={this.state.isLoading} width={80} height={80} />
                    </div>
                )}
                {(!this.state.isLoading && (this.state.allArticles.length > 0 || this.state.allRecipes.length > 0)) && (
                    <form onSubmit={this.saveRecommendations}>
                        <Form>
                            <GroupItem caption="Artikel- & Rezeptempfehlungen für den Online Shop">
                                <Item
                                    dataField={"name"}
                                    editorType={"dxTagBox"}
                                    editorOptions={{
                                        searchEnabled: true,
                                        dataSource: this.state.allArticles,
                                        displayExpr: "name",
                                        value: this.recommendedArticles.map((item) => {
                                            return item;
                                        }),
                                        onValueChanged: this.onRecommendedArticleChanges,
                                    }}
                                    label={{ text: "Empfohlene Artikel" }}
                                />


                                <Item
                                    dataField={"title"}
                                    editorType={"dxTagBox"}
                                    editorOptions={{
                                        searchEnabled: true,
                                        dataSource: this.state.allRecipes,
                                        displayExpr: "title",
                                        value: this.recommendedRecipes.map((item) => {
                                            return item;
                                        }),
                                        onValueChanged: this.onRecommendedRecipeChanges,
                                    }}
                                    label={{ text: "Empfohlene Rezepte" }}
                                />
                            </GroupItem>
                            <ButtonItem
                                buttonOptions={{
                                    text: "Speichern",
                                    type: "default",
                                    useSubmitBehavior: true,
                                }}
                            />
                        </Form>
                    </form>
                )}
            </div>
        );
    }
}

export default withTranslation(["dynamicTranslation"])(ShopRecommendations);