import Config from "../Config";
import { authHeader, handleResponse } from "../helpers/requestHelpers";

export const userService = {
    login,
    logout,
    getAll,
    getAllRoles,
    getUserRoles,
    getUserRights,
    getById,
    update,
    delete: _delete
};

const config = new Config();

function login(username, password) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ username, password })
    };

    return fetch(config.backendHost + "/users/authenticate", requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem("user", JSON.stringify(user));

            return user;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("user");
}

function getAll(user) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(user)
    };

    return fetch(config.backendHost + "/users/getAll", requestOptions).then(
        handleResponse
    );
}

function getAllRoles() {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };

    return fetch(
        config.backendHost + "/users/GetAllRoles",
        requestOptions
    ).then(handleResponse);
}

function getUserRoles(user) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            Id: user.Id,
            Username: user.Username,
            Token: user.Token
        })
    };
    return fetch(
        config.backendHost + "/users/GetUserRoles",
        requestOptions
    ).then(handleResponse);
}

function getUserRights(user) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            Id: user.Id,
            Username: user.Username,
            Token: user.Token
        })
    };
    return fetch(
        config.backendHost + "/users/GetUserRights",
        requestOptions
    ).then(handleResponse);
}

function getById(id) {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };

    return fetch(config.backendHost + `/users/${id}`, requestOptions).then(
        handleResponse
    );
}

function update(user) {
    const requestOptions = {
        method: "PUT",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(user)
    };

    return fetch(config.backendHost + `/users/${user.id}`, requestOptions).then(
        handleResponse
    );
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: "DELETE",
        headers: authHeader()
    };

    return fetch(config.backendHost + `/users/${id}`, requestOptions).then(
        handleResponse
    );
}
