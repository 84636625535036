import {
	REQUEST_MEDIAFOLDER,
	RECEIVE_MEDIAFOLDER
} from '../actions/mediaFolderActions'

const initialState = {
	isFetching: false,
	mediaFolder: []
}

export default (state = initialState, action) => {
	switch (action.type) {
		case REQUEST_MEDIAFOLDER:
		return Object.assign({}, state, {
			isFetching: true
		})
		case RECEIVE_MEDIAFOLDER:
		return Object.assign({}, state, {
			isFetching: false,
			mediaFolder: action.mediaFolder
		})
		default:
		return state
	}
}